import React from 'react'
import { Input } from '@packhelp/platform-dsl/atoms'
import { FormError } from '../../views/generate-dieline-view/components/form-error/FormError'
import { Field } from '../field/Field'
import { useStringValue } from './hooks/string-value/string-value'
import { TextFieldProps } from './types'

export const TextField = ({
  type,
  value: passedValue,
  required = true,
  disabled = false,
  name,
  label,
  error,
  onChange,
  onFocus,
  onBlur
}: TextFieldProps) => {
  const { value, setValue } = useStringValue({
    baseValue: passedValue,
    onChange
  })

  return (
    <Field>
      <label htmlFor={name}>{label}</label>
      <Input
        type={type}
        name={name}
        error={!!error}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        controlled={true}
        disabled={disabled}
      />
      <FormError error={error} />
    </Field>
  )
}
