import { Estimation } from '../../../../../../../../../../services'

type Supplier = {
  name: string
  estimations: number
}

export const useSuppliers = (estimations: Estimation[]) => {
  const suppliers: Supplier[] = [
    {
      name: 'All',
      estimations: estimations.length
    }
  ]

  estimations.forEach((estimation) => {
    const index = suppliers.findIndex(
      (s) => s.name === estimation.supplier.name
    )
    if (index !== -1) {
      suppliers[index] = {
        ...suppliers[index],
        estimations: suppliers[index].estimations + 1
      }
    } else {
      suppliers.push({
        name: estimation.supplier.name,
        estimations: 1
      })
    }
  })

  return { suppliers }
}
