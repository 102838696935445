import React from 'react'
import { Redirect, useParams } from 'react-router'
import { observer } from 'mobx-react'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { Content, Heading, HeadingSize, Wrapper } from '../../layout'
import { DiecutTile } from '../generate-diecut-view/components/diecut-tile/DiecutTile'
import styles from './DiecutView.module.scss'
import { useData } from './hooks/data/data'

export const DiecutView = observer(() => {
  const { diecutUuid } = useParams<{ diecutUuid: string }>()
  const { diecut, loading } = useData(diecutUuid)

  if (loading) {
    return <LoadingContent />
  }

  if (!diecut) {
    return <Redirect to="/404" />
  }

  return (
    <div
      className={styles.dieline}
      e2e-target="dieline"
      e2e-target-name={`dieline-${diecut.uuid}`}
    >
      <Content>
        <Wrapper>
          <Heading size={HeadingSize.h1}>
            {diecut.dieline.label} @ {diecut.format}
          </Heading>
          <DiecutTile diecut={diecut} fullWidth />
        </Wrapper>
      </Content>
    </div>
  )
})
