import React, { useEffect, useMemo, useState } from 'react'
import { Button, ButtonSize } from '@packhelp/platform-dsl/atoms'
import { useHistory } from 'react-router'
import { Heading, HeadingSize, MarginSize } from '../../../../layout/components'
import { Field, TextField } from '../../../../form'
import { useFormValidation } from '../../../../../hooks/form-validation'
import { ReturnLink } from '../return-link/ReturnLink'
import { Agreement } from '../agreement/Agreement'
import translations from './translations'
import { signUp, submit } from './actions'

import {
  FormatMessage,
  useFormatMessage
} from './../../../../../libs/localisation/LocaleIntlProvider'

export const SignUpForm = () => {
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const {
    values,
    errors,
    touched,
    createChangeHandler,
    createTouchHandler,
    validate,
    validated
  } = useFormValidation({
    email: null,
    password: null,
    agreement: null
  })
  const hasErrors = useMemo(() => Object.keys(errors).length !== 0, [errors])
  useEffect(() => {
    if (!isSubmitting || !validated) return
    if (hasErrors) return setIsSubmitting(false)
    signUp(values, setIsSubmitting, history)
  }, [validated, hasErrors, isSubmitting])

  const isLoading = isSubmitting && validated && !hasErrors

  return (
    <>
      <Heading size={HeadingSize.h1} marginBottom={MarginSize.xl}>
        <FormatMessage id={translations.welcome} />
      </Heading>
      <form onSubmit={submit(validate, setIsSubmitting)}>
        <Heading size={HeadingSize.h4}>
          <FormatMessage id={translations.signUp} />
        </Heading>
        <Field>
          <TextField
            label={useFormatMessage(translations.email)}
            value={values.email}
            name="email"
            onChange={createChangeHandler('email')}
            onBlur={createTouchHandler('email')}
            error={touched.email && errors.email}
          />
        </Field>
        <Field>
          <TextField
            label={useFormatMessage(translations.password)}
            value={values.password}
            name="password"
            onChange={createChangeHandler('password')}
            onBlur={createTouchHandler('password')}
            type="password"
            error={touched.password && errors.password}
          />
        </Field>
        <Agreement
          value={!!values.agreement}
          error={errors.agreement}
          onChange={createChangeHandler('agreement')}
        />
        <Button size={ButtonSize.large} fullWidth isLoading={isLoading}>
          <FormatMessage id={translations.signUp} />
        </Button>
      </form>
      <ReturnLink />
    </>
  )
}
