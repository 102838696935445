import React, { useMemo } from 'react'
import { Redirect } from 'react-router'
import { observer } from 'mobx-react'
import { Layout, Content } from '../../layout'
import { useRootStore } from '../../../hooks'
import { LoadingView } from '../loading-view/LoadingView'
import { SettingsForm } from './components/settings-form/SettingsForm'

export const SettingsView = observer(() => {
  const { userStore } = useRootStore()

  const content = useMemo(() => {
    if (!userStore.isInitialized || userStore.isLoading) return <LoadingView />
    if (!userStore.isAuthenticated) return <Redirect to="/" />
    return (
      <Content>
        <SettingsForm />
      </Content>
    )
  }, [userStore.isInitialized, userStore.isAuthenticated])

  return <Layout>{content}</Layout>
})
