import React, { useContext, useMemo } from 'react'
import { Country, getData } from 'country-list'
import { Loading, LoadingSize } from '@packhelp/platform-dsl/atoms'
import { Input } from '@packhelp/platform-dsl/atoms/Input/Input'
import { Select, SelectSize } from '@packhelp/platform-dsl/molecules/Select'
import { ProductSpecificationConfig } from '@packhelp/platform-pss-form'
import { InstantPriceContext } from '../../InstantPrice.context'
import { useSpecificationPreview } from './hooks/specificationPreview'
import PreviewItem from './components/previewItem/PreviewItem'
import styles from './Summary.module.scss'

type Props = {
  config: ProductSpecificationConfig
}

export const Summary: React.FC<Props> = ({ config }) => {
  const { specification, country, postalCode, updateDeliveryAddress } =
    useContext(InstantPriceContext)

  const preview = useSpecificationPreview(config, specification)
  const countries = useMemo(
    () =>
      getData()
        .sort((a: Country, b: Country) => a.name.localeCompare(b.name))
        .map(({ name }: Country) => ({ label: name, value: name })),
    []
  )

  return (
    <div className={styles.root}>
      <span className={styles.cardTitle}>Summary</span>

      <div className={styles.form}>
        <div className={styles.input}>
          <label className={styles.label}>Country</label>
          <Select
            size={SelectSize.large}
            value={country || undefined}
            name="country"
            onSelect={(value) => updateDeliveryAddress(value, postalCode)}
            data={countries}
            withSearch
          />
        </div>

        <div className={styles.input}>
          <label className={styles.label}>Postal code</label>
          <Input
            name="postalCode"
            value={postalCode || undefined}
            placeholder="Enter postal code"
            onChange={(event) =>
              updateDeliveryAddress(country, event.target.value)
            }
          />
        </div>
      </div>

      <div className={styles.preview}>
        {!preview && (
          <div className={styles.preview__loading}>
            <Loading size={LoadingSize.l} />
          </div>
        )}

        {preview?.map((group) => (
          <PreviewItem group={group} key={`preview__${group.label}`} />
        ))}
      </div>
    </div>
  )
}
