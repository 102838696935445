export class ErrorGeneratingSVG extends Error {
  constructor(message = 'Could not generate SVG') {
    super(message)
  }
}

export class ErrorGeneratingPDF extends Error {
  constructor(message = 'Could not generate PDF') {
    super(message)
  }
}
