import { ReactNode } from 'react'

export enum WrapperSize {
  medium = 'medium',
  big = 'big'
}

export interface WrapperProps {
  children?: ReactNode
  e2eTargetName?: string
  size?: WrapperSize
}
