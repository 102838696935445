import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { GenerateDiecutView } from '../generate-diecut-view/GenerateDiecutView'
import { MyDiecutsView } from '../my-diecuts-view/MyDiecutsView'
import { DiecutView } from '../diecut-view/DiecutView'

export const DiecutsView = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Switch>
        <Route
          path={`${path}/generate/:template?/:dielineUuid?`}
          component={GenerateDiecutView}
        />
        <Route exact path={`${path}/my-diecuts`} component={MyDiecutsView} />
        <Route
          exact
          path={`${path}/my-diecuts/:diecutUuid`}
          component={DiecutView}
        />
        <Redirect exact from={path} to={`${path}/generate/`} />
      </Switch>
    </>
  )
}
