import React from 'react'
import { observer } from 'mobx-react'
import { Heading } from '../../../../layout/components/heading/Heading'
import { HeadingSize } from '../../../../layout/components/heading/types'
import { Actions } from '../actions/Actions'
import { HeaderProps } from './types'
import styles from './Header.module.scss'

export const Header = observer(({ dieline }: HeaderProps) => {
  return (
    <div className={styles['header']}>
      <Heading size={HeadingSize.h1}>{dieline.label}</Heading>
      <Actions dieline={dieline} />
    </div>
  )
})
