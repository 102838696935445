import React from 'react'
import { LinkElement } from '@packhelp/platform-dsl/atoms'
import { FormatMessage } from '../../../../../libs/localisation/LocaleIntlProvider'
import { CheckboxField } from '../../../../form'
import translations from './translations'

export const Agreement = ({
  error,
  value,
  onChange
}: {
  value: boolean
  onChange?: (value: boolean) => void
  error?: string
}) => {
  return (
    <CheckboxField
      name="agreement"
      value={value}
      error={error}
      onChange={onChange}
      label={
        <FormatMessage
          id={translations.agreement}
          values={{
            termsOfService: (
              <LinkElement to="https://packhelp.com/terms_of_service/" external>
                <FormatMessage id={translations.termsOfService} />
              </LinkElement>
            ),
            privacyPolicy: (
              <LinkElement to="https://packhelp.com/privacy_policy/" external>
                <FormatMessage id={translations.privacyPolicy} />
              </LinkElement>
            )
          }}
        />
      }
    />
  )
}
