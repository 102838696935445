import { UserInfo } from '../../services/vma-api'

export interface User {
  authenticated: boolean
}

export interface UnauthenticatedUser extends User {
  authenticated: false
}

export interface AuthenticatedUser extends User, UserInfo {
  authenticated: true
}

export type UserStoreData = UnauthenticatedUser | AuthenticatedUser

export class InactiveUserError extends Error {
  constructor() {
    super('User account is not yet confirmed.')
    Object.setPrototypeOf(this, InactiveUserError.prototype)
  }
}

export class MissingTokenError extends Error {
  constructor() {
    super('Login failed')
    Object.setPrototypeOf(this, MissingTokenError.prototype)
  }
}
