import { SelectData } from '@packhelp/platform-dsl/molecules'

export const thicknessSelectData: SelectData[] = [
  {
    label: '0.4 mm',
    value: '0.4'
  },
  {
    label: '0.5 mm',
    value: '0.5'
  },
  {
    label: '1 mm',
    value: '1'
  },
  {
    label: '1.5 mm',
    value: '1.5'
  },
  {
    label: '3 mm',
    value: '3'
  },
  {
    label: '4 mm',
    value: '4'
  }
]
