import React, { useEffect, useMemo } from 'react'
import {
  ColumnAlign,
  PaddingType,
  RowData,
  TableConfig
} from '@packhelp/platform-dsl/components/Table'
import { observer } from 'mobx-react'
import { Icon, LinkElement, TIcon } from '@packhelp/platform-dsl/atoms'
import mapValues from 'lodash/mapValues'
import { useRootStore } from '../../../hooks'
import { UrlToSvg } from '../../url-to-svg/UrlToSvg'
import { NumberOptionStore } from '../../../stores'
import { LoadingView } from '../loading-view/LoadingView'
import { ListView } from '../list-view/ListView'
import { Pagination } from '../list-view/types'
import { useFormatMessage } from '../../../libs/localisation/LocaleIntlProvider'
import styles from './MyDielinesView.module.scss'
import translations from './translations'

export const MyDielinesView = observer(() => {
  const { dielinesStore } = useRootStore()
  useEffect(() => {
    !dielinesStore.isDielinesInitialized &&
      !dielinesStore.isDielinesLoading &&
      dielinesStore.getDielines()
  }, [])

  const messages = mapValues(translations, useFormatMessage)

  const config: TableConfig = {
    idAccessor: 'my-dielines',
    columns: [
      {
        uuid: 'product_type',
        label: messages.productType,
        cellRenderer: (data) => {
          return (
            <>
              <div className={styles['dieline-preview']}>
                <UrlToSvg
                  url={data.svg}
                  e2eTargetName={`dieline-${data.uuid}`}
                />
              </div>
              <LinkElement
                to={`/tools/dielines/templates/${data.templateSlug}/${data.uuid}`}
              >
                <span className={styles['dieline-label']}>
                  {data.templateLabel}
                </span>
              </LinkElement>
            </>
          )
        },
        align: ColumnAlign.left,
        maxWidth: 408
      },
      {
        uuid: 'size',
        label: messages.size,
        side: 'right'
      },
      {
        uuid: 'date_created',
        label: messages.dateCreated,
        side: 'right'
      },
      {
        uuid: 'file',
        cellRenderer: (data) => (
          <a
            {...data}
            rel="enclosure"
            className={styles['download-file']}
            target="_blank"
          >
            <Icon icon={TIcon.Download} />
          </a>
        ),
        paddingType: PaddingType.l,
        side: 'right'
      }
    ]
  }

  const data: RowData = useMemo(
    () =>
      dielinesStore.isDielinesInitialized
        ? dielinesStore.dielines.map((dieline) => ({
            product_type: dieline,
            size: dieline.dimensionOptions
              .map((dimension: NumberOptionStore) => dimension.value)
              .join('x')
              .concat(' mm'),
            date_created: dieline.createdAt,
            file: {
              href: dieline.pdfObjectUrl,
              download: `${dieline.slug}.pdf`
            }
          }))
        : [],
    [dielinesStore.dielines]
  )

  if (!dielinesStore.isDielinesInitialized) {
    return <LoadingView />
  }

  return (
    <ListView
      title={messages.header}
      e2eTargetName="my-dielines"
      config={config}
      data={data}
      pagination={dielinesStore.pagination as Pagination}
    />
  )
})
