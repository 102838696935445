import React from 'react'
import { Estimation } from '../../../../../../../../../../../../../services'
import styles from './ItemContent.module.scss'
import { ExtraCosts } from './components/extraCosts'
import { Technology } from './components/technology'
import { Shipping } from './components/shipping'

type Props = {
  estimation: Estimation
}

const ItemContent: React.FC<Props> = ({ estimation }) => {
  return (
    <div className={styles.root}>
      <ExtraCosts estimation={estimation} />
      <Technology estimation={estimation} />
      <Shipping estimation={estimation} />
    </div>
  )
}

export default ItemContent
