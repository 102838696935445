import React, { useState } from 'react'
import { VmaApi } from '../../../../../../../services/vma-api'
import {
  ErrorsState,
  ValuesState
} from '../../../../../../../hooks/form-validation'
import { SettingsFields } from '../../types'
import { useRootStore } from '../../../../../../../hooks'
import { handleError } from '../../../../../../../utils/handle-error/handle-error'
import { snackbar } from '../../../../../../../utils/snackbar/snackbar'
import { useTranslations } from '../../../../../../../libs/localisation/LocaleIntlProvider'
import translations from '../../translations'

export const useActions = () => {
  const [isSavingChanges, setSavingChanges] = useState(false)
  const { userStore } = useRootStore()
  const messages = useTranslations(translations)
  const vmaApi = new VmaApi()

  const saveChanges = (
    values: ValuesState<SettingsFields>,
    errors: ErrorsState<SettingsFields>
  ) => async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    if (Object.keys(errors).length !== 0) return
    try {
      setSavingChanges(true)
      await vmaApi.updateProfile({
        uuid: userStore.uuid as string,
        firstName: values.firstName as string,
        lastName: values.lastName as string,
        email: values.email as string,
        phoneNumber: values.phoneNumber as string,
        companyRole: values.companyRole as string
      })
      snackbar.success(messages.changesSaved)
    } catch (error) {
      handleError(error)
    } finally {
      setSavingChanges(false)
    }
  }
  return {
    saveChanges,
    isSavingChanges
  }
}
