import React from 'react'
import cxBinder from 'classnames/bind'
import styles from './Sidebar.module.scss'
import { SidebarPadding, SidebarProps, SidebarSide } from './types'

const cx = cxBinder.bind(styles)

export const Sidebar = ({
  children,
  side = SidebarSide.left,
  padding = SidebarPadding.extraSmall,
  e2eTargetName
}: SidebarProps) => {
  return (
    <div
      e2e-target="layout-sidebar"
      e2e-target-name={e2eTargetName}
      className={cx('sidebar', {
        [`sidebar--${side}`]: true,
        [`sidebar--padding-${padding}`]: padding
      })}
    >
      {children}
    </div>
  )
}
