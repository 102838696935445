import React from 'react'
import { MultiSelect } from '@packhelp/platform-dsl/molecules'
import { PopupSizeProp } from '@packhelp/platform-dsl/atoms/Popup'
import { v4 as uuidv4 } from 'uuid'
import { FormError } from '../../views/generate-dieline-view/components/form-error/FormError'
import { Field } from '../field/Field'
import { MultiSelectFieldProps } from './types'

interface ListProps {
  label: string
  value: string
}

export const MultiSelectField = ({
  listData,
  name,
  label,
  checkedData,
  onChange,
  error,
  placeholder,
  useStartCase = false,
  disabled = false
}: MultiSelectFieldProps) => {
  return (
    <Field>
      <label htmlFor={name}>{label}</label>

      <MultiSelect
        checkedData={checkedData}
        listData={listData}
        onSelectChange={onChange}
        popupSize={PopupSizeProp.auto}
        renderValue={(listData: ListProps[], checkedData: string[]) => {
          return checkedData.length > 0 ? (
            checkedData.map((option) => (
              <React.Fragment key={uuidv4()}>
                {option}
                <br />
              </React.Fragment>
            ))
          ) : (
            <>{placeholder}</>
          )
        }}
        useStartCase={useStartCase}
        disabled={disabled}
      />
      <FormError error={error} />
    </Field>
  )
}
