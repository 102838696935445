export type ChangePasswordModalProps = {
  isOpen: boolean
  toggleOpen: () => void
}

export enum ChangePasswordFields {
  oldPassword = 'oldPassword',
  password = 'password',
  confirmPassword = 'confirmPassword'
}
