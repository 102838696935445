import { ScrollTopHookType } from './types'

export const useScrollTop = (): ScrollTopHookType => {
  const root = document.querySelector('#root')
  const scrollTop = (offsetY = 0) => {
    if (root) root.scrollTop = offsetY
  }

  return scrollTop
}
