import React, { useEffect, useMemo, useState } from 'react'
import { Loading, LoadingSize } from '@packhelp/platform-dsl/atoms'
import { useParams } from 'react-router'
import { Heading, HeadingSize, MarginSize } from '../../../../layout/components'
import { ReturnLink } from '../return-link/ReturnLink'
import translations from './translations'
import { confirmAccount } from './actions'

import { FormatMessage } from './../../../../../libs/localisation/LocaleIntlProvider'

export const AccountConfirmed = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const { token, uuid } = useParams<{ token: string; uuid: string }>()
  useEffect(() => {
    confirmAccount(uuid, token, setIsLoading, setIsConfirmed)
  }, [])

  const headingText = useMemo(
    () =>
      isLoading
        ? translations.inProgress
        : isConfirmed
        ? translations.confirmed
        : translations.failed,
    [isLoading, isConfirmed, translations]
  )

  const content = useMemo(
    () =>
      isLoading ? (
        <Loading size={LoadingSize.l} />
      ) : isConfirmed ? (
        <FormatMessage id={translations.allowLogin} />
      ) : (
        <FormatMessage id={translations.error} />
      ),
    [isLoading, isConfirmed, translations]
  )

  return (
    <>
      <Heading size={HeadingSize.h1} marginBottom={MarginSize.xl}>
        <FormatMessage id={headingText} />
      </Heading>
      {content}
      <ReturnLink />
    </>
  )
}
