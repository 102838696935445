import { EMAIL, PHONE_NUMBER } from './regexp'
import { FieldName, ValidationRule } from './types'

const passwordRule: (name: FieldName) => ValidationRule = (name) => (values) =>
  !values[name] ? 'invalidPassword' : undefined

export const validationRules: {
  [key in FieldName]: ValidationRule
} = {
  firstName: () => undefined,
  lastName: () => undefined,
  companyRole: () => undefined,
  phoneNumber: (values) =>
    !values.phoneNumber || PHONE_NUMBER.test(values.phoneNumber)
      ? undefined
      : 'invalidPhoneNumber',
  email: (values) =>
    !values.email || !EMAIL.test(values.email) ? 'invalidEmail' : undefined,
  password: passwordRule('password'),
  oldPassword: passwordRule('oldPassword'),
  confirmPassword: (values) =>
    values.confirmPassword !== values.password
      ? 'invalidConfirmPassword'
      : undefined,
  agreement: (values) => (!values.agreement ? 'invalidAgreement' : undefined)
}
