import React, { ReactNode } from 'react'
import cxBinder from 'classnames/bind'
import { useSearchParams } from '../../utils/search-params/search-params'
import styles from './Layout.module.scss'
import { Header, Body } from './components'

const cx = cxBinder.bind(styles)

export const Layout = ({
  children,
  e2eTargetName
}: {
  children?: ReactNode
  e2eTargetName?: string
}) => {
  const { query, initialQuery } = useSearchParams()
  const isHeaderless =
    (query.get('headerless') || initialQuery.get('headerless')) === 'true'

  return (
    <div
      className={cx('layout')}
      e2e-target="layout"
      e2e-target-name={e2eTargetName}
    >
      {!isHeaderless && <Header />}
      <Body>{children}</Body>
    </div>
  )
}
