import { action, computed, observable, when } from 'mobx'
import { DielineConfig, DielinesService } from '../../services'
import { handleError } from '../../utils/handle-error/handle-error'
import { DielineStore } from '../dieline-store/dieline-store'
import { PaginatedStoreData, StoreData, StoreDataState } from '../types'
import { RootStore } from '../root-store'
import { SegmentAnalytics } from '../../libs/segment-analytics'
import { DielineNotFound, ProductTemplateNotFound } from './types'

export class DielinesStore {
  @observable.shallow private _templates: StoreData<DielineStore[]> = {
    state: StoreDataState.pristine,
    data: []
  }
  @observable.shallow private _dielines: PaginatedStoreData<DielineStore[]> = {
    state: StoreDataState.pristine,
    data: []
  }

  constructor(
    private _rootStore: RootStore,
    private _dielinesService = new DielinesService()
  ) {
    this._initialize()
  }

  @computed get isTemplatesInitialized() {
    return this._templates.state === StoreDataState.ok
  }

  @computed get isDielinesInitialized() {
    return this._dielines.state === StoreDataState.ok
  }

  @computed get isDielinesLoading() {
    return this._dielines.state === StoreDataState.loading
  }

  @computed get pagination() {
    if (this._dielines.state !== StoreDataState.ok) {
      return undefined
    }
    const { page, per_page, total } = this._dielines.meta
    return {
      page,
      pages: Math.ceil(total / per_page),
      perPage: per_page,
      changePage: (newPage: number) => {
        this.getDielines(newPage, per_page)
      },
      changePerPage: (perPage: number) => {
        const newPage = Math.ceil(((page - 1) * per_page) / total)
        this.getDielines(newPage, perPage)
      }
    }
  }

  @computed get templates() {
    return this._templates.data
  }

  @computed get packingAvailableTemplates() {
    return this._templates.data.filter(
      (template) => template.isPackingAvailable
    )
  }

  @computed get dielines() {
    return this._dielines.data
  }

  @action.bound public newDieline(productType: string): DielineStore {
    const productTemplate = this.templates.find((template) => {
      return template.templateSlug === productType
    })

    if (!productTemplate) {
      throw new ProductTemplateNotFound()
    }

    return productTemplate.getCopy()
  }

  @action.bound public async save(dielineToSave: DielineStore) {
    const dielineIndex = this.dielines.findIndex((dieline) => {
      return dieline.uuid === dielineToSave.uuid
    })

    if (this._rootStore.userStore.isAuthenticated) {
      try {
        this._dielines.state = StoreDataState.loading

        const uuid = await this._dielinesService.saveDieline(
          dielineToSave.toJSON(),
          this._rootStore.userStore.uuid as string
        )
        await this.getDielines()
        dielineToSave.uuid = uuid
        SegmentAnalytics.dielineGenerated(
          dielineToSave,
          this._rootStore.userStore
        )
      } catch (error) {
        handleError(error)
        this._dielines = {
          data: [],
          state: StoreDataState.error,
          error
        }
      }
      return
    }

    if (dielineIndex < 0) {
      this._dielines.data.unshift(dielineToSave)
      SegmentAnalytics.dielineGenerated(
        dielineToSave,
        this._rootStore.userStore
      )
    } else {
      this._dielines.data[dielineIndex] = dielineToSave
    }
    this._dielines.state = StoreDataState.ok
  }

  public async getDieline(uuid: string): Promise<DielineStore> {
    let dieline = this.dielines.find((dieline) => {
      return dieline.uuid === uuid
    })

    if (!dieline) {
      const dielineConfig = await this._dielinesService.loadDieline(uuid)
      dieline = dielineConfig && new DielineStore(this, dielineConfig)
      dieline && this._dielines.data.push(dieline)
    }

    if (!dieline) {
      throw new DielineNotFound()
    }

    return dieline
  }

  @action.bound private async _initialize() {
    await Promise.all([this._getProductTemplates(), this.getDielines()])
  }

  @action.bound private async _getProductTemplates() {
    this._templates.state = StoreDataState.loading
    try {
      const productConfigs = await this._dielinesService.loadProductTemplates()
      this._templates = {
        data: this._mapProductConfigsToDielineStores(productConfigs),
        state: StoreDataState.ok
      }
    } catch (error) {
      this._templates = {
        data: [],
        state: StoreDataState.error,
        error
      }
      handleError(error)
    }
  }

  @action.bound async getDielines(page?: number, perPage?: number) {
    await when(() => this._rootStore.userStore.isInitialized)
    if (this._rootStore.userStore.isAuthenticated) {
      try {
        this._dielines.state = StoreDataState.loading
        const { dielines, meta } = await this._dielinesService.loadDielines(
          page,
          perPage
        )

        this._dielines = {
          data: this._mapProductConfigsToDielineStores(dielines),
          state: StoreDataState.ok,
          meta
        }
      } catch (error) {
        this._dielines = {
          data: [],
          state: StoreDataState.error,
          error
        }
        handleError(error)
      }
      return
    }

    this._dielines = {
      data: [],
      meta: { total: 0, page: 1, per_page: 30 },
      state: StoreDataState.ok
    }
  }

  private _mapProductConfigsToDielineStores = (
    productConfigs: DielineConfig[]
  ): DielineStore[] => {
    return productConfigs.map(
      (config: DielineConfig) => new DielineStore(this, config)
    )
  }
}
