import { snackbar } from '../snackbar/snackbar'

export const handleError = (
  error: Error | string | string[],
  throwError?: boolean
) => {
  console.error(error)

  let errorMessage: string
  if (error instanceof Error) {
    errorMessage = error.message
  } else if (Array.isArray(error)) {
    errorMessage = error.join('; ')
  } else {
    errorMessage = error
  }

  snackbar.error(errorMessage)

  if (throwError) throw new Error(errorMessage)
}
