import flatten from 'lodash/flatten'
import {
  ProductSpecification,
  ProductSpecificationConfig,
  ParsedGroup,
  ParsedAttribute
} from '@packhelp/platform-pss-form'
import { Attribute } from '@packhelp/platform-pss-form/types/config'
import { productSpecificationParser } from './productSpecParser'

export type ProductSpecPreview = {
  attributes: ParsedAttribute[]
} & ParsedGroup

export const productSpecPreview = (
  productSpecification: ProductSpecification,
  productSpecificationConfig: ProductSpecificationConfig
): ProductSpecPreview[] => {
  const {
    groups,
    fields,
    formik: { values }
  } = productSpecificationParser(
    productSpecification.attributes,
    productSpecificationConfig
  )

  return groups.reduce<ProductSpecPreview[]>((productSpecPreviews, group) => {
    const attributes = flatten(group.rows)
      .filter((attribute: string) => attribute in values)
      .map((attribute: string) => ({
        ...(fields[attribute] as object),
        value: values[attribute]
      }))

    if (attributes.length) {
      productSpecPreviews.push({
        ...group,
        attributes: attributes as ParsedAttribute[] & Attribute[]
      })
    }

    return productSpecPreviews
  }, [])
}

export const getAttributeUnit = (attribute: ParsedAttribute): string => {
  return attribute.unit || attribute.default_unit
    ? ` ${attribute.unit || attribute.default_unit}`
    : ''
}

export const valueWithUnit = (
  attribute: ParsedAttribute,
  selectedValue: unknown
): string => {
  const unit = getAttributeUnit(attribute)
  const option = attribute?.options?.find(
    (option) => option.value === selectedValue
  )
  const value = option?.label || selectedValue

  return `${value}${unit}`
}

export const attributeValueToString = (attribute: ParsedAttribute): string => {
  if (attribute.value === false) {
    return 'no'
  }

  if (attribute.value === true) {
    return 'yes'
  }

  if (attribute.value === undefined) {
    return '-'
  }

  if (Array.isArray(attribute.value)) {
    return attribute.value
      .map((value) => valueWithUnit(attribute, value))
      .join(', ')
  } else {
    return valueWithUnit(attribute, attribute.value)
  }
}
