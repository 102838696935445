import React from 'react'
import { Redirect, useParams } from 'react-router'
import { observer } from 'mobx-react'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { Content, Wrapper } from '../../layout'
import { useData } from '../generate-diecut-view/hooks/data/data'
import { Heading } from '../../layout/components/heading/Heading'
import { HeadingSize } from '../../layout/components/heading/types'
import { GenerateProductForm } from '../forms'
import { GeneratorPlaceholder } from '../placeholders/generator-placeholder/GeneratorPlaceholder'
import styles from './GenerateDielineView.module.scss'
import { useHighlight } from './hooks/highlight/highlight'
import { DielineTemplate } from './components/dieline-template/DielineTemplate'
import { useActions } from './hooks/actions/actions'

import { Actions } from './components/actions/Actions'
import { FormatMessage } from './../../../libs/localisation/LocaleIntlProvider'
import { DielineView } from '../../views/dieline-view/DielineView'
import translations from './translations'

export const GenerateDielineView = observer(() => {
  const { redirect, dieline, template } = useData()
  const { dielineUuid } = useParams<{
    dielineUuid?: string
  }>()

  const { highlight, setHighlight, clearHighlight } = useHighlight()

  const { generateDieline } = useActions(dieline, template)

  if (redirect) {
    return <Redirect to="/404" />
  }

  if (!dieline) {
    return <LoadingContent />
  }

  return (
    <form
      onSubmit={generateDieline}
      className={styles['generate-dieline']}
      e2e-target="generate-dieline"
    >
      <Content>
        <Wrapper>
          <Heading size={HeadingSize.h1}>
            <FormatMessage id={translations.header} />
          </Heading>
          <div className={styles['generate-dieline__content']}>
            <GenerateProductForm
              template={template}
              route="/tools/dielines/templates"
              dieline={dieline}
              onFocus={setHighlight}
              onBlur={clearHighlight}
              forPacking={false}
            >
              {dieline && <Actions dieline={dieline} />}
            </GenerateProductForm>

            {!dielineUuid &&
              (template ? (
                <DielineTemplate template={template} highlight={highlight} />
              ) : (
                <GeneratorPlaceholder
                  titleI18n={translations.generatorPlaceholder}
                />
              ))}
            {dielineUuid && <DielineView />}
          </div>
        </Wrapper>
      </Content>
    </form>
  )
})
