import React, { useEffect, useMemo, useState } from 'react'
import { Icon, Input, TIcon } from '@packhelp/platform-dsl/atoms'
import { Select, SelectSize } from '@packhelp/platform-dsl/molecules'
import cxBinder from 'classnames/bind'
import { FormError } from '../../views/generate-dieline-view/components/form-error/FormError'
import { Field } from '../field/Field'
import { PhoneNumberHelper } from './helpers/phone-number-helper'
import { TextFieldProps } from './types'
import styles from './PhoneField.module.scss'

const cx = cxBinder.bind(styles)

export const PhoneField = ({
  value: passedValue,
  required = true,
  name,
  label,
  error,
  onChange,
  onFocus,
  onBlur
}: TextFieldProps) => {
  const { nationalNumber, countryCallingCode } = useMemo(
    () =>
      PhoneNumberHelper.phoneNumberDescription(passedValue || '') || {
        nationalNumber: '',
        countryCallingCode: 48
      },
    []
  )
  const [number, setNumber] = useState(String(nationalNumber))
  const [code, setCode] = useState(`+${countryCallingCode}`)
  const [isCodeSelectOpen, setCodeSelectOpen] = useState(false)

  useEffect(() => {
    onChange && onChange(number ? code + number : null)
  }, [number, code])

  const options = useMemo(
    () =>
      PhoneNumberHelper.getPhoneNumberPrefixesWithCountryCodes()
        .filter((country) =>
          Object.values(TIcon).includes(
            `Flags${country.countryCode.replace(/^\w/, (c) =>
              c.toUpperCase()
            )}` as TIcon
          )
        )
        .map((country) => ({
          value: `+${country.prefix}`,
          label: (
            <div className={styles['code-label']}>
              <Icon
                icon={
                  TIcon[
                    `Flags${country.countryCode.replace(/^\w/, (c) =>
                      c.toUpperCase()
                    )}` as TIcon
                  ]
                }
              />
              +{country.prefix}
            </div>
          )
        })),
    []
  )

  return (
    <Field>
      <label htmlFor={name}>{label}</label>
      <div className={styles.wrapper}>
        <div className={cx('select-code', { open: isCodeSelectOpen })}>
          <Select
            name="code"
            data={options}
            value={code}
            error={!!error}
            onSelect={(value) => {
              setCode(value)
              setCodeSelectOpen(false)
            }}
            size={SelectSize.large}
            onOpen={() => setCodeSelectOpen(true)}
            onClose={() => setCodeSelectOpen(false)}
          />
        </div>
        <div className={styles['input-number']}>
          <Input
            type="number"
            name={name}
            error={!!error}
            value={number}
            onChange={(e) => setNumber(e.currentTarget.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            required={required}
            controlled={true}
          />
        </div>
      </div>
      <FormError error={error} />
    </Field>
  )
}
