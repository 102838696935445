import React from 'react'
import { Button, ButtonSize, LinkElement } from '@packhelp/platform-dsl/atoms'
import { observer } from 'mobx-react'
import { TwoColumnLayout, Heading, HeadingSize, MarginSize } from '../../layout'
import { Field, TextField } from '../../form'
import { useFormValidation } from '../../../hooks/form-validation'
import { useRootStore } from '../../../hooks'
import styles from './LoginView.module.scss'
import { login } from './actions'
import translations from './translations'

import {
  FormatMessage,
  useFormatMessage
} from './../../../libs/localisation/LocaleIntlProvider'

export const LoginView = observer(() => {
  const { userStore } = useRootStore()
  const {
    values,
    errors,
    touched,
    createChangeHandler,
    createTouchHandler
  } = useFormValidation({
    email: null,
    password: null
  })

  return (
    <>
      <Heading size={HeadingSize.h1} marginBottom={MarginSize.xl}>
        <FormatMessage id={translations.title} />
      </Heading>
      <form onSubmit={login(values, errors, userStore.login)}>
        <Heading size={HeadingSize.h4}>
          <FormatMessage id={translations.signIn} />
        </Heading>
        <Field>
          <TextField
            label={useFormatMessage(translations.email)}
            value={values.email}
            name="email"
            onChange={createChangeHandler('email')}
            onBlur={createTouchHandler('email')}
            error={touched.email && errors.email}
          />
        </Field>
        <Field>
          <TextField
            label={useFormatMessage(translations.password)}
            value={values.password}
            name="password"
            onChange={createChangeHandler('password')}
            onBlur={createTouchHandler('password')}
            type="password"
            error={touched.password && errors.password}
          />
        </Field>
        <Button
          size={ButtonSize.large}
          fullWidth
          isLoading={userStore.isLoading}
        >
          <FormatMessage id={translations.login} />
        </Button>
        <div className={styles['forgot-password']}>
          <FormatMessage id={translations.forgotPassword} />{' '}
          <LinkElement to="/auth/password/reset">
            <FormatMessage id={translations.resetPassword} />
          </LinkElement>
        </div>
      </form>
    </>
  )
})
