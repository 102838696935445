import { AxiosError } from 'axios'
import { ProductSpecification } from '@packhelp/platform-pss-form'
export interface DiecutContainerConfig {
  width: number | null
  height: number | null
}

export interface DiecutCreateRequestOptions {
  with_die_cut_info?: boolean
  previews?: boolean
  previews_format?: 'png' | 'svg'
  max_boxes?: number | undefined | null
  distance_between_boxes?: number | undefined | null
}

export interface DiecutCreateRequest {
  sizes: DielineConfigOptions
  containers?: DiecutContainerConfig[]
  product_type: string
  format?: string
  options?: DiecutCreateRequestOptions
}

export interface DiecutContainerResponse {
  width: number
  height: number
  thumbnail: string
  boxes: Array<unknown>
  die_cut_info: {
    cut_length: number
    crease_length: number
  }
}

export interface DiecutContainersResponse {
  containers: DiecutContainerResponse[]
}

export interface DiecutCreateResponse {
  data: {
    id: string
    response_data: DiecutContainersResponse | DiecutContainerConfig[] | string
    name?: string
  }
}

export interface DielineConfig {
  created_at?: string
  name?: string
  options: DielineConfigOptions
  options_custom_order?: string[]
  product_type: string
  is_packing_available?: boolean
  uuid?: string
  psvg_file?: string
  pdf?: string
  svg?: string
  elements?: DielineConfigElement[]
}

export interface InstantEstimationRequest {
  specification: ProductSpecification
  quantities: number[]
  shippingDestination: {
    country: string | null
    postalCode: string | null
  }
}

export interface Cost {
  amount: string
  currency: string
}

export interface Supplier {
  id: string
  name: string
}

export interface Item {
  weight: {
    amount: string
    unit: string
  } | null
}

export interface LeadTime {
  working_days: number
}

export interface PriceList {
  id: string
  name: string
  quantity: number
  unit_price: Cost
}

export interface Material {
  id: string
  cost: Cost
  name: string
  unit_cost: Cost
  unit: string
  sheet_area: number
  total_area: number
  length: number
  price_list: PriceList
}

export interface Operation {
  id: string
  name: string
  cost: Cost
  type: string
  quantity: number
  attributes: Array<{ name: string; type: string; value: string }>
  input_type: string
  price_list: PriceList
  output_type: string
  tools: Tool[]
}

export interface Tool {
  cost: Cost
  size: string
  type: string
  width: number
  height: number
  elements: Array<{
    name: string
    cost: Cost
    x: number
    y: number
    type: string
    depth: number
    width: number
    height: number
    rotation: number
    bbox_width: number
    bbox_height: number
  }>
}

export interface Technology {
  materials: Material[]
  operations: Operation[]
  tools: Tool[]
}

export interface PalletsService {
  name: string
  cost: Cost
  transit_times: string
  carrier?: string
  number_of_pallets?: number[]
}
export interface PackingBoxes {
  cost: Cost | null
  quantity: number
  products_per_box: number
  product_weight: { amount: number; unit: string } | null
  size: {
    depth: { value: number; unit: string }
    width: { value: number; unit: string }
    height: { value: number; unit: string }
  }
}

export interface PackingPallets {
  cost: Cost | null
  type: 'eur pallet' | 'custom pallet'
  boxes_per_pallet: number
  boxes_rotation: number
  height: { value: number; unit: string }
  height_brutto: { value: number; unit: string } | null
  products_per_pallet: number
  quantity: number
  stacks: number
  width: { value: number; unit: string }
  width_brutto: { value: number; unit: string } | null
  depth: { value: number; unit: string } | null
  depth_brutto: { value: number; unit: string } | null
  pallet_weight: { value: number; unit: string }
  pallet_weight_unit: string
  services?: PalletsService[]
  product_weight: { amount: number; unit: string } | null
}

export interface Estimation {
  id: string
  quantity: number
  created_at: string
  total_cost: Cost
  supplier: Supplier
  item: Item
  info?: {
    sheet?: {
      elements: number
      height: {
        value: number
        unit: string
      }
      width: {
        value: number
        unit: string
      }
    }
    die_cuts: Array<{
      name?: string
      elements: number
      width?: {
        value: number
        unit: string
      }
      height?: {
        value: number
        unit: string
      }
    }>
  }
  isAccepted: boolean | undefined
  production_cost: Cost | null
  tools_cost: Cost | null
  unit_cost: Cost
  lead_times: LeadTime[]
  technology: Technology | null
  packing: {
    boxes: PackingBoxes[]
    pallets: PackingPallets[]
  }
  comment: string | null
}

export interface InstantEstimationResponse {
  estimations: Estimation[]
  warnings: string[]
  requestCancelled: boolean
}

export interface DielineCreateRequest extends DielineConfig {
  format: DielineFormat
}

export interface DielineCreateResponse extends DielineConfig {
  data: {
    id: string
    response_data: string
    name?: string
  }
}

export interface DielineConfigElement {
  data_modifiers?: DataModifiers
  name: string
  product_type: string
}

export enum DielineFormat {
  svg = 'svg',
  pdf = 'pdf'
}

export enum DimensionOptions {
  width = 'width',
  depth = 'depth',
  height = 'height',
  length = 'length'
}

export type DataModifiers = {
  [key in DimensionOptions]?: number
}
export interface DielineConfigOptions {
  height: number | null
  width: number | null
  [option: string]: number | string | boolean | null
}

export class BoxesFlowApiError extends Error {
  public options: { key: string; message: string }[] = []

  constructor(error: AxiosError<{ options: { [key: string]: string } }>) {
    super(
      error.response &&
        error.response.data &&
        Array.isArray(error.response.data)
        ? error.response.data[0]
        : error.message
    )

    if (error.response && error.response.data && error.response.data.options) {
      const options = error.response.data.options
      this.options = Object.keys(options).map((key) => ({
        key,
        message: options[key]
      }))
    }
  }
}

export class DielinesTemplatesFetchingError extends BoxesFlowApiError {}

export class DielineCreateError extends BoxesFlowApiError {}
export class DiecutCreateError extends BoxesFlowApiError {}
