import { pipe } from 'lodash/fp'
import {
  FormBuilderParams,
  FormConstraintsHandler,
  FormGroupsHandler,
  FormRowsHandler,
  ParsedProductSpecification,
  ProductSpecificationAttributes,
  ProductSpecificationConfig
} from '@packhelp/platform-pss-form'

export const productSpecificationParser = (
  attributes: ProductSpecificationAttributes,
  config: ProductSpecificationConfig
): ParsedProductSpecification => {
  const constraintHandler = new FormConstraintsHandler()
  const groupsHandler = new FormGroupsHandler()
  const rowsHandler = new FormRowsHandler()

  return pipe(
    constraintHandler.apply,
    groupsHandler.array,
    groupsHandler.sort,
    rowsHandler.array,
    rowsHandler.filterRowValues,
    rowsHandler.addMissingRows,
    rowsHandler.array,
    rowsHandler.clean,
    groupsHandler.clean
  )({
    formik: { values: attributes },
    config: config
  } as FormBuilderParams)
}
