import React, { useContext, useMemo } from 'react'
import {
  Loading,
  TType,
  Typography,
  LoadingSize
} from '@packhelp/platform-dsl/atoms'
import cxBinder from 'classnames/bind'
import { AvailabilityState, useActions } from './hooks/actions'
import styles from './AvailabilityBox.module.scss'
import { InstantPriceContext } from '../../InstantPrice.context'
import { ProductSpecification } from '@packhelp/platform-pss-form'

const cx = cxBinder.bind(styles)

export const AvailabilityBox: React.FC = () => {
  const { specification, postalCode, country, boxesflow } = useContext(InstantPriceContext)
  const { currentStateText, currentState } = useActions(
    specification as ProductSpecification,
    postalCode,
    country,
    boxesflow
  )

  const stateToStyle: Record<AvailabilityState, string | null> = {
    [AvailabilityState.available]: styles.available,
    [AvailabilityState.loading]: styles.loading,
    [AvailabilityState.notAvailable]: styles.notAvailable,
    [AvailabilityState.notReady]: null
  }

  const isLoading = useMemo(() => currentState == AvailabilityState.loading, [
    currentState
  ])

  return (
    <div className={cx('availability-box', stateToStyle[currentState])}>
      {isLoading && <Loading size={LoadingSize.s} />}
      <Typography type={TType.Regular14_16}>{currentStateText}</Typography>
    </div>
  )
}

export default AvailabilityBox
