import React from 'react'
import cxBinder from 'classnames/bind'
import { observer } from 'mobx-react'
import { Actions } from '../actions/Actions'
import { SecondaryParameters } from '../secondary-parameters/SecondaryParameters'
import { Separator } from '../../../../layout'
import { FormProps } from './types'
import styles from './Form.module.scss'

const cx = cxBinder.bind(styles)

export const Form = observer(({ diecut }: FormProps) => {
  return (
    <div className={cx('form')} e2e-target="form">
      <Separator withLine={false} />
      <SecondaryParameters diecut={diecut} />
      <Separator />
      <Actions diecut={diecut} />
    </div>
  )
})
