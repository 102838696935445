import React, { useEffect } from 'react'
import axios from 'axios'
import { StringToSvg } from '../string-to-svg/StringToSvg'
import { UrlToSvgProps } from './types'
import styles from './UrlToSvg.module.scss'

export const UrlToSvg = ({
  url,
  e2eTargetName,
  shouldRotate
}: UrlToSvgProps) => {
  const [svg, setSvg] = React.useState<string | null>(null)

  const loadSvg = async () => {
    setSvg((await axios.get(url)).data)
  }

  useEffect(() => {
    if (url) loadSvg()
  }, [url])

  return svg !== null ? (
    <div e2e-target="url-to-svg" className={styles['url-to-svg']}>
      <StringToSvg
        svgString={svg}
        e2eTargetName={e2eTargetName}
        shouldRotate={shouldRotate}
      />
    </div>
  ) : null
}
