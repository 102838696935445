import React, { PropsWithChildren } from 'react'
import { SingleRowData, Table } from '@packhelp/platform-dsl/components/Table'
import { observer } from 'mobx-react'
import { Pagination } from '@packhelp/platform-dsl/atoms'
import cxBinder from 'classnames/bind'
import { ItemsOnPage } from '@packhelp/platform-dsl/molecules'
import {
  Content,
  Heading,
  HeadingSize,
  Wrapper,
  WrapperSize
} from '../../layout/components'
import { useFormatMessage } from '../../../libs/localisation/LocaleIntlProvider'
import styles from './ListView.module.scss'
import { ListViewProps } from './types'
import translations from './translations'

const cx = cxBinder.bind(styles)

export const ListView = observer(
  ({ title, e2eTargetName, config, data, pagination }: ListViewProps) => {
    const CellWrapper = ({
      children,
      className
    }: PropsWithChildren<{ className?: string }>) => (
      <div className={cx('cell-wrapper', className)}>{children}</div>
    )

    const itemsOnPageLabel = useFormatMessage(translations.itemsOnPage)

    const tableConfig = {
      ...config,
      columns: config.columns?.map((column) => ({
        ...column,
        cellRenderer: (data: any, row: SingleRowData) => (
          <CellWrapper>
            {column.cellRenderer ? column.cellRenderer(data, row) : data}
          </CellWrapper>
        )
      }))
    }

    return (
      <Content e2eTargetName={e2eTargetName}>
        <Wrapper e2eTargetName={e2eTargetName} size={WrapperSize.medium}>
          <Heading size={HeadingSize.h1}>{title}</Heading>
          <Table config={tableConfig} data={data} />
          <div className={styles.pagination}>
            <ItemsOnPage
              onSelect={(perPage: number) => pagination.changePerPage(perPage)}
              quantity={pagination.perPage}
              caption={itemsOnPageLabel}
            />
            <Pagination
              page={pagination.page}
              count={pagination.pages}
              totalVisible={7}
              onPageChange={(page: number) => pagination.changePage(page)}
            />
          </div>
        </Wrapper>
      </Content>
    )
  }
)
