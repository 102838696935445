import { useEffect, useState } from 'react'
import { StringValueHookParams } from './types'

export const useStringValue = ({
  baseValue,
  onChange
}: StringValueHookParams) => {
  const [value, setValue] = useState<string>(
    baseValue ? baseValue.toString() : ''
  )

  useEffect(() => {
    onChange && onChange(value.trim() !== '' ? value : null)
  }, [value, onChange])

  return {
    value,
    setValue
  }
}
