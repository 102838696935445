import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import {
  ProductSpecification,
  ProductSpecificationConfig
} from '@packhelp/platform-pss-form'
import {
  productSpecPreview,
  ProductSpecPreview
} from '../../../hooks/productSpecPreview'

export const useSpecificationPreview = (
  productSpecConfig: ProductSpecificationConfig,
  specification?: ProductSpecification
): ProductSpecPreview[] | undefined => {
  const [preview, setPreview] = useState<ProductSpecPreview[] | undefined>(
    undefined
  )

  const debouncedPreview = useCallback(
    debounce(
      (config: ProductSpecificationConfig, spec?: ProductSpecification) => {
        if (spec) {
          setPreview(productSpecPreview(spec, config))
        }
      },
      500
    ),
    []
  )

  useEffect(() => {
    debouncedPreview(productSpecConfig, specification)
  }, [specification, productSpecConfig])

  return preview
}
