import { FormEvent } from 'react'
import { useHistory } from 'react-router'
import { DielineStore } from '../../../../../stores'
import { handleError } from '../../../../../utils/handle-error/handle-error'
import { snackbar } from '../../../../../utils/snackbar/snackbar'
import { ActionsHookReturnType } from './types'

export const useActions = (dieline?: DielineStore, template?: string): ActionsHookReturnType => {
  const history = useHistory()

  const generateDieline = async (e?: FormEvent<HTMLFormElement>) => {
    e && e.preventDefault()

    if (!dieline) return

    try {
      const generatedDieline = await dieline.generate()
      snackbar.success('New dieline generated')
      history.push(`/tools/dielines/templates/${template}/${generatedDieline.uuid}`)
    } catch (error) {
      handleError(error)
    }
  }

  return {
    generateDieline
  }
}
