import React from 'react'
import { observer } from 'mobx-react'
import { camelCase, upperFirst } from 'lodash'
import { Card, CardPadding } from '@packhelp/platform-dsl/atoms'
import { ProductTemplate } from '../../../../product-template/ProductTemplate'
import { TProductTemplate } from '../../../../product-template/components'
import { TProductDimension } from '../../../../product-template/types'
import { DielineTemplateProps } from './types'
import styles from './DielineTemplate.module.scss'

export const DielineTemplate = observer(
  ({ template, highlight }: DielineTemplateProps) => {
    const templateKey = upperFirst(camelCase(template))

    return (
      <div
        className={styles['dieline-template']}
        e2e-target="dieline-template"
        e2e-target-name={`dieline-template-${template}`}
      >
        <Card border={true} padding={CardPadding.medium}>
          <ProductTemplate
            product={templateKey as TProductTemplate}
            highlightDimension={highlight as TProductDimension}
            showDimensions={true}
          />
        </Card>
      </div>
    )
  }
)
