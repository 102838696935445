import React, { useContext } from 'react'
import { TType, Typography } from '@packhelp/platform-dsl/atoms'
import { MultiToggleSelect } from '@packhelp/platform-dsl/molecules'
import { InstantPriceContext } from '../../../../InstantPrice.context'
import styles from './Quantities.module.scss'

const sampleQuantities = [1000, 1500, 2000, 2500, 3000, 4000, 5000]

const Quantities: React.FC = () => {
  const { quantities, updateQuantities } = useContext(InstantPriceContext)

  const handleQuantitiesChange = (qty: Array<string | number>) => {
    const parsedQuantities = qty.map((quantity) =>
      typeof quantity === 'number' ? quantity : parseInt(quantity, 10)
    )

    updateQuantities(parsedQuantities)
  }

  return (
    <div>
      <Typography type={TType.Medium14_20} className={styles.label}>
        Quantities
      </Typography>

      <MultiToggleSelect<number>
        data={sampleQuantities.map((qty) => ({
          value: qty,
          label: qty.toString()
        }))}
        value={quantities}
        onChange={handleQuantitiesChange}
        customOptionLabel="Add custom quantity"
        customOption
      />
    </div>
  )
}

export default Quantities
