import React, { useContext } from 'react'
import { debounce } from 'lodash'
import { Card, CardHeight, CardPadding } from '@packhelp/platform-dsl/atoms'
import { ProductSpecification, PSSForm } from '@packhelp/platform-pss-form'
import {
  PSSFormConfigSetupWithObject,
  PSSFormConfigSetupWithUrl
} from '@packhelp/platform-pss-form/types/form-configuration'
import { useBaseUrls } from '../../hooks/baseUrls'
import { InstantPriceContext } from '../../InstantPrice.context'
import styles from './Form.module.scss'
import { Quantities } from './components/quantities'
import { Estimations } from './components/estimations'

type Props = {
  configSetup: PSSFormConfigSetupWithObject | PSSFormConfigSetupWithUrl
}

export const Form: React.FC<Props> = ({ configSetup }) => {
  const { previewsBaseUrl } = useBaseUrls()
  const { updateSpecification } = useContext(InstantPriceContext)

  const handleUpdateSpecification = debounce((spec: ProductSpecification) => {
    updateSpecification(spec)
  }, 1000)

  return (
    <div className={styles.root}>
      <Card height={CardHeight.auto} padding={CardPadding.small} border>
        <span className={styles.cardTitle}>Instant price validation</span>

        <div className={styles.content}>
          <PSSForm
            callbacks={{
              onChange: handleUpdateSpecification
            }}
            previewsSetup={{ url: previewsBaseUrl }}
            configSetup={configSetup}
          />

          <Quantities />

          <hr className={styles.divider} />

          <Estimations />
        </div>
      </Card>
    </div>
  )
}
