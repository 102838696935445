import React from 'react'
import cxBinder from 'classnames/bind'
import { Checkbox } from '@packhelp/platform-dsl/atoms'
import { FormError } from '../../views/generate-dieline-view/components/form-error/FormError'
import { Field } from '../field/Field'
import { CheckboxFieldProps } from './types'
import styles from './CheckboxField.module.scss'
const cx = cxBinder.bind(styles)

export const CheckboxField = ({
  value,
  label,
  name,
  error,
  onChange
}: CheckboxFieldProps) => {
  return (
    <Field>
      <label>
        <div className={cx('form-field__checkbox')}>
          <Checkbox
            name={name}
            error={!!error}
            checked={value}
            onChange={() => onChange && onChange(!value)}
          />
        </div>
        {label}
      </label>
      <FormError error={error} />
    </Field>
  )
}
