import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import cxBinder from 'classnames/bind'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { ProductTemplate } from '../../../../product-template/ProductTemplate'
import { TProductTemplate } from '../../../../product-template/components'
import { ProductTileProps } from './types'
import styles from './ProductTile.module.scss'

const cx = cxBinder.bind(styles)

export const ProductTile = observer(({ store }: ProductTileProps) => {
  const { path } = useRouteMatch()

  const generateUrl = useMemo(() => `${path}/${store.templateSlug}`, [
    path,
    store.templateSlug
  ])

  return (
    <NavLink to={generateUrl} className={cx('product-tile')}>
      <div className={cx('product-tile__svg-wrapper')}>
        <ProductTemplate product={store.templateKey as TProductTemplate} />
      </div>
      <div className={cx('product-tile__spacer')} />
      <div className={cx('product-tile__name')}>{store.templateLabel}</div>
      <div className={cx('product-tile__cta')}>Generate dieline</div>
    </NavLink>
  )
})
