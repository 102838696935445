import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { GenerateDielineView } from '../generate-dieline-view/GenerateDielineView'
import { ProductTemplatesView } from '../product-templates-view/ProductTemplatesView'
import { MyDielinesView } from '../my-dielines-view/MyDielinesView'

export const DielinesView = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Switch>
        <Route exact path={`${path}/my-dielines`} component={MyDielinesView} />
        <Route
          path={`${path}/templates/:template/:dielineUuid?`}
          component={GenerateDielineView}
        />
        <Route path={`${path}/templates`} component={ProductTemplatesView} />
        <Redirect exact from={path} to={`${path}/templates`} />
      </Switch>
    </>
  )
}
