import {
  StringOptionStore,
  NumberOptionStore,
  BooleanOptionStore
} from '../../stores/option-store/option-store'
import { OptionValueType } from '../../stores/option-store/types'

export class OptionStoreFactory {
  static createOptionStore = (
    option: string,
    value: OptionValueType
  ): StringOptionStore | NumberOptionStore | BooleanOptionStore => {
    if (typeof value == 'boolean') {
      return new BooleanOptionStore(option, value)
    } else if (typeof value == 'string') {
      return new StringOptionStore(option, value)
    }
    return new NumberOptionStore(option, value)
  }
}
