import { LeadTime } from '../../../../../../../../../../../../../../services'

export const parseLeadTimes = (leadTimes: LeadTime[]): string => {
  if (leadTimes.length === 0) {
    return '-'
  }

  if (leadTimes.length === 1) {
    return `${leadTimes[0].working_days} days`
  }

  const min = leadTimes.reduce((prev, curr) =>
    prev.working_days < curr.working_days ? prev : curr
  )
  const max = leadTimes.reduce((prev, curr) =>
    prev.working_days > curr.working_days ? prev : curr
  )

  if (min.working_days === max.working_days) {
    return `${min.working_days} days`
  }

  return `${min.working_days} - ${max.working_days} days`
}
