import { ReactNode } from 'react'

export enum SidebarSide {
  left = 'left',
  right = 'right'
}

export enum SidebarPadding {
  none = 'none',
  extraSmall = 'extra-small',
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extra-large'
}

export interface SidebarProps {
  children?: ReactNode
  side?: SidebarSide
  padding?: SidebarPadding
  e2eTargetName?: string
}
