import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { observer } from 'mobx-react'
import { Layout, Sidebar } from '../../layout'
import { NavigationMenu } from '../../navigation-menu/NavigationMenu'
import { DiecutsView } from '../diecuts-view/DiecutsView'
import { DielinesView } from '../dielines-view/DielinesView'
import { useRootStore } from '../../../hooks'
import { LoadingView } from '../loading-view/LoadingView'
import { toolsNavigationMenu } from './configs/menu'

export const ToolsView = observer(() => {
  const { userStore } = useRootStore()
  const { path } = useRouteMatch()

  return (
    <Layout>
      {userStore.isAuthenticated && (
        <Sidebar>
          <NavigationMenu menuConfig={toolsNavigationMenu} />
        </Sidebar>
      )}
      {userStore.isLoading ? (
        <LoadingView />
      ) : (
        <Switch>
          <Route path={`${path}/dielines`} component={DielinesView} />
          <Route path={`${path}/diecuts`} component={DiecutsView} />
          <Redirect exact from={path} to={`${path}/dielines`} />
        </Switch>
      )}
    </Layout>
  )
})
