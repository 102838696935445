import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { observer } from 'mobx-react'
import { LoginView } from '../login-view/LoginView'
import { SignUpView } from '../sign-up-view/SignUpView'
import { ResetPasswordView } from '../reset-password-view/ResetPasswordView'
import { TwoColumnLayout } from '../../layout'
import { useRootStore } from '../../../hooks'
import { AccountConfirmed } from '../sign-up-view/components/account-confirmed/AccountConfirmed'
import { NewPasswordForm } from '../reset-password-view/components/new-password-form/NewPasswordForm'

export const AuthView = observer(() => {
  const { path } = useRouteMatch()
  const { userStore } = useRootStore()
  if (userStore.isAuthenticated) return <Redirect to="/" />

  return (
    <TwoColumnLayout>
      <Switch>
        <Route path={`${path}/login`} component={LoginView} />
        <Route path={`${path}/register`} component={SignUpView} />
        <Route
          path={`${path}/confirm/:uuid/:token`}
          component={AccountConfirmed}
        />
        <Route path={`${path}/password/reset`} component={ResetPasswordView} />
        <Route path={`${path}/confirm/:code`} component={NewPasswordForm} />
        <Redirect to={`${path}/login`} />
      </Switch>
    </TwoColumnLayout>
  )
})
