import { useState } from 'react'
export const useHighlight = () => {
  const [highlight, setHighlight] = useState<string | undefined>(undefined)

  const clearHighlight = () => {
    setHighlight(undefined)
  }

  return {
    highlight,
    setHighlight,
    clearHighlight
  }
}
