import React from 'react'
import { observer } from 'mobx-react'
import { Button, ButtonSize } from '@packhelp/platform-dsl/atoms'
import styles from './Actions.module.scss'
import { ActionsProps } from './types'
import translations from './translations'
import { FormatMessage } from './../../../../../libs/localisation/LocaleIntlProvider'

export const Actions = observer(({ diecut }: ActionsProps) => {
  return (
    <>
      <div className={styles['actions']}>
        <Button
          type="submit"
          size={ButtonSize.large}
          isLoading={diecut.isGenerating}
          disabled={!diecut.isFilled}
        >
          <FormatMessage id={translations.buttonGenerate} />
        </Button>
      </div>
    </>
  )
})
