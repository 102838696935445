import React from 'react'
import { useLocation } from 'react-router'
import { EmailSent } from './components/email-sent/EmailSent'
import { EnterEmailForm } from './components/enter-email-form/EnterEmailForm'

export const ResetPasswordView = () => {
  const location = useLocation()
  const { email, isEmailSent } = (location.state || {}) as {
    email?: string
    isEmailSent?: boolean
  }

  if (isEmailSent) {
    return <EmailSent email={email} />
  }

  return <EnterEmailForm />
}
