import React, { useState } from 'react'
import { Button, ButtonSize } from '@packhelp/platform-dsl/atoms'
import { useHistory, useLocation, useParams } from 'react-router'
import { Heading, HeadingSize, MarginSize } from '../../../../layout/components'
import { Field, TextField } from '../../../../form'
import { useFormValidation } from '../../../../../hooks/form-validation'
import { ReturnLink } from '../return-link/ReturnLink'
import translations from './translations'
import { setNewPassword } from './actions'

import {
  FormatMessage,
  useFormatMessage
} from './../../../../../libs/localisation/LocaleIntlProvider'

export const NewPasswordForm = () => {
  const history = useHistory()
  const { code } = useParams<{ code: string }>()
  const email = new URLSearchParams(useLocation().search).get('email') as string
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const {
    values,
    errors,
    touched,
    createChangeHandler,
    createTouchHandler
  } = useFormValidation({
    password: null,
    confirmPassword: null
  })

  return (
    <>
      <Heading size={HeadingSize.h1} marginBottom={MarginSize.xl}>
        <FormatMessage id={translations.setPassword} />
      </Heading>
      <form
        onSubmit={setNewPassword(
          code,
          email,
          values,
          errors,
          setIsSubmitting,
          history
        )}
      >
        <Heading size={HeadingSize.h4}>
          <FormatMessage id={translations.enterPassword} />
        </Heading>
        <Field>
          <TextField
            type="password"
            label={useFormatMessage(translations.newPassword)}
            value={values.password}
            name="password"
            onChange={createChangeHandler('password')}
            onBlur={createTouchHandler('password')}
            error={touched.password && errors.password}
          />
          <TextField
            type="password"
            label={useFormatMessage(translations.confirmPassword)}
            value={values.confirmPassword}
            name="password_confirmation"
            onChange={createChangeHandler('confirmPassword')}
            onBlur={createTouchHandler('confirmPassword')}
            error={touched.confirmPassword && errors.confirmPassword}
          />
        </Field>
        <Button size={ButtonSize.large} fullWidth isLoading={isSubmitting}>
          <FormatMessage id={translations.setPassword} />
        </Button>
      </form>
      <ReturnLink />
    </>
  )
}
