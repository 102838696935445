import React from 'react'
import { Heading, HeadingSize, MarginSize } from '../../../../layout/components'
import { ReturnLink } from '../return-link/ReturnLink'
import translations from './translations'

import { FormatMessage } from './../../../../../libs/localisation/LocaleIntlProvider'

export const EmailSent = ({ email = '' }: { email?: string }) => {
  return (
    <>
      <Heading size={HeadingSize.h1} marginBottom={MarginSize.xl}>
        <FormatMessage id={translations.activation} />
      </Heading>
      <FormatMessage id={translations.checkEmail} values={{ email }} />
      <ReturnLink />
    </>
  )
}
