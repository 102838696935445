import React from 'react'
import { useConfig } from '@packhelp/platform-pss-form'
import { PSSFormConfigSetupWithUrl } from '@packhelp/platform-pss-form/types/form-configuration'
import { Card, CardHeight, CardPadding } from '@packhelp/platform-dsl/atoms'
import { Content, Heading, HeadingSize, Layout, Wrapper } from '../../layout'
import styles from './InstantPriceView.module.scss'
import { Summary } from './components/summary/Summary'
import { Form } from './components/form/Form'
import { InstantPriceContext } from './InstantPrice.context'
import { useInstantPrice } from './hooks/instantPrice'
import { useBaseUrls } from './hooks/baseUrls'
import { AvailabilityBox } from './components/availability-box'

export const InstantPriceView: React.FC = () => {
  const { context } = useInstantPrice()

  const { pssBaseUrl } = useBaseUrls()
  const configSetup: PSSFormConfigSetupWithUrl = {
    language: 'en',
    url: pssBaseUrl,
    subset: { name: process.env.REACT_APP_BOXESFLOW_SUBSET || 'boxesflow' }
  }
  const { config } = useConfig({ configSetup })

  return (
    <InstantPriceContext.Provider value={context}>
      <Layout>
        <Content>
          <Wrapper>
            <Heading size={HeadingSize.h1}>Instant price</Heading>

            <div className={styles.instant_price__content}>
              <div className={styles.instant_price__leftbox}>
                <Card
                  height={CardHeight.auto}
                  padding={CardPadding.small}
                  border
                >
                  <Summary config={config} />
                </Card>
                {context.specification && <AvailabilityBox />}
              </div>
              <Form configSetup={configSetup} />
            </div>
          </Wrapper>
        </Content>
      </Layout>
    </InstantPriceContext.Provider>
  )
}
