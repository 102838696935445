import React, { ReactNode } from 'react'
import { Logo } from '../components'
import styles from './TwoColumnLayout.module.scss'

export const TwoColumnLayout = ({
  children,
  e2eTargetName
}: {
  children?: ReactNode
  e2eTargetName?: string
}) => {
  return (
    <div
      e2e-target="two-column-layout"
      e2e-target-name={e2eTargetName}
      className={styles['two-column-layout']}
    >
      <div className={styles['first-column']}>
        <Logo className={styles['logo']} />
      </div>
      <div className={styles['second-column']}>
        <div className={styles['content']}>{children}</div>
      </div>
    </div>
  )
}
