import React, { useEffect, useState, useContext } from 'react'
import { IntlProvider, FormattedMessage } from 'react-intl'
import mapValues from 'lodash/mapValues'
import { InjectIntlContext, IntlContext } from './LocaleIntlProviderContext'
import { LocaleIntlProviderProps } from './types'
import { languageMap } from './IntlInjection'

const languageTag = 'en'
let intlMessages: any = {}

export const loadIntlMessages = new Promise((resolve, reject) => {
  const languageLoader = languageMap[languageTag]

  languageLoader()
    .then(resolve)
    .catch(() => {
      throw new Error('failed to load a language')
    })
})

export const LocaleIntlProvider = ({ children }: LocaleIntlProviderProps) => {
  const [isLocaleLoaded, setLocaleLoadingState] = useState(false)

  useEffect(() => {
    loadIntlMessages
      .then((jsonMessages) => {
        intlMessages = jsonMessages
        setLocaleLoadingState(true)
      })
      .catch((e) => {
        console.error(e)
        throw new Error('failed to load a language')
      })
  }, [])

  if (!isLocaleLoaded) return null

  // Needed to fallback to this, because console is spaming with no translation errors
  // TODO: need to find async solution
  return (
    <IntlProvider locale={languageTag} messages={intlMessages}>
      <InjectIntlContext>{children}</InjectIntlContext>
    </IntlProvider>
  )
}

export const useFormatMessage = (id: string, values?: any) => {
  const intl = useContext(IntlContext)
  return intl.formatMessage({ id }, { ...values })
}

export const useTranslations = <
  TranslationsSet extends { [key: string]: string }
>(
  translations: TranslationsSet
): TranslationsSet => mapValues(translations, useFormatMessage)

export const BareFormatMessage = ({ id }: { id: string }) => {
  return <>{useFormatMessage(id)}</>
}

export const FormatMessage = ({ id, values }: { id: string; values?: any }) => {
  return <FormattedMessage id={id} values={values} />
}
