import { useEffect, useState } from 'react'

export const useRotatedImage = (src: string) => {
  const degrees = 90
  const image = new Image()
  const [transformedSrc, setTransformedSrc] = useState<string>(src)

  image.setAttribute('crossorigin', 'anonymous')

  image.onload = function() {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (ctx) {
      canvas.width = image.height
      canvas.height = image.width

      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate((degrees * Math.PI) / 180)
      ctx.drawImage(image, image.width / -2, image.height / -2)
      setTransformedSrc(canvas.toDataURL())
    }
  }

  useEffect(() => {
    image.src = src
  }, [src])

  return transformedSrc
}
