export type UserCredentials = {
  email: string
  password: string
}

export interface UserFullName {
  firstname: string
  lastname: string
}

export interface UserInfo extends UserFullName, UserAvatar {
  uuid: string
  phone_number: string
  company_role: string
  active: boolean
  email: string
}

export interface UserAvatar {
  avatar_image: AvatarImage
}

export interface AvatarImage {
  title: string
  variants: AvatarImageVariant[]
}

export type AvatarImageVariant = {
  path: string
  size: string
  url: string
}

export type LoginResult = {
  token: string
}

export type VmaFile = {
  uuid: string
  name: string
  url: string
  path: string
  mime: string
  size: number
  disk: string
  origin: string
}
export enum ProductType {
  dieline = 'dieline',
  diecut = 'diecut'
}

export type CreateProductConfigRequest = {
  name: string
  owner: string
  type: ProductType
  specification: { name: string; value: unknown }[]
  attachments: VmaFile[]
}

export type CreateProductConfigResponse = {
  uuid: string
}

export type PaginationMeta = {
  per_page: number
  page: number
  total: number
}

export interface ListResponse {
  data: unknown[]
  meta: PaginationMeta
}

export interface GetProductResponse {
  data: ProductConfig
}

export interface GetProductsResponse extends ListResponse {
  data: ProductConfig[]
}

export interface ProductOwner extends UserFullName {
  uuid: string
  avatarImage: AvatarImage
  email: string
}

export interface ProductConfig {
  created_at: string
  file: VmaFile | null
  id: string
  name: string
  owner: ProductOwner
  specification: { name: string; value: unknown }[]
  type: ProductType
  updated_at: string
  attachments: VmaFile[]
}

export interface UserRegisterRequest extends UserCredentials, UserFullName {}

export class AuthenticationError extends Error {
  constructor() {
    super('Authentication error')
    Object.setPrototypeOf(this, AuthenticationError.prototype)
  }
}

export class UserRegistrationError extends Error {
  constructor() {
    super('Registration failed')
    Object.setPrototypeOf(this, UserRegistrationError.prototype)
  }
}

export class UserConfirmationError extends Error {
  constructor() {
    super('Confirmation failed')
    Object.setPrototypeOf(this, UserConfirmationError.prototype)
  }
}
