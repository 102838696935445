import React from 'react'
import cxBinder from 'classnames/bind'
import { observer } from 'mobx-react'
import {
  ButtonMode,
  ButtonSize,
  LinkAsButton,
  NavLinkAsButton
} from '@packhelp/platform-dsl/atoms'
import { SegmentAnalytics } from '../../../../../libs/segment-analytics'
import { useRootStore } from '../../../../../hooks'
import styles from './Actions.module.scss'
import { ActionsProps } from './types'

const cx = cxBinder.bind(styles)

export const Actions = observer(({ dieline }: ActionsProps) => {
  const { userStore } = useRootStore()

  return (
    <div className={cx('actions')}>
      {dieline.isPackingAvailable && (
        <NavLinkAsButton
          size={ButtonSize.large}
          mode={ButtonMode.secondary}
          to={`/tools/diecuts/generate/${dieline.templateSlug}/${dieline.uuid}`}
        >
          Generate diecut layout
        </NavLinkAsButton>
      )}
      <LinkAsButton
        onClick={() => SegmentAnalytics.dielineDownloaded(dieline, userStore)}
        size={ButtonSize.large}
        href={dieline.pdfObjectUrl}
        download={`${dieline.slug}.pdf`}
        disabled={!dieline.pdf}
        target="_blank"
      >
        Download file
      </LinkAsButton>
    </div>
  )
})
