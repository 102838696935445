import React from 'react'
import classNames from 'classnames'
import { Icon, TIcon, TType, Typography } from '@packhelp/platform-dsl/atoms'
import { Estimation } from '../../../../../../../../../../../../../services'
import styles from './ItemHeader.module.scss'
import { parseLeadTimes } from './helprs/leadTimeHelper'

type Props = {
  estimation: Estimation
  collapsed: boolean
  onToggle: () => void
}

const ItemHeader: React.FC<Props> = ({
  estimation,
  collapsed,
  onToggle: handleToggle
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.header__item}>
        <Typography
          type={TType.Regular12_16}
          className={styles['header__item--label']}
        >
          Supplier
        </Typography>
        <Typography type={TType.Medium14_20}>
          {estimation.supplier.name}
        </Typography>
      </div>

      <div className={styles.header__item}>
        <Typography
          type={TType.Regular12_16}
          className={styles['header__item--label']}
        >
          Total cost
        </Typography>
        <Typography type={TType.Medium14_20}>
          {estimation.total_cost.amount} {estimation.total_cost.currency}
        </Typography>
      </div>

      <div className={styles.header__item}>
        <Typography
          type={TType.Regular12_16}
          className={styles['header__item--label']}
        >
          Per unit
        </Typography>
        <Typography type={TType.Medium14_20}>
          {estimation.unit_cost.amount} {estimation.unit_cost.currency}
        </Typography>
      </div>

      <div className={styles.header__item}>
        <Typography
          type={TType.Regular12_16}
          className={styles['header__item--label']}
        >
          Lead time
        </Typography>
        <Typography type={TType.Medium14_20}>
          {parseLeadTimes(estimation.lead_times)}
        </Typography>
      </div>

      <div
        className={classNames(
          styles.header__item,
          styles['header__item--action']
        )}
      >
        <Typography
          type={TType.Regular14_16}
          className={styles['header__item--link']}
          onClick={handleToggle}
        >
          {collapsed ? 'Hide details' : 'Show details'}
          <Icon
            icon={collapsed ? TIcon.ArrowsType1Up : TIcon.ArrowsType1Down}
          />
        </Typography>
      </div>
    </div>
  )
}

export default ItemHeader
