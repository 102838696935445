import React from 'react'
import { ParsedGroup } from '@packhelp/platform-pss-form'
import styles from '../../Summary.module.scss'
import PreviewValue from './PreviewValue'

type Props = {
  group: ParsedGroup
}

const PreviewItem: React.FC<Props> = ({ group }) => {
  return (
    <>
      <div className={styles.preview__group_header}>{group.label}</div>
      {group.attributes.map((attribute) => (
        <div
          className={styles.preview__group_value}
          key={`${attribute.group}__${attribute.name}`}
        >
          <span className={styles.preview__attribute_label}>
            {attribute.label}
          </span>
          <PreviewValue attribute={attribute} />
        </div>
      ))}
    </>
  )
}

export default PreviewItem
