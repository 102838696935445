import React from 'react'
import { LinkElement } from '@packhelp/platform-dsl/atoms'
import styles from './ReturnLink.module.scss'
import translations from './translations'

import { FormatMessage } from './../../../../../libs/localisation/LocaleIntlProvider'

export const ReturnLink = () => {
  return (
    <div className={styles['return-link']}>
      <LinkElement to="/auth/login">
        <FormatMessage id={translations.button} />
      </LinkElement>
    </div>
  )
}
