import React from 'react'
import { NavLink, Route } from 'react-router-dom'
import cxBinder from 'classnames/bind'
import { NavigationMenu } from '../../NavigationMenu'
import { useFormatMessage } from '../../../../libs/localisation/LocaleIntlProvider'
import { NavigationMenuLinkProps } from './types'
import styles from './NavigationMenuLink.module.scss'

const cx = cxBinder.bind(styles)

export const NavigationMenuLink = ({
  label,
  url,
  submenu,
  e2eTargetName
}: NavigationMenuLinkProps) => {
  return (
    <>
      <NavLink
        exact={!!submenu}
        to={url}
        e2e-target="navigation-menu-link"
        e2e-target-name={e2eTargetName}
        className={cx('navigation-menu-link')}
        activeClassName={cx('navigation-menu-link--active')}
      >
        {useFormatMessage(label)}
      </NavLink>
      {!!submenu && (
        <Route
          path={url}
          render={() => {
            return <NavigationMenu menuConfig={submenu} />
          }}
        />
      )}
    </>
  )
}
