import React from 'react'
import { useRotatedImage } from '../../hooks/rotated-image/rotated-image'
import { RotatableImageProps } from '../../types'

export const RotatedImage = ({
  src,
  alt = '',
  ...other
}: RotatableImageProps) => {
  const rotatedSrc = useRotatedImage(src)

  return <img src={rotatedSrc} alt={alt} {...other} />
}
