import React, { useCallback, useState } from 'react'
import { ChangePasswordModal } from '../ChangePasswordModal'

export const useChangePasswordModal = (isInitiallyOpen = false) => {
  const [isOpen, setOpen] = useState(isInitiallyOpen)
  const toggleOpen = useCallback(() => setOpen(!isOpen), [setOpen, isOpen])
  const Modal = () => (
    <ChangePasswordModal isOpen={isOpen} toggleOpen={toggleOpen} />
  )

  return {
    toggleChangePasswordModalOpen: toggleOpen,
    ChangePasswordModal: Modal
  }
}
