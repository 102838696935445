import React from 'react'
import { Select, SelectSize } from '@packhelp/platform-dsl/molecules'
import { PopupSizeProp } from '@packhelp/platform-dsl/atoms/Popup'
import { FormError } from '../../views/generate-dieline-view/components/form-error/FormError'
import { useNumberValue } from '../number-field/hooks/number-value/number-value'
import { Field } from '../field/Field'
import { SelectItem } from '../../form/select-item/SelectItem'
import { SelectFieldProps } from './types'

export const SelectField = ({
  name,
  label,
  value: passedValue,
  error,
  onChange,
  selectOptions,
  disabled
}: SelectFieldProps) => {
  const { value, setValue } = useNumberValue({
    baseValue: passedValue,
    name,
    onChange
  })

  return (
    <Field>
      <label htmlFor={name}>{label}</label>

      <Select
        name={name}
        disabled={disabled}
        value={value}
        size={SelectSize.large}
        popupSize={PopupSizeProp.auto}
        data={selectOptions}
        onSelect={setValue}
        listItemRenderer={(label) => <SelectItem label={label} />}
      />
      <FormError error={error} />
    </Field>
  )
}
