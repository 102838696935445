import React from 'react'
import { Button, ButtonMode, ButtonSize } from '@packhelp/platform-dsl/atoms'
import { Modal, ModalSize } from '@packhelp/platform-dsl/molecules'
import { TextField } from '../../../../form'
import { useFormValidation } from '../../../../../hooks/form-validation'
import { useTranslations } from '../../../../../libs/localisation/LocaleIntlProvider'
import styles from './ChangePasswordModal.module.scss'
import { ChangePasswordFields, ChangePasswordModalProps } from './types'
import { useActions } from './hooks/actions/actions'
import translations from './translations'

export const ChangePasswordModal = ({
  isOpen,
  toggleOpen
}: ChangePasswordModalProps) => {
  const {
    values,
    errors,
    createTouchHandler,
    createChangeHandler
  } = useFormValidation({
    oldPassword: null,
    password: null,
    confirmPassword: null
  })
  const { changePassword, isChangingPassword } = useActions()
  const messages = useTranslations(translations)

  const fields = Object.values(ChangePasswordFields).map((field) => (
    <TextField
      key={field}
      type="password"
      label={messages[field]}
      value={values[field]}
      error={errors[field]}
      onBlur={createTouchHandler(field)}
      onChange={createChangeHandler(field)}
      name={field}
    />
  ))
  return (
    <form onSubmit={changePassword(values, errors, toggleOpen)}>
      <Modal
        header={messages.changePassword}
        width={ModalSize.xs}
        height={ModalSize.auto}
        open={isOpen}
        onClose={toggleOpen}
        rightFooter={
          <div className={styles.actions}>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                toggleOpen()
              }}
              size={ButtonSize.large}
              mode={ButtonMode.secondary}
            >
              {messages.cancel}
            </Button>
            <Button
              size={ButtonSize.large}
              mode={ButtonMode.primary}
              isLoading={isChangingPassword}
            >
              {messages.changePassword}
            </Button>
          </div>
        }
      >
        {fields}
      </Modal>
    </form>
  )
}
