import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Button, ButtonSize } from '@packhelp/platform-dsl/atoms'
import { Heading, HeadingSize, MarginSize } from '../../../../layout/components'
import { Field, TextField } from '../../../../form'
import { useFormValidation } from '../../../../../hooks/form-validation'
import { ReturnLink } from '../return-link/ReturnLink'
import translations from './translations'
import { resetPassword } from './actions'

import {
  FormatMessage,
  useFormatMessage
} from './../../../../../libs/localisation/LocaleIntlProvider'

export const EnterEmailForm = () => {
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const {
    values,
    errors,
    touched,
    createChangeHandler,
    createTouchHandler
  } = useFormValidation({
    email: null
  })

  return (
    <>
      <Heading size={HeadingSize.h1} marginBottom={MarginSize.xl}>
        <FormatMessage id={translations.forgotPassword} />
      </Heading>
      <form onSubmit={resetPassword(values, errors, setIsSubmitting, history)}>
        <Heading size={HeadingSize.h4}>
          <FormatMessage id={translations.sentLink} />
        </Heading>
        <Field>
          <TextField
            label={useFormatMessage(translations.email)}
            value={values.email}
            name="email"
            onChange={createChangeHandler('email')}
            onBlur={createTouchHandler('email')}
            error={touched.email && errors.email}
          />
        </Field>
        <Button size={ButtonSize.large} fullWidth isLoading={isSubmitting}>
          <FormatMessage id={translations.resetPassword} />
        </Button>
      </form>
      <ReturnLink />
    </>
  )
}
