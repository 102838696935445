import React from 'react'
import { TType, Typography } from '@packhelp/platform-dsl/atoms'
import styles from './NoShippingCosts.module.scss'

type Props = {
  totalWeight?: string
  weightUnit?: string
}

export const NoShippingCosts: React.FC<Props> = ({
  totalWeight,
  weightUnit
}) => {
  return (
    <div>
      {totalWeight && weightUnit && (
        <ul className={styles.table}>
          <li>
            <Typography type={TType.Regular12_16}>Total weight</Typography>
            <Typography type={TType.Regular12_16}>
              {totalWeight} {weightUnit}
            </Typography>
          </li>
        </ul>
      )}

      <Typography type={TType.Regular12_16} className={styles.note}>
        There are no shipping costs available
      </Typography>
    </div>
  )
}

export default NoShippingCosts
