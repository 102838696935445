import React from 'react'
import cxBinder from 'classnames/bind'
import styles from './Field.module.scss'
import { FieldProps } from './types'
const cx = cxBinder.bind(styles)

export const Field = ({ children }: FieldProps) => {
  return (
    <div className={cx('form-field')} e2e-target="form-field">
      {children}
    </div>
  )
}
