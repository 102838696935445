import { ReactNode } from 'react'

export enum HeadingSize {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4'
}

export enum MarginSize {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl'
}

export interface HeadingProps {
  size?: HeadingSize
  marginBottom?: MarginSize
  children: ReactNode
}
