import React from 'react'
import {
  Button,
  ButtonMode,
  ButtonSize,
  Card,
  CardHeight,
  CardPadding
} from '@packhelp/platform-dsl/atoms'
import { mapValues } from 'lodash'
import { Heading, HeadingSize, MarginSize } from '../../../../layout/components'
import { useFormatMessage } from '../../../../../libs/localisation/LocaleIntlProvider'
import { Field, TextField } from '../../../../form'
import { useChangePasswordModal } from '../change-password-modal/hooks/change-password-modal'
import { useFormValidation } from '../../../../../hooks/form-validation'
import { useRootStore } from '../../../../../hooks'
import { PhoneField } from '../../../../form/phone-field/PhoneField'
import translations from './translations'
import styles from './SettingsForm.module.scss'
import { useActions } from './hooks/actions/actions'
import { SettingsFields } from './types'

export const SettingsForm = () => {
  const { userStore } = useRootStore()
  const { saveChanges, isSavingChanges } = useActions()
  const messages = mapValues(translations, useFormatMessage)

  const {
    toggleChangePasswordModalOpen,
    ChangePasswordModal
  } = useChangePasswordModal()

  const {
    values,
    errors,
    createChangeHandler,
    createTouchHandler
  } = useFormValidation({
    firstName: userStore.firstname || null,
    lastName: userStore.lastname || null,
    email: userStore.email || null,
    phoneNumber: userStore.phoneNumber || null,
    companyRole: userStore.companyRole || null
  })

  const fields = Object.values(SettingsFields).map((field) => {
    const FieldComponent = field === 'phoneNumber' ? PhoneField : TextField
    return (
      <FieldComponent
        key={field}
        label={messages[field]}
        value={values[field]}
        error={errors[field]}
        onBlur={createTouchHandler(field)}
        onChange={createChangeHandler(field)}
        name={field}
        disabled={field === 'email'}
        required={false}
      />
    )
  })

  return (
    <div>
      <form
        onSubmit={saveChanges(values, errors)}
        className={styles['settings-form']}
      >
        <div className={styles['header']}>
          <Heading size={HeadingSize.h1} marginBottom={MarginSize.xxs}>
            {messages.settings}
          </Heading>
          <Button
            size={ButtonSize.large}
            mode={ButtonMode.primary}
            isLoading={isSavingChanges}
          >
            {messages.saveChanges}
          </Button>
        </div>
        <Card
          border={true}
          padding={CardPadding.medium}
          height={CardHeight.auto}
        >
          <div className={styles.form}>
            {fields}
            <div />
            <Field>
              <label>Password</label>
              <Button
                size={ButtonSize.large}
                mode={ButtonMode.secondary}
                fullWidth
                onClick={(e) => {
                  e.preventDefault()
                  toggleChangePasswordModalOpen()
                }}
              >
                {messages.changePassword}
              </Button>
            </Field>
          </div>
        </Card>
      </form>
      <ChangePasswordModal />
    </div>
  )
}
