import React, { useMemo } from 'react'
import { TType, Typography } from '@packhelp/platform-dsl/atoms'
import { Estimation } from '../../../../../../../../../../../../../../../services'
import styles from './ExtraCosts.module.scss'

type Props = {
  estimation: Estimation
}

type Cost = { label: string; value: string }

const ExtraCosts: React.FC<Props> = ({ estimation }) => {
  const toolsCosts = useMemo(() => {
    const costs: Cost[] = []

    if (estimation.technology === null) {
      return costs
    }

    estimation.technology.operations.forEach((operation) => {
      operation.tools.forEach((tool) => {
        costs.push({
          label: tool.type,
          value: `${tool.cost.amount} ${tool.cost.currency}`
        })
      })
    })

    estimation.technology.tools.forEach((tool) => {
      costs.push({
        label: tool.type,
        value: `${tool.cost.amount} ${tool.cost.currency}`
      })
    })

    return []
  }, [estimation])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Typography type={TType.Medium12_18}>Production</Typography>
      </div>

      <div className={styles.content}>
        <ul className={styles.content__table}>
          <li>
            <Typography type={TType.Regular12_16}>Cost</Typography>
            <Typography type={TType.Regular12_16}>
              {estimation.production_cost
                ? `${estimation.production_cost.amount} ${estimation.production_cost.currency}`
                : 'n/a'}
            </Typography>
          </li>
        </ul>
      </div>

      {toolsCosts.length > 0 && (
        <>
          <div className={styles.header}>
            <Typography type={TType.Medium12_18}>Tools</Typography>
          </div>

          <div className={styles.content}>
            <ul className={styles.content__table}>
              {toolsCosts.map((cost) => (
                <li key={`${cost.label}__${cost.value}`}>
                  <Typography type={TType.Regular12_16}>
                    {cost.label}
                  </Typography>
                  <Typography type={TType.Regular12_16}>
                    {cost.value}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

export default ExtraCosts
