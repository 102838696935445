import React, { ReactNode } from 'react'
import cxBinder from 'classnames/bind'
import styles from './Body.module.scss'

const cx = cxBinder.bind(styles)

export const Body = ({
  children,
  e2eTargetName
}: {
  children?: ReactNode
  e2eTargetName?: string
}) => {
  return (
    <div
      e2e-target="layout-body"
      e2e-target-name={e2eTargetName}
      className={cx('body')}
    >
      {children}
    </div>
  )
}
