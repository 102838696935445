import React from 'react'
import { Avatar, Icon, TIcon } from '@packhelp/platform-dsl/atoms'
import { MenuPopup } from '@packhelp/platform-dsl/components/MenuPopup/MenuPopup'
import { observer } from 'mobx-react'
import { useRootStore } from '../../../../../../hooks'
import styles from './UserAccount.module.scss'

export const UserAccount = observer(() => {
  const {
    userStore: { firstname, lastname, email, logout, avatar, isAuthenticated }
  } = useRootStore()
  const [anchorEl, setAnchorEl] = React.useState<EventTarget | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const onClickOutside = (): void => {
    setAnchorEl(null)
  }

  const header = React.useMemo(
    () => (
      <div>
        <div className={styles['user-name']}>
          {firstname} {lastname}
        </div>
        <div className={styles['user-email']}>{email}</div>
      </div>
    ),
    [firstname, lastname, email]
  )

  const menu = React.useMemo(
    () => [
      [
        {
          label: 'Settings',
          id: 'settings',
          link: '/settings',
          icon: <Icon icon={TIcon.Settings} />
        }
        // {
        //   label: 'Company profile',
        //   id: 'company',
        //   icon: <Icon icon={TIcon.Users} />
        // }
      ],
      [
        {
          label: 'Log out',
          id: 'logout',
          icon: <Icon icon={TIcon.LogOut} />,
          onClick: logout
        }
      ]
    ],
    [isAuthenticated]
  )

  return (
    <div onClick={handleClick}>
      <Avatar
        clickable
        active={!!anchorEl}
        src={avatar?.variants.find((variant) => variant.size === 'sm')?.url}
      />
      <MenuPopup
        anchorEl={anchorEl as HTMLElement}
        onClickOutside={onClickOutside}
        header={header}
        menu={menu}
      />
    </div>
  )
})
