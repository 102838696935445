import { Loading, LoadingSize } from '@packhelp/platform-dsl/atoms'
import React from 'react'
import cxBinder from 'classnames/bind'
import styles from './LoadingContent.module.scss'

const cx = cxBinder.bind(styles)

export const LoadingContent = () => {
  return (
    <div className={cx('loading-content')}>
      <Loading size={LoadingSize.l} />
    </div>
  )
}
