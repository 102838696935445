import React from 'react'
import { observer } from 'mobx-react'
import { MenuItem } from '../menu-item/MenuItem'
import { useRootStore } from '../../../../../../hooks'

export const TopLeftMenu = observer(() => {
  const { userStore } = useRootStore()

  const AuthenticatedMenu = React.memo(() => (
    <MenuItem url="/tools">Tools</MenuItem>
  ))

  const UnauthenticatedMenu = React.memo(() => (
    <>
      <MenuItem url="/tools/dielines/templates">Dielines</MenuItem>
      <MenuItem url="/tools/diecuts/generate/">Diecut layouts</MenuItem>
    </>
  ))

  return userStore.isAuthenticated ? (
    <AuthenticatedMenu />
  ) : !userStore.isLoading ? (
    <UnauthenticatedMenu />
  ) : null
})
