import React, { useState } from 'react'
import { TType, Typography, ToggleSelect } from '@packhelp/platform-dsl/atoms'
import { Estimation } from '../../../../../../../../../services'
import styles from './QuantityPrices.module.scss'
import { PriceItem } from './components/priceItem'
import { useSuppliers } from './hooks/suppliers'

type Props = {
  quantity: number
  estimations: Estimation[]
}

const QuantityPrices: React.FC<Props> = ({ quantity, estimations }) => {
  const [collapsed, setCollapsed] = useState(false)
  const { suppliers } = useSuppliers(estimations)
  const [supplierFilter, setSupplierFilter] = useState('All')

  const collapseFilter = (index: number) => {
    if (collapsed) {
      return true
    }

    return index === 0
  }

  return (
    <div className={styles.quantityContainer}>
      <div className={styles.header}>
        <Typography type={TType.Regular14_16} className={styles.header__label}>
          Quantity:
        </Typography>
        <Typography type={TType.Medium16_24}>{quantity}</Typography>
      </div>

      <div className={styles.suppliers}>
        <ToggleSelect
          data={suppliers.map(({ name, estimations: count }) => ({
            label: `${name} (${count})`,
            value: name
          }))}
          value={supplierFilter}
          onChange={(value) => setSupplierFilter(value)}
        />
      </div>

      <div className={styles.prices}>
        {estimations
          .filter(
            (estimation) =>
              supplierFilter === 'All' ||
              supplierFilter === estimation.supplier.name
          )
          .filter((_, index) => collapseFilter(index))
          .map((estimation) => (
            <PriceItem estimation={estimation} key={estimation.id} />
          ))}

        <Typography
          type={TType.Regular14_16}
          className={styles.prices__toggleLink}
          onClick={() => setCollapsed((state) => !state)}
        >
          {collapsed ? 'Hide additional prices' : 'Show all additional prices'}
        </Typography>
      </div>
    </div>
  )
}

export default QuantityPrices
