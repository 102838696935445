import { useCallback, useEffect, useState } from 'react'
import { useRootStore } from '../../../../../hooks'
import { DiecutStore } from '../../../../../stores/diecut-store/diecut-store'
import { handleError } from '../../../../../utils/handle-error/handle-error'

export const useData = (uuid: string) => {
  const [diecut, setDiecut] = useState<DiecutStore>()
  const [loading, setLoading] = useState(true)
  const { diecutsStore } = useRootStore()

  const getDiecut = useCallback(async () => {
    try {
      const diecut = await diecutsStore.getDiecut(uuid)
      setDiecut(diecut)
      setLoading(false)
    } catch (error) {
      handleError(error)
    }
  }, [uuid])

  useEffect(() => {
    getDiecut()
  }, [uuid])
  return { diecut, loading }
}
