import { Estimation } from '../../../../../../../../services'

type SortedEstimations = Array<{ quantity: number; estimations: Estimation[] }>

export const useQuantityPrices = () => {
  return {
    sort(estimations: Estimation[]): SortedEstimations {
      const sortedQuantities: SortedEstimations = []

      estimations.forEach((estimation) => {
        const index = sortedQuantities.findIndex(
          (sortedQuantity) => sortedQuantity.quantity === estimation.quantity
        )

        if (index !== -1) {
          sortedQuantities[index].estimations = [
            ...sortedQuantities[index].estimations,
            estimation
          ].sort(
            (a, b) =>
              parseFloat(a.total_cost.amount) - parseFloat(b.total_cost.amount)
          )
        } else {
          sortedQuantities.push({
            quantity: estimation.quantity,
            estimations: [estimation]
          })
        }
      })

      return sortedQuantities.sort((a, b) => a.quantity - b.quantity)
    }
  }
}
