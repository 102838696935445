import React from 'react'
import { observer } from 'mobx-react'
import styles from './FormError.module.scss'
import { FormErrorProps } from './types'

export const FormError = observer(({ error }: FormErrorProps) => {
  if (!error) return null

  return (
    <div className={styles['form-error']} e2e-target="form-error">
      {error}
    </div>
  )
})
