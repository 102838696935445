import React from 'react'
import { PackingPallets } from '../../../../../../../../../../../../../../../../../services'
import { usePallets } from './hooks/pallets'
import { PalletsService } from './components/palletsService'
import { PalletsCost } from './components/palletsCost'

type Props = {
  pallets: PackingPallets[]
}

const Pallets: React.FC<Props> = ({ pallets }) => {
  const {
    pallets: {
      services,
      palletsByService,
      palletsWithNoService,
      transitTimes,
      serviceDeliveries
    }
  } = usePallets(pallets)

  return (
    <div>
      {services.map((service) => (
        <PalletsService
          name={service}
          pallets={palletsByService[service]}
          transitTimes={transitTimes[service]}
          deliveries={serviceDeliveries[service]}
          key={service}
        />
      ))}

      {palletsWithNoService.map((palletsCost, index) => (
        <PalletsCost {...palletsCost} key={index} />
      ))}
    </div>
  )
}

export default Pallets
