import React from 'react'
import cxBinder from 'classnames/bind'
import { observer } from 'mobx-react'
import { Card, CardHeight, CardPadding } from '@packhelp/platform-dsl/atoms'
import { NavLink } from 'react-router-dom'
import { Param } from '../../../form'
import { ProductTemplate } from '../../../product-template/ProductTemplate'
import { TProductTemplate } from '../../../product-template/components'
import styles from './GeneratorPlaceholder.module.scss'
import { GeneratorPlaceholderProps } from './types'
import translations from './translations'
import {
  FormatMessage,
  useFormatMessage
} from './../../../../libs/localisation/LocaleIntlProvider'

const cx = cxBinder.bind(styles)

export const GeneratorPlaceholder = observer(
  ({ titleI18n }: GeneratorPlaceholderProps) => {
    return (
      <div className={cx('diecut-placeholder')}>
        <div className={cx('diecut-placeholder__info-wrapper')}>
          <div className={cx('diecut-placeholder__info')}>
            <FormatMessage id={titleI18n} />
          </div>
        </div>
        <div className={cx('diecut-tile')}>
          <Card
            border={true}
            padding={CardPadding.medium}
            height={CardHeight.auto}
          >
            <div className={cx('diecut-tile__content')}>
              <div className={cx('diecut-tile__thumbnail')}>
                <ProductTemplate product={TProductTemplate.StraightTuckEnd} />
              </div>
              <div className={cx('diecut-tile__diecut-layout')}>
                <Param label={useFormatMessage(translations.format)}>
                  1000 x 1000 Format
                </Param>
                <Param label={useFormatMessage(translations.unitNumber)}>
                  1
                </Param>
              </div>
              <div className={cx('diecut-tile__dieline-params')}>
                <Param label={useFormatMessage(translations.dieline)}>
                  <NavLink to={`#`}>
                    {useFormatMessage(translations.placeholderUrl)}
                  </NavLink>
                </Param>
                <Param label={useFormatMessage(translations.dimensions)}>
                  Width: 100, Depth: 100, Height: 100
                </Param>
              </div>
            </div>
          </Card>
        </div>
      </div>
    )
  }
)
