import { DielineConfig } from '../../boxesflow-api/types'

export const productTypesData: DielineConfig[] = [
  {
    options: {
      depth: null,
      height: null,
      width: null,
      paper_thickness: 3.0
    },
    product_type: 'fefco 427',
    psvg_file: 'fefco-427.psvg',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      tuck_flap_size: 15,
      glue_flap_size: 10,
      paper_thickness: 0.5
    },
    options_custom_order: [
      'width',
      'depth',
      'height',
      'tuck_flap_size',
      'glue_flap_size',
      'paper_thickness'
    ],
    product_type: 'reverse tuck end',
    psvg_file: 'reverse-tuck-end.psvg',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      tuck_flap_size: 15,
      glue_flap_size: 10,
      paper_thickness: 0.5
    },
    options_custom_order: [
      'width',
      'depth',
      'height',
      'tuck_flap_size',
      'glue_flap_size',
      'paper_thickness'
    ],
    product_type: 'tuck top snap lock bottom',
    psvg_file: 'top-tuck-bottom-snap-v2.psvg',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 15,
      paper_thickness: 0.5
    },
    options_custom_order: [
      'width',
      'depth',
      'height',
      'glue_flap_size',
      'paper_thickness'
    ],
    product_type: 'sleeve',
    psvg_file: 'sleeve.psvg',
    is_packing_available: true
  },
  {
    options: {
      depth: null,
      height: null,
      width: null,
      paper_thickness: 1.0
    },
    product_type: 'rigid box',
    psvg_file: 'rigid-box-wrapper-and-cardboard-v2.psvg',
    is_packing_available: true
  },
  {
    options: {
      depth: null,
      height: null,
      width: null,
      paper_thickness: 0.5
    },
    product_type: 'double sidewall double bottom',
    psvg_file: 'double-sidewall-double-bottom.psvg',
    is_packing_available: true
  },
  {
    options: {
      depth: null,
      height: null,
      width: null,
      paper_thickness: 0.5
    },
    product_type: 'double sidewall',
    psvg_file: 'double-sidewall.psvg',
    is_packing_available: true
  },
  {
    options: {
      glue_flap_size: 20,
      height: null,
      width: null,
      depth: null
    },
    product_type: 'envelope',
    psvg_file: 'envelope.psvg',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      auto_lock_size: 1,
      dust_flap_ratio: 0.8,
      glue_flap_size: 10,
      tuck_flap_size: 12
    },
    options_custom_order: [
      'width',
      'depth',
      'height',
      'auto_lock_size',
      'dust_flap_ratio',
      'glue_flap_size',
      'tuck_flap_size'
    ],
    product_type: 'auto bottom',
    psvg_file: 'top-tuck-auto-bottom.psvg',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null
    },
    product_type: 'cake box',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null
    },
    product_type: 'cake box auto bottom',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 25,
      paper_thickness: 1.5
    },
    product_type: 'fefco 701',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 25,
      paper_thickness: 1.5
    },
    product_type: 'fefco 703',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 25,
      paper_thickness: 1.5
    },
    product_type: 'fefco 711',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 25,
      paper_thickness: 1.5
    },
    product_type: 'fefco 712',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 25,
      paper_thickness: 1.5
    },
    product_type: 'fefco 713',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 17,
      paper_thickness: 1
    },
    product_type: 'yope',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      lid_height: null,
      paper_thickness: 1
    },
    product_type: 'two pieces tray box',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 20,
      paper_thickness: 1.5
    },
    product_type: 'fefco 201',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 20,
      paper_thickness: 1.5
    },
    product_type: 'fefco 217',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      paper_thickness: 1.5
    },
    product_type: 'fefco 426',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
    },
    product_type: 'doypack',
    is_packing_available: false
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      glue_flap_size: 20
    },
    product_type: 'pillow box',
    is_packing_available: true
  },
  {
    options: {
      width: null,
      depth: null,
      height: null,
      paper_thickness: 1.5
    },
    product_type: 'food box flexo',
    is_packing_available: false
  }
]
