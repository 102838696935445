import React from 'react'
import { Redirect } from 'react-router'
import cxBinder from 'classnames/bind'
import { observer } from 'mobx-react'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { Content, Wrapper } from '../../layout'
import { Heading } from '../../layout/components/heading/Heading'
import { HeadingSize } from '../../layout/components/heading/types'
import { GenerateProductForm } from '../forms'
import styles from './GenerateDiecutView.module.scss'
import { Form } from './components/form/Form'
import { useActions } from './hooks/actions/actions'
import { DiecutsList } from './components/diecuts-list/DiecutsList'
import { useData } from './hooks/data/data'

import { FormatMessage } from './../../../libs/localisation/LocaleIntlProvider'
import translations from './translations'

const cx = cxBinder.bind(styles)

export const GenerateDiecutView = observer(() => {
  const { redirect, diecut, diecutsStore, isLoading, template } = useData()

  const { generateDiecut } = useActions(diecut)

  if (redirect) {
    return <Redirect to="/404" />
  }

  if (isLoading) {
    return <LoadingContent />
  }

  return (
    <form
      onSubmit={generateDiecut}
      className={cx('generate-diecut')}
      e2e-target="generate-diecut"
    >
      <Content>
        <Wrapper>
          <Heading size={HeadingSize.h1}>
            <FormatMessage id={translations.header} />
          </Heading>

          <div className={cx('generate-diecut__content')}>
            <GenerateProductForm
              template={template}
              route="/tools/diecuts/generate"
              diecut={diecut}
              forPacking={true}
            >
              {diecut && <Form diecut={diecut} />}
            </GenerateProductForm>
            <DiecutsList diecutsStore={diecutsStore} />
          </div>
        </Wrapper>
      </Content>
    </form>
  )
})
