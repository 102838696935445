import { History } from 'history'
import React from 'react'
import { ErrorsState, ValuesState } from '../../../../../hooks/form-validation'
import { VmaApi } from '../../../../../services/vma-api'
import { handleError } from '../../../../../utils/handle-error/handle-error'
import { SegmentAnalytics } from '../../../../../libs/segment-analytics'

export const signUp = async (
  values: ValuesState<'email' | 'password' | 'agreement'>,
  setIsSubmitting: (isSubmitting: boolean) => void,
  history: History
): Promise<void> => {
  try {
    const vmaApi = new VmaApi()
    await vmaApi.register({
      email: values.email as string,
      password: values.password as string,
      firstname: 'USER_FIRST_NAME',
      lastname: 'USER_LAST_NAME'
    })
    SegmentAnalytics.userRegistered(values.email as string)
    history.push('/auth/register', { email: values.email, isEmailSent: true })
  } catch (e) {
    handleError(e)
  } finally {
    setIsSubmitting(false)
  }
}
export const submit = (
  validate: () => void,
  setIsSubmitting: (isSubmitting: boolean) => void
) => (event: React.FormEvent): void => {
  event.preventDefault()
  validate()
  setIsSubmitting(true)
}
