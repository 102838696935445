import React, { ReactElement, useEffect, useMemo, useState } from 'react'

const isInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const InIframeWrapper = ({ children }: { children: ReactElement }) => {
  const inIframe = useMemo(isInIframe, [])

  useEffect(() => {
    if (inIframe) {
      const script = document.createElement('script')
      script.src = '/vendor/iframeResizer.contentWindow.min.js'
      script.async = true
      document.body.appendChild(script)
    }
  }, [])

  if (!inIframe) return children

  return <div data-iframe-height>{children}</div>
}
