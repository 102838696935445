import { History } from 'history'
import React from 'react'
import { ErrorsState, ValuesState } from '../../../../../hooks/form-validation'
import { VmaApi } from '../../../../../services/vma-api'
import { handleError } from '../../../../../utils/handle-error/handle-error'

export const resetPassword = (
  values: ValuesState<'email'>,
  errors: ErrorsState<'email'>,
  setIsSubmitting: (isSubmitting: boolean) => void,
  history: History
) => async (event: React.FormEvent): Promise<void> => {
  event.preventDefault()
  if (Object.keys(errors).length !== 0) return

  setIsSubmitting(true)

  try {
    const vmaApi = new VmaApi()
    await vmaApi.resetPassword({
      email: values.email as string
    })
    history.replace('/auth/password/reset', {
      isEmailSent: true,
      email: values.email
    })
  } catch (e) {
    setIsSubmitting(false)
    handleError(e)
  }
}
