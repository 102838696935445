import { action, computed, observable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'
import { DiecutContainerConfig, DielineConfigOptions } from '../../services'
import { StoreData, StoreDataState } from '../types'
import { DielineStore } from '../dieline-store/dieline-store'
import { NumberOptionStore } from '../option-store/option-store'
import { DiecutsStore } from '../diecuts-store/diecuts-store'
import { DiecutConfig, GeneratedDiecutConfig } from './types'
import { SHEET_SIZE } from './config/sheet-size'

export class DiecutStore {
  @observable private _thumbnail: StoreData<string | undefined> = {
    state: StoreDataState.pristine,
    data: undefined
  }
  @observable private _noOfUnits: StoreData<number> = {
    state: StoreDataState.pristine,
    data: 0
  }

  @observable private _preferredSpaceBetweenElements = new NumberOptionStore(
    'preferredSpaceBetweenElements',
    5
  )
  @observable private _preferredMaximumNumberOfElements = new NumberOptionStore(
    'preferredMaximumNumberOfElements',
    48
  )

  @observable private _selectedSheetSizes = {
    state: StoreDataState.pristine,
    data: [] as DiecutContainerConfig[]
  }

  @observable private _width = new NumberOptionStore('width', null, SHEET_SIZE)
  @observable private _height = new NumberOptionStore(
    'height',
    null,
    SHEET_SIZE
  )

  @observable private _knifePrice = new NumberOptionStore('knifePrice', null)
  @observable private _knifeLength = new NumberOptionStore('knifeLength', null)

  @observable private _dieCutInfo:
    | { cutLength: number; creaseLength: number }
    | undefined

  @observable private _isGenerating = false

  @observable private _pdf: string | undefined
  @observable private _isPdfBlob = false

  private _uuid: string = uuidv4()
  private _createdAt: string | undefined

  constructor(
    private _dielineStore: DielineStore,
    private _diecutsStore: DiecutsStore,
    diecutConfig?: GeneratedDiecutConfig
  ) {
    if (diecutConfig) {
      this._thumbnail = {
        state: StoreDataState.ok,
        data: diecutConfig.thumbnail
      }
      this._noOfUnits = {
        state: StoreDataState.pristine,
        data: diecutConfig.noOfUnits as number
      }
      this._selectedSheetSizes = {
        state: StoreDataState.pristine,
        data: diecutConfig.selectedSheetSizes
      }
      this._width.setValue(
        (diecutConfig.containers[0] as DiecutContainerConfig).width
      )
      this._height.setValue(
        (diecutConfig.containers[0] as DiecutContainerConfig).height
      )
      this._pdf = diecutConfig.pdf
      this._uuid = diecutConfig.uuid as string
      this._createdAt = diecutConfig.createdAt
    }
  }

  public toJSON = (withUUID = true, pdfFile = false): DiecutConfig => {
    let containers
    if (this.width || this.height) {
      containers = [
        {
          width: this.width,
          height: this.height
        }
      ]
    } else if (this.selectedSheetSizes.length > 0) {
      containers = cloneDeep(this.selectedSheetSizes)
    }

    return {
      sizes: this._dielineStore.numericOptions.reduce((options, option) => {
        return {
          ...options,
          ...option.toJSON()
        }
      }, {}) as DielineConfigOptions,
      product_type: this._dielineStore.type,
      thumbnail: this.thumbnail,
      uuid: withUUID ? this._uuid : undefined,
      noOfUnits: this.noOfUnits,
      dieline: this.dieline.toJSON(),
      ...(pdfFile && { format: 'pdf', pdf: this.pdf }),
      options: {
        with_die_cut_info: true,
        previews: true,
        previews_format: 'svg',
        distance_between_boxes: this.preferredSpaceBetweenElements,
        max_boxes: this.preferredMaximumNumberOfElements
      },
      containers
    }
  }

  public getCopy = () => {
    return new DiecutStore(this._dielineStore, this._diecutsStore)
  }

  public get uuid() {
    return this._uuid
  }

  public set uuid(uuid: string) {
    this._uuid = uuid
  }

  get createdAt() {
    return this._createdAt
  }

  @computed get isFilled() {
    return this.dieline.isNumericFilled
  }

  @computed get thumbnailLoading() {
    return this._thumbnail.state === StoreDataState.loading
  }

  @computed get thumbnail() {
    return this._thumbnail.data
  }

  @computed get width() {
    return this._width.value
  }

  @computed get height() {
    return this._height.value
  }

  @computed get dieline() {
    return this._dielineStore
  }

  @computed get isGenerating() {
    return this._isGenerating
  }

  @computed get noOfUnits() {
    return this._noOfUnits.data
  }

  @computed get selectedSheetSizes() {
    return this._selectedSheetSizes.data
  }

  @computed get format() {
    if (this.isVertical) {
      return `${this.height}x${this.width}mm`
    }
    return `${this.width}x${this.height}mm`
  }

  @computed get isVertical() {
    return !!(this.height && this.width && this.height > this.width)
  }

  @computed get options() {
    return [this._width, this._height]
  }

  @computed get preferredSpaceBetweenElements() {
    return this._preferredSpaceBetweenElements.value
  }

  @computed get preferredMaximumNumberOfElements() {
    return this._preferredMaximumNumberOfElements.value
  }

  @computed get additionalParams() {
    return [
      this._preferredSpaceBetweenElements,
      this._preferredMaximumNumberOfElements
    ]
  }

  @computed get knifePrice() {
    return this._knifePrice.value
  }

  @computed get knifeLength() {
    return this._knifeLength.value
  }

  @computed get knifeParams() {
    return [this._knifePrice, this._knifeLength]
  }

  @computed get dieCutInfo() {
    return this._dieCutInfo
  }

  @computed public get pdf() {
    return this._pdf
  }

  @computed get pdfUrl() {
    if (!this._pdf) return ''
    if (!this._isPdfBlob) return this.pdf

    const file = new Blob([this._pdf], {
      type: 'application/pdf'
    })
    return URL.createObjectURL(file)
  }

  @action.bound setThumbnail(thumbnail: string) {
    this._thumbnail = {
      data: thumbnail,
      state: StoreDataState.ok
    }
  }

  @action.bound setNoOfUnits(units: number) {
    this._noOfUnits = {
      data: units,
      state: StoreDataState.ok
    }
  }
  @action.bound setWidth(width: number | null) {
    this._width.setValue(width)
  }
  @action.bound setHeight(height: number | null) {
    this._height.setValue(height)
  }

  @action.bound setSelectedSheetSizes(
    selectedSheetSizes: DiecutContainerConfig[]
  ) {
    this._selectedSheetSizes = {
      data: selectedSheetSizes,
      state: StoreDataState.ok
    }
  }
  @action.bound setPreferredSpaceBetweenElements(
    preferredSpaceBetweenElements: number | null
  ) {
    this._preferredSpaceBetweenElements.setValue(preferredSpaceBetweenElements)
  }
  @action.bound setPreferredMaximumNumberOfElements(
    preferredMaximumNumberOfElements: number | null
  ) {
    this._preferredMaximumNumberOfElements.setValue(
      preferredMaximumNumberOfElements
    )
  }

  @action.bound setKnifePrice(price: number | null) {
    this._knifePrice.setValue(price)
  }
  @action.bound setKnifeLength(length: number | null) {
    this._knifeLength.setValue(length)
  }
  @action.bound setIsGenerating(generating: boolean) {
    this._isGenerating = generating
  }
  @action.bound setDiecutPdf(pdfBlob: string) {
    this._pdf = pdfBlob
    this._isPdfBlob = true
  }
  @action.bound setDieCutInfo(dieCutInfo: {
    cut_length: number
    crease_length: number
  }) {
    this._dieCutInfo = {
      cutLength: dieCutInfo.cut_length,
      creaseLength: dieCutInfo.crease_length
    }
  }
  @action.bound async generatePdf() {
    await this._diecutsStore.generateDiecutPdf(this)
  }
  @action.bound async generate() {
    this._diecutsStore.clearDiecuts()
    await this._diecutsStore.generate(this)
  }
}
