import React from 'react'
import cxBinder from 'classnames/bind'
import { observer } from 'mobx-react'
import { Param } from '../../../../form'
import { Heading, HeadingSize } from '../../../../layout'
import styles from './Details.module.scss'
import { DetailsProps } from './types'

const cx = cxBinder.bind(styles)

export const Details = observer(({ dieline }: DetailsProps) => {
  return (
    <div
      className={cx('details')}
      e2e-target="details"
      e2e-target-name={`details-${dieline.uuid}`}
    >
      <Heading size={HeadingSize.h2}>Dieline details</Heading>

      <Param label="Product template">{dieline.templateLabel}</Param>
      <Param label="Measurements">Millimeters</Param>
      <Param label="Outer Dimensions">
        {dieline.dimensionOptions.map((option, index: number) => (
          <span key={option.name}>
            {index > 0 ? ', ' : ''}
            {option.label}: {option.text}
          </span>
        ))}
      </Param>
      {dieline.nonDimensionNumericOptions.map((option) => (
        <Param label={option.label} key={option.name}>
          {option.text}
        </Param>
      ))}
    </div>
  )
})
