import { useMemo } from 'react'
import { Estimation } from '../../../../../../../../../../../../../../../../services'
import { ShippingType } from '../types'

export const usePacking = (estimation: Estimation) => {
  const hasPallets = useMemo(
    () => !!estimation.packing.pallets.length,
    [estimation]
  )
  const hasPackages = useMemo(
    () => !!estimation.packing.boxes.length,
    [estimation]
  )

  return {
    hasPackages,
    hasPallets,
    initialType:
      !hasPackages && !hasPallets
        ? null
        : hasPallets
        ? ShippingType.Pallets
        : ShippingType.Packages
  }
}
