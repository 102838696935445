import React, { useEffect } from 'react'
import cxBinder from 'classnames/bind'
import { observer } from 'mobx-react'
import { useRootStore } from '../../../../../hooks'
import { DiecutTile } from '../diecut-tile/DiecutTile'
import { GeneratorPlaceholder } from '../../../placeholders/generator-placeholder/GeneratorPlaceholder'
import { DiecutsStore } from '../../../../../stores/diecuts-store/diecuts-store'
import styles from './DiecutsList.module.scss'
import translations from './translations'

const cx = cxBinder.bind(styles)

export const DiecutsList = observer(
  ({ diecutsStore }: { diecutsStore: DiecutsStore }) => {
    useEffect(() => diecutsStore.clearDiecuts(), [])

    return (
      <div className={cx('diecuts-list')} e2e-target="diecuts-list">
        {diecutsStore.diecuts.length === 0 && (
          <GeneratorPlaceholder titleI18n={translations.placeholderTitle} />
        )}
        {diecutsStore.sortedDiecuts.map((diecut) => (
          <DiecutTile key={diecut.uuid} diecut={diecut} />
        ))}
      </div>
    )
  }
)
