import { useEffect, useState } from 'react'
import { NumberValueHookParams } from './types'

export const useNumberValue = ({
  baseValue,
  name,
  onChange
}: NumberValueHookParams) => {
  const [value, setValue] = useState<string>(
    baseValue ? baseValue.toString() : ''
  )

  const setProperValue = (value: string) => {
    let properValue = value
      .toString()
      .replace(/\./g, ',')
      .replace(',', '.')
      .trim()

    let allowOnly = `\\d`

    if (
      [
        'flap_roundness',
        'wall_flap_ratio',
        'paper_thickness',
        'material_thickness'
      ].includes(name)
    ) {
      allowOnly = `${allowOnly}\\.`
    }

    allowOnly = `[^${allowOnly}]`
    properValue = properValue.replace(new RegExp(allowOnly, 'gm'), '').trim()
    setValue(properValue)
  }

  useEffect(() => {
    const numberValue: number | null = parseFloat(value)
    onChange && onChange(isNaN(numberValue) ? null : numberValue)
  }, [onChange, value])

  return {
    value,
    setValue: setProperValue
  }
}
