import React from 'react'
import { Content } from '../../layout'
import { Heading } from '../../layout/components/heading/Heading'
import { HeadingSize } from '../../layout/components/heading/types'
import { Wrapper } from '../../layout/components/wrapper/Wrapper'
import { ProductTemplatesList } from './components/product-templates-list/ProductTemplatesList'

export const ProductTemplatesView = () => {
  return (
    <Content e2eTargetName="product-templates">
      <Wrapper e2eTargetName="product-templates">
        <Heading size={HeadingSize.h1}>Product templates</Heading>
        <ProductTemplatesList />
      </Wrapper>
    </Content>
  )
}
