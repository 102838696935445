import { DiecutsStore } from '../diecuts-store/diecuts-store'
import { DielinesStore } from '../dielines-store/dielines-store'
import { UserStore } from '../user-store'

export class RootStore {
  public userStore: UserStore
  public dielinesStore: DielinesStore
  public diecutsStore: DiecutsStore

  constructor(token?: string) {
    this.userStore = new UserStore(token)
    this.dielinesStore = new DielinesStore(this)
    this.diecutsStore = new DiecutsStore(this)
  }
}
