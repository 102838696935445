import { Estimation } from '../../../../../../../../../../../../../../../../services'

export const printSheetHelper = (estimation: Estimation): string => {
  const printSheet = estimation.info?.sheet

  if (!printSheet) {
    return 'n/a'
  }

  const elements = `${printSheet.elements} ${
    printSheet.elements > 1 ? 'elements' : 'element'
  }`

  return `${printSheet.width.value} x ${printSheet.height.value} ${printSheet.height.unit} (${elements})`
}
