import React from 'react'
import { UrlToSvg } from '../../../../../url-to-svg/UrlToSvg'
import { StringToSvg } from '../../../../../string-to-svg/StringToSvg'
import { RotatableImage } from '../../../../../rotatable-image/RotatableImage'
import { DiecutStore } from '../../../../../../stores/diecut-store/diecut-store'

export const DiecutThumbnail = ({ diecut }: { diecut: DiecutStore }) => {
  if (!diecut.thumbnail) return null

  if (diecut.thumbnail.endsWith('png'))
    return (
      <RotatableImage
        shouldRotate={diecut.isVertical}
        src={diecut.thumbnail}
        alt={diecut.format}
      />
    )

  if (diecut.thumbnail.endsWith('svg'))
    return (
      <UrlToSvg
        shouldRotate={diecut.isVertical}
        url={diecut.thumbnail}
        e2eTargetName="diecut-thumbnail"
      />
    )

  return (
    <StringToSvg
      shouldRotate={diecut.isVertical}
      svgString={diecut.thumbnail}
      e2eTargetName="diecut-thumbnail"
    />
  )
}
