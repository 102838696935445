export default {
  settings: 'global.account.settings',
  firstName: 'components.views.settings-view.first-name',
  lastName: 'components.views.settings-view.last-name',
  email: 'global.account.email',
  phoneNumber: 'components.views.settings-view.phone-number',
  companyRole: 'components.views.settings-view.company-role',
  saveChanges: 'global.actions.save-changes',
  changesSaved: 'components.views.settings-view.changes-saved',
  changePassword: 'components.views.settings-view.change-password'
}
