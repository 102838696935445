import React from 'react'
import { observer } from 'mobx-react'
import { BooleanOptionStore, StringOptionStore } from '../../../../../stores'
import {
  CheckboxField,
  DielineNumericFields,
  TextField
} from '../../../../form'
import { DielineFieldsProps } from './types'

export const DielineFields = observer(
  ({ dieline, onFocus, onBlur }: DielineFieldsProps) => {
    return (
      <>
        <DielineNumericFields
          options={dieline.dimensionOptions}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <DielineNumericFields options={dieline.nonDimensionNumericOptions} />
        {dieline.textOptions.map((option: StringOptionStore) => (
          <TextField
            key={option.name}
            name={option.name}
            value={option.value}
            label={option.label}
            error={option.error}
            onChange={option.setValue}
          />
        ))}
        {dieline.booleanOptions.map((option: BooleanOptionStore) => (
          <CheckboxField
            key={option.name}
            name={option.name}
            value={option.value}
            label={option.label}
            error={option.error}
            onChange={option.setValue}
          />
        ))}
      </>
    )
  }
)
