import {
  BoxesflowApi,
  DielineConfig,
  DielineCreateResponse,
  DielineFormat
} from '../boxesflow-api'
import {
  CreateProductConfigRequest,
  PaginationMeta,
  ProductConfig,
  ProductType,
  VmaApi,
  VmaFile
} from '../vma-api'
import { productTypesData } from './data/product-types'

export class DielinesService {
  constructor(
    private _boxesflow = new BoxesflowApi(),
    private _vma = new VmaApi()
  ) {}

  public generateSVGDieline = async (
    config: DielineConfig
  ): Promise<string> => {
    const response: DielineCreateResponse = await this._boxesflow.generateDieline(
      {
        product_type: config.product_type,
        options: config.options,
        format: DielineFormat.svg
      })
    return response.data.response_data
  }

  public generatePDFDieline = async (
    config: DielineConfig
  ): Promise<string> => {
    const response: DielineCreateResponse = await this._boxesflow.generateDieline(
      {
        product_type: config.product_type,
        options: config.options,
        format: DielineFormat.pdf
      }
    )
    return response.data.response_data
  }

  public loadDieline = async (
    uuid: string
  ): Promise<DielineConfig | undefined> => {
    const { data } = await this._vma.getDieline(uuid)
    return this._mapProductConfigToDielineConfig(data)
  }

  public loadDielines = async (
    page?: number,
    perPage?: number
  ): Promise<{
    dielines: DielineConfig[]
    meta: PaginationMeta
  }> => {
    const { data, meta } = await this._vma.getDielines(page, perPage)
    return {
      dielines: data
        .map(this._mapProductConfigToDielineConfig)
        .filter((dieline) => !!dieline) as DielineConfig[],
      meta
    }
  }

  public saveDieline = async (
    dielineToSave: DielineConfig,
    ownerUuid: string
  ): Promise<string> => {
    const [pdf, svg] = await Promise.all([
      this._vma.createFile(dielineToSave.pdf as string, 'application/pdf'),
      this._vma.createFile(dielineToSave.svg as string, 'image/svg+xml')
    ])

    const savedDieline = await this._vma.createProductTemplate(
      this._mapDielineConfigToProductConfigRequest(
        dielineToSave,
        { pdf, svg },
        ownerUuid
      )
    )
    return savedDieline.uuid
  }

  public loadProductTemplates = async (): Promise<DielineConfig[]> => {
    return productTypesData
  }

  private _mapProductConfigToDielineConfig = (
    productConfig: ProductConfig
  ): DielineConfig | undefined => {
    const { id, specification, attachments } = productConfig
    const config: { [key: string]: unknown } = specification.reduce(
      (result, { name, value }) => {
        return Object.assign(result, {
          [name]:
            name === 'svg' || name === 'pdf'
              ? (
                  attachments.find(
                    (attachment) => attachment.uuid === value
                ) as VmaFile).url
              : value
        })
      },
      {}
    )
    if (!config.options || !config.product_type) {
      return undefined
    }
    return {
      ...((config as unknown) as DielineConfig),
      uuid: id,
      created_at: productConfig.created_at
    }
  }

  private _mapDielineConfigToProductConfigRequest = (
    dielineConfig: DielineConfig,
    files: { svg: VmaFile; pdf: VmaFile },
    ownerUuid: string
  ): CreateProductConfigRequest => {
    return {
      name: dielineConfig.name || dielineConfig.product_type,
      owner: ownerUuid,
      type: ProductType.dieline,
      specification: Object.keys(dielineConfig).map((key) => {
        if (Object.keys(files).includes(key))
          return { name: key, value: files[key as keyof typeof files].uuid }
        return { name: key, value: dielineConfig[key as keyof DielineConfig] }
      }, []),
      attachments: [files.svg, files.pdf]
    }
  }
}
