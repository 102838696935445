import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import './App.scss'
import '@packhelp/platform-dsl/platform-dsl.css'

import { AuthView, ToolsView, SettingsView, ErrorView } from '../views'
import { RootStoreProvider } from '../../stores'
import { SearchParamsProvider } from '../../utils/search-params/search-params'
import { InIframeWrapper } from '../in-iframe-wrapper/InIframeWrapper'
import { InstantPriceView } from '../views/instant-price-view/InstantPriceView'

export const App = () => {
  return (
    <InIframeWrapper>
      <SearchParamsProvider>
        <RootStoreProvider>
          <Router>
            <Switch>
              <Route path="/auth" component={AuthView} />
              <Route path="/tools" component={ToolsView} />
              <Route path="/settings" component={SettingsView} />
              <Route path="/instant-price" component={InstantPriceView} />
              <Redirect exact from="/" to="/tools" />
              <Redirect from="/index" to="/tools" />
              <Redirect from="/home" to="/tools" />
              <Route component={ErrorView} />
            </Switch>
          </Router>
        </RootStoreProvider>
      </SearchParamsProvider>
    </InIframeWrapper>
  )
}
