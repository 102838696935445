import React from 'react'
import { observer } from 'mobx-react'
import { Select, SelectSize } from '@packhelp/platform-dsl/molecules'
import { PopupSizeProp } from '@packhelp/platform-dsl/atoms/Popup'
import { Field } from '../../../form'
import { SelectItem } from '../../../form/select-item/SelectItem'
import { useFormTemplates } from './hooks/form-templates/form-templates'
import { TemplateSelectProps } from './types'
import translations from './translations'
import { FormatMessage } from './../../../../libs/localisation/LocaleIntlProvider'

export const TemplateSelect = observer(
  ({ template, route, forPacking=false }: TemplateSelectProps) => {
    const { templates, onTemplateChange } = useFormTemplates({
      route,
      forPacking
    })

    return (
      <Field>
        <label htmlFor="template">
          <FormatMessage id={translations.title} />
        </label>
        <Select
          placeholder="Please choose template"
          name="template"
          data={templates}
          value={template}
          onSelect={onTemplateChange}
          size={SelectSize.large}
          popupSize={PopupSizeProp.auto}
          listItemRenderer={(label) => <SelectItem label={label} />}
        />
      </Field>
    )
  }
)
