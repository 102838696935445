import React from 'react'
import { observer } from 'mobx-react'
import { Card, CardPadding, CardHeight } from '@packhelp/platform-dsl/atoms'
import { TemplateSelect } from '../template-select/TemplateSelect'
import { HeadingSize } from '../../../layout/components/heading/types'
import { Heading } from '../../../layout/components/heading/Heading'
import { DielineNumericFields } from '../../../form'
import { DielineFields } from '../../generate-dieline-view/components/dieline-fields/DielineFields'
import { NumberOptionStore } from '../../../../stores'
import { GenerateProductFormProps } from './types'
import styles from './GenerateProductForm.module.scss'
import translations from './translations'
import { FormatMessage } from './../../../../libs/localisation/LocaleIntlProvider'
import { AdditionalParametersForm } from './AdditionalParametersForm'

export const GenerateProductForm = observer(
  ({
    template,
    route,
    dieline,
    diecut,
    onFocus,
    onBlur,
    children,
    forPacking
  }: GenerateProductFormProps) => {
    return (
      <div className={styles['form']} e2e-target="form">
        <Card
          border={true}
          padding={CardPadding.medium}
          height={CardHeight.auto}
        >
          <TemplateSelect
            template={template}
            route={route}
            forPacking={forPacking}
          />

          {template && (
            <>
              <Heading size={HeadingSize.h3}>
                <FormatMessage id={translations.outerDimensions} />
              </Heading>
              {diecut && (
                <>
                  <DielineNumericFields
                    options={diecut.dieline.dimensionOptions}
                  />
                  <DielineNumericFields
                    options={diecut.dieline.numericOptions.filter(
                      (option: NumberOptionStore) =>
                        ['tuck_flap_size', 'glue_flap_size'].includes(
                          option.name
                        )
                    )}
                  />
                  <AdditionalParametersForm diecut={diecut} />
                </>
              )}
              {dieline && (
                <DielineFields
                  dieline={dieline}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
              )}
            </>
          )}
        </Card>
        {children}
      </div>
    )
  }
)
