import { action, autorun, computed, observable, when } from 'mobx'
import { pick } from 'lodash'
import { DielineStore } from '../dieline-store/dieline-store'
import { DiecutStore } from '../diecut-store/diecut-store'
import { PaginatedStoreData, StoreDataState } from '../types'
import { DiecutsService } from '../../services/diecuts-service/diecuts-service'
import { RootStore } from '../root-store'
import { GeneratedDiecutConfig } from '../diecut-store/types'
import { SegmentAnalytics } from '../../libs/segment-analytics'

export class DiecutsStore {
  @observable.shallow private _diecuts: PaginatedStoreData<DiecutStore[]> = {
    state: StoreDataState.pristine,
    data: []
  }

  constructor(
    private _rootStore: RootStore,
    private _diecutsService = new DiecutsService()
  ) {
    autorun((): void => {
      if (!this._rootStore.userStore.isAuthenticated) this.clearDiecuts()
    })
  }

  @computed get pagination() {
    if (this._diecuts.state !== StoreDataState.ok) {
      return undefined
    }
    const { page, per_page, total } = this._diecuts.meta
    return {
      page,
      pages: Math.ceil(total / per_page),
      perPage: per_page,
      changePage: (newPage: number) => {
        this.getDiecuts(newPage, per_page)
      },
      changePerPage: (perPage: number) => {
        const newPage = Math.ceil(((page - 1) * per_page) / total)
        this.getDiecuts(newPage, perPage)
      }
    }
  }

  @action.bound async getDiecut(uuid: string) {
    const diecut = this._diecuts.data.find((diecut) => diecut.uuid === uuid)
    if (diecut) return diecut
    const diecutConfig = await this._diecutsService.loadDiecut(uuid)
    return this._mapDiecutConfigToDiecutStore(diecutConfig)
  }

  @action.bound async getDiecuts(page?: number, perPage?: number) {
    await when(
      () =>
        this._rootStore.userStore.isInitialized &&
        this._rootStore.dielinesStore.isDielinesInitialized
    )
    if (this._rootStore.userStore.isAuthenticated) {
      this._diecuts.state = StoreDataState.loading
      const { diecuts, meta } = await this._diecutsService.loadDiecuts(
        page,
        perPage
      )

      this._diecuts = {
        data: this._mapDiecutConfigsToDiecutStores(diecuts),
        state: StoreDataState.ok,
        meta
      }
      return
    }
    this._diecuts = {
      state: StoreDataState.ok,
      meta: {
        total: 0,
        page: 1,
        per_page: 30
      },
      data: []
    }
  }

  @action.bound newDiecut(dieline: DielineStore) {
    return new DiecutStore(dieline, this)
  }

  @action.bound async saveDiecut(diecut: DiecutStore) {
    if (this._rootStore.userStore.isAuthenticated) {
      const uuid = await this._diecutsService.saveDiecut(
        diecut,
        this._rootStore.userStore.uuid as string
      )
      diecut.uuid = uuid
    }
    this._diecuts.data = [...this.diecuts, diecut]
  }

  @action.bound public generate = async (diecut: DiecutStore) => {
    diecut.setIsGenerating(true)
    let response
    try {
      const dielineCopy = diecut.dieline.getCopy()
      response = await this._diecutsService.generateDiecut(
        pick(diecut.toJSON(), [
          'sizes',
          'product_type',
          'containers',
          'options'
        ])
      )
      await Promise.all(
        response.map(async (container) => {
          const diecutTemplate = new DiecutStore(dielineCopy, this)
          diecutTemplate.setThumbnail(container.thumbnail)
          diecutTemplate.setNoOfUnits(container.boxes.length)
          diecutTemplate.setWidth(container.width)
          diecutTemplate.setHeight(container.height)
          diecutTemplate.setPreferredMaximumNumberOfElements(
            diecut.preferredMaximumNumberOfElements
          )
          diecutTemplate.setPreferredSpaceBetweenElements(
            diecut.preferredSpaceBetweenElements
          )
          diecutTemplate.setSelectedSheetSizes(diecut.selectedSheetSizes)
          diecutTemplate.setKnifePrice(diecut.knifePrice);
          diecutTemplate.setKnifeLength(diecut.knifeLength)
          diecutTemplate.setDieCutInfo(container.die_cut_info)
          await diecutTemplate.generatePdf()
          await this.saveDiecut(diecutTemplate)
        })
      )
      SegmentAnalytics.diecutLayoutGenerated(diecut, this._rootStore.userStore)
    } finally {
      if (
        this._rootStore.userStore.isAuthenticated &&
        response &&
        response.length
      ) {
        await this.getDiecuts(1, response.length)
      }

      diecut.setIsGenerating(false)
    }
  }

  @action.bound generateDiecutPdf = async (diecut: DiecutStore) => {
    const response = await this._diecutsService.generateDiecutPdf(
      pick(diecut.toJSON(true, true), [
        'sizes',
        'product_type',
        'containers',
        'format',
        'options'
      ])
    )
    diecut.setDiecutPdf(response)
  }

  @action.bound clearDiecuts() {
    this._diecuts = {
      state: StoreDataState.pristine,
      data: []
    }
  }

  @computed get sortedDiecuts() {
    return this._diecuts.data.sort((diecutA, diecutB) => {
      if (diecutA.noOfUnits === diecutB.noOfUnits) return 0

      return diecutA.noOfUnits < diecutB.noOfUnits ? 1 : -1
    })
  }

  @computed get diecuts() {
    return this._diecuts.data
  }

  @computed get isInitialized() {
    return this._diecuts.state !== StoreDataState.pristine
  }

  @computed get isLoading() {
    return this._diecuts.state === StoreDataState.loading
  }

  @action.bound private _mapDiecutConfigToDiecutStore(
    diecutConfig: GeneratedDiecutConfig
  ): DiecutStore | undefined {
    return diecutConfig.dieline
      ? new DiecutStore(
          new DielineStore(this._rootStore.dielinesStore, diecutConfig.dieline),
          this,
          diecutConfig
        )
      : undefined
  }

  @action.bound private _mapDiecutConfigsToDiecutStores(
    diecutConfigs: GeneratedDiecutConfig[]
  ): DiecutStore[] {
    return diecutConfigs
      .map(this._mapDiecutConfigToDiecutStore)
      .filter(
        (diecutStore): diecutStore is DiecutStore =>
          diecutStore instanceof DiecutStore
      )
  }
}
