import React from 'react'
import cxBinder from 'classnames/bind'
import { observer } from 'mobx-react'
import {
  Card,
  CardHeight,
  CardPadding,
  LinkAsButton,
  ButtonSize
} from '@packhelp/platform-dsl/atoms'

import { Param } from '../../../../form'
import { SegmentAnalytics } from '../../../../../libs/segment-analytics'
import { DiecutStore } from '../../../../../stores/diecut-store/diecut-store'
import { useRootStore } from '../../../../../hooks'
import {
  FormatMessage,
  useFormatMessage
} from '../../../../../libs/localisation/LocaleIntlProvider'
import { DiecutTileProps } from './types'
import {
  preparePdfFileName,
  validateKnifeLength,
  validateNumberOfElements,
  calculateKnifeLength,
  calculateTotalKnifePrice,
  maximumNumberOfElements
} from './helpers'
import styles from './DiecutTile.module.scss'
import translations from './translations'
import { DiecutThumbnail } from './components/DiecutThumbnail'

const cx = cxBinder.bind(styles)

export const DiecutTile = observer(({ diecut, fullWidth }: DiecutTileProps) => {
  const calculatedKnifeLength = calculateKnifeLength(
    diecut.dieCutInfo?.cutLength,
    diecut.dieCutInfo?.creaseLength
  )
  const calculatedTotalKnifePrice = calculateTotalKnifePrice(
    diecut.knifePrice,
    calculatedKnifeLength
  )
  const validatedKnifeLengthError = validateKnifeLength(
    diecut.knifeLength,
    calculatedKnifeLength
  )

  const validatedNumberOfElementsError = validateNumberOfElements(
    diecut.noOfUnits
  )

  return (
    <div
      className={cx('diecut-tile', { [`diecut-tile__full-width`]: fullWidth })}
    >
      <Card
        key={diecut.uuid}
        border={true}
        padding={CardPadding.medium}
        height={CardHeight.auto}
      >
        <div className={styles['diecut-tile__content']}>
          <div className={styles['diecut-tile__thumbnail']}>
            <DiecutThumbnail diecut={diecut} />
          </div>
          <div className={styles['diecut-tile__diecut-layout']}>
            <Param label={useFormatMessage(translations.sheetSize)}>
              <strong>{diecut.format}</strong>
            </Param>
            <Param label={useFormatMessage(translations.noOfElements)}>
              <strong>{diecut.noOfUnits}</strong>
              {validatedNumberOfElementsError && (
                <div className={styles['maximum-number-of-elements']}>
                  {useFormatMessage(validatedNumberOfElementsError, {
                    maxValue: maximumNumberOfElements
                  })}
                </div>
              )}
            </Param>
            {calculatedKnifeLength && (
              <Param label="Knife length">
                <div
                  className={
                    validatedKnifeLengthError
                      ? styles['invalid-knife-length']
                      : ''
                  }
                >
                  <strong>{calculatedKnifeLength} m</strong>
                  <span>
                    {validatedKnifeLengthError &&
                      useFormatMessage(validatedKnifeLengthError)}
                  </span>
                </div>
              </Param>
            )}
          </div>
          <div className={styles['diecut-tile__dieline-params']}>
            <Param label={useFormatMessage(translations.dieline)}>
              {diecut.dieline.label}
            </Param>
            <Param label={useFormatMessage(translations.dimensions)}>
              {diecut.dieline.dimensionsLabel}
            </Param>
            {calculatedTotalKnifePrice && (
              <Param label={useFormatMessage(translations.diecutCost)}>
                {calculatedTotalKnifePrice} pln
              </Param>
            )}
            {diecut.pdfUrl && <DownloadButton diecut={diecut} />}
          </div>
        </div>
      </Card>
    </div>
  )
})

export const DownloadButton = ({ diecut }: { diecut: DiecutStore }) => {
  const {
    pdfUrl,
    dieline: { label }
  } = diecut
  const fileName = preparePdfFileName(label)
  const { userStore } = useRootStore()

  return (
    <div className={styles['download-pdf']}>
      <LinkAsButton
        size={ButtonSize.large}
        href={pdfUrl}
        download={fileName}
        target="_blank"
        onClick={() =>
          SegmentAnalytics.diecutLayoutDownloaded(diecut, userStore)
        }
      >
        <FormatMessage id={translations.downloadButton} />
      </LinkAsButton>
    </div>
  )
}
