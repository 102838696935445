import React from 'react'
import { Heading, HeadingSize, MarginSize, TwoColumnLayout } from '../../layout'
import { FormatMessage } from '../../../libs/localisation/LocaleIntlProvider'
import translations from './translations'

export const ErrorView = ({
  error = 404,
  title,
  message = <FormatMessage id={translations.pageNotFound} />
}: {
  error?: number
  title?: string
  message: string | React.ReactElement
}) => {
  return (
    <TwoColumnLayout>
      <Heading size={HeadingSize.h1} marginBottom={MarginSize.xl}>
        {title ||
          (error ? (
            <FormatMessage id={translations.error} values={{ error }} />
          ) : (
            <FormatMessage id={translations.unknownError} />
          ))}
      </Heading>
      <Heading size={HeadingSize.h4}>{message}</Heading>
    </TwoColumnLayout>
  )
}
