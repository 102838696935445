import { DielineStore } from '../../stores'
import { DiecutStore } from '../../stores/diecut-store/diecut-store'
import { UserStore } from '../../stores/user-store'
import {
  ProductEventCommonProperties,
  DielineEventCommonProperties,
  DiecutEventCommonProperties,
  SegmentEvent
} from './types'
import * as EVENTS from './events'

// eslint-disable-next-line no-unused-vars
declare global {
  interface Window {
    analytics: any
  }
}

export class SegmentAnalytics {
  public static init() {
    if (!process.env.REACT_APP_SEGMENT_WRITE_KEY) return
    /* eslint-disable */
    // @ts-ignore
    (function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
      analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);
    }})();
    /* eslint-enable */
  }

  private static track({ event, properties, options, callback }: SegmentEvent) {
    if (window.analytics) {
      window.analytics.track(event, properties, options, callback)
    }
  }

  public static userRegistered(email: string): void {
    SegmentAnalytics.track({
      event: EVENTS.USER_REGISTERED,
      properties: { email }
    })
  }

  public static accountConfirmed(uuid: string): void {
    SegmentAnalytics.track({
      event: EVENTS.ACCOUNT_CONFIRMED,
      properties: { uuid }
    })
  }

  public static dielineGenerated(dieline: DielineStore, user: UserStore): void {
    SegmentAnalytics.track({
      event: EVENTS.DIELINE_GENERATED,
      properties: SegmentAnalytics._getCommonDielineEventProperties(
        dieline,
        user
      )
    })
  }

  public static dielineDownloaded(
    dieline: DielineStore,
    user: UserStore
  ): void {
    SegmentAnalytics.track({
      event: EVENTS.DIELINE_DOWNLOADED,
      properties: SegmentAnalytics._getCommonDielineEventProperties(
        dieline,
        user
      )
    })
  }

  public static diecutLayoutGenerated(
    diecut: DiecutStore,
    user: UserStore
  ): void {
    SegmentAnalytics.track({
      event: EVENTS.DIECUT_LAYOUT_GENERATED,
      properties: SegmentAnalytics._getCommonDiecutEventProperties(diecut, user)
    })
  }

  public static diecutLayoutDownloaded(
    diecut: DiecutStore,
    user: UserStore
  ): void {
    SegmentAnalytics.track({
      event: EVENTS.DIECUT_LAYOUT_DOWNLOADED,
      properties: SegmentAnalytics._getCommonDiecutEventProperties(diecut, user)
    })
  }

  private static _getCommonProductEventProperties(
    dieline: DielineStore,
    user: UserStore
  ): ProductEventCommonProperties {
    const options = dieline.dimensionOptions.reduce<{ [key: string]: number | null }>(
      (result, option) =>
        Object.assign(result, { [option.name]: option.value }),
      {}
    )

    return {
      is_registered: user.isAuthenticated,
      product_template: dieline.templateLabel,
      product_dimensions: {
        width: String(options.width),
        depth: options.depth ? String(options.depth) : undefined,
        height: String(options.height)
      }
    }
  }

  private static _getCommonDielineEventProperties(
    dieline: DielineStore,
    user: UserStore
  ): DielineEventCommonProperties {
    const options = dieline.options.reduce<{ [key: string]: unknown }>(
      (result, option) => Object.assign({}, { [option.name]: option.value }),
      {}
    )

    return {
      paper_thickness: String(options.paper_thickness),
      ...SegmentAnalytics._getCommonProductEventProperties(dieline, user)
    }
  }

  private static _getCommonDiecutEventProperties(
    diecut: DiecutStore,
    user: UserStore
  ): DiecutEventCommonProperties {
    return {
      generated_from_dieline: diecut.dieline.isGeneratedDieline,
      sheet_size:
        diecut.width || diecut.height
          ? {
              width: diecut.width ? String(diecut.width) : undefined,
              height: diecut.height ? String(diecut.height) : undefined
            }
          : undefined,
      ...SegmentAnalytics._getCommonProductEventProperties(diecut.dieline, user)
    }
  }
}
