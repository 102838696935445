import React from 'react'
import { PackingBoxes } from '../../../../../../../../../../../../../../../../../services'
import { PackagesCost } from './components/packagesCost'

type Props = {
  boxes: PackingBoxes[]
}

const Packages: React.FC<Props> = ({ boxes }) => {
  return (
    <div>
      {boxes.map((packagesCost, index) => (
        <PackagesCost {...packagesCost} key={index} />
      ))}
    </div>
  )
}

export default Packages
