interface HostsReturnType {
  pssBaseUrl: string
  previewsBaseUrl: string
}

export const useBaseUrls = (): HostsReturnType => {
  return {
    pssBaseUrl: process.env.REACT_APP_PSS_HOSTNAME || '',
    previewsBaseUrl: `${process.env.REACT_APP_DOCUMENTS_S3_DOMAIN}/product-specification-form/previews/`
  }
}
