import React from 'react'
import { NavigationMenuHeader } from './components/navigation-menu-header/NavigationMenuHeader'
import { NavigationMenuLink } from './components/navigation-menu-link/NavigationMenuLink'
import { NavigationMenuConfig, NavigationMenuProps } from './types'

export const NavigationMenu = ({
  menuConfig,
  e2eTargetName
}: NavigationMenuProps) => {
  return (
    <div
      e2e-target="navigation-menu"
      e2e-target-name={e2eTargetName}
      role="navigation"
    >
      {menuConfig.map((menuElement: NavigationMenuConfig, index: number) => {
        if (!menuElement.url) {
          return (
            <NavigationMenuHeader
              key={`menu-element-${index}`}
              label={menuElement.label}
            />
          )
        }

        return (
          <NavigationMenuLink
            key={`menu-element-${index}`}
            label={menuElement.label}
            url={menuElement.url}
            submenu={menuElement.submenu}
          />
        )
      })}
    </div>
  )
}
