import { useEffect, useState, useMemo } from 'react'
import { ProductSpecification } from '@packhelp/platform-pss-form'
import { BoxesflowApi } from '../../../../../../services'
import { BoxesflowContract } from '../../../contracts/InstantPriceContextContract'

export enum AvailabilityState {
  notReady,
  loading,
  notAvailable,
  available
}

type UseActionsReturnType = {
  currentStateText: string
  currentState: AvailabilityState
}

export const text = {
  [AvailabilityState.notReady]:
    'Fill out the required fields to see if the instant estimation will be available',
  [AvailabilityState.loading]: 'Checking if instant estimations are available',
  [AvailabilityState.notAvailable]:
    'There are no price estimations for this specification.',
  [AvailabilityState.available]:
    'Price estimations for this specification should be available.'
}

const sampleQuantities = [1000, 10000, 20000, 30000]

export const useActions = (
  specification: ProductSpecification,
  postalCode: string | null,
  country: string | null,
  boxesflow: BoxesflowContract
): UseActionsReturnType => {
  const boxesFlowService = new BoxesflowApi
  const [loadingEstimation, setLoadingEstimation] = useState<boolean>(false)
  const [hasEstimations, setEstimations] = useState<boolean | null>(null)
  const [
    currentStateOverride,
    setCurrentStateOverride
  ] = useState<AvailabilityState | null>(null)
  const [wasSpecificationChanged, setSpecificationChangedState] = useState<
    boolean
  >(false)

  useEffect(() => {
    setCurrentStateOverride(null)
    setSpecificationChangedState(false)
    setLoadingEstimation(false)
    setEstimations(null)
  }, [specification.attributes['type__name']])

  useEffect(() => {
    if (boxesflow.loading) {
      setCurrentStateOverride(AvailabilityState.loading)
    } else {
      setCurrentStateOverride(boxesflow.estimations.length > 0 ? AvailabilityState.available : AvailabilityState.notAvailable)
    }
  }, [boxesflow.estimations, boxesflow.loading])

  useEffect(() => {
    ;(async () => {
      setCurrentStateOverride(null)
      if (wasSpecificationChanged) {
        setEstimations(null)
        setLoadingEstimation(true)

        const result = await boxesFlowService.getInstantEstimations({
          specification,
          quantities: sampleQuantities,
          shippingDestination: {
            country,
            postalCode
          },
        }, true)

        if (!result.requestCancelled) {
          setLoadingEstimation(false)
          setEstimations(result.estimations.length > 0)
        }
      }

      setSpecificationChangedState(true)
    })()
  }, [JSON.stringify(specification)])

  const currentState = useMemo(() => {
    if (currentStateOverride) {
      return currentStateOverride
    }
    if (loadingEstimation) {
      return AvailabilityState.loading
    }
    if (hasEstimations !== null) {
      return hasEstimations
        ? AvailabilityState.available
        : AvailabilityState.notAvailable
    }

    return AvailabilityState.notReady
  }, [
    hasEstimations,
    loadingEstimation,
    currentStateOverride
  ])

  const currentStateText = useMemo(() => text[currentState], [currentState])

  return {
    currentState,
    currentStateText
  }
}
