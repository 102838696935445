import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Loading, LoadingSize } from '@packhelp/platform-dsl/atoms'
import { UserAccount } from '../user-account/UserAccount'
import { MenuItem } from '../menu-item/MenuItem'
import { useRootStore } from '../../../../../../hooks'

export const TopRightMenu = observer(() => {
  const { userStore } = useRootStore()
  useEffect(() => {
    userStore.authenticate()
  }, [])

  return (
    <>
      {userStore.isAuthenticated ? (
        <UserAccount />
      ) : userStore.isLoading ? (
        <Loading size={LoadingSize.m} lightMode />
      ) : (
        <>
          <MenuItem url="/auth/register">Register</MenuItem>
          <MenuItem url="/auth/login">Log in</MenuItem>
        </>
      )}
    </>
  )
})
