export default {
  newPassword:
    'components.views.reset-password-view.new-password-form.new-password',
  confirmPassword:
    'components.views.reset-password-view.new-password-form.confirm-password',
  setPassword:
    'components.views.reset-password-view.new-password-form.set-new-password',
  enterPassword:
    'components.views.reset-password-view.new-password-form.enter-new-password',
  email: 'global.account.email'
}
