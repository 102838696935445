import { History } from 'history'
import React from 'react'
import { ErrorsState, ValuesState } from '../../../../../hooks/form-validation'
import { VmaApi } from '../../../../../services/vma-api'
import { handleError } from '../../../../../utils/handle-error/handle-error'

export const setNewPassword = (
  code: string,
  email: string,
  values: ValuesState<'password' | 'confirmPassword'>,
  errors: ErrorsState<'password' | 'confirmPassword'>,
  setIsSubmitting: (isSubmitting: boolean) => void,
  history: History
) => async (event: React.FormEvent): Promise<void> => {
  event.preventDefault()
  if (Object.keys(errors).length !== 0) return

  setIsSubmitting(true)

  try {
    const vmaApi = new VmaApi()
    await vmaApi.setPassword({
      code,
      email,
      password: values.password as string,
      confirmPassword: values.confirmPassword as string
    })
    history.push('/auth/login')
  } catch (e) {
    setIsSubmitting(false)
    handleError(e)
  }
}
