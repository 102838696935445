import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useRootStore } from '../../../../../hooks'
import { DielineStore } from '../../../../../stores'
import { DiecutStore } from '../../../../../stores/diecut-store/diecut-store'
import {
  DielineNotFound,
  ProductTemplateNotFound
} from '../../../../../stores/dielines-store/types'
import { handleError } from '../../../../../utils/handle-error/handle-error'
import { DiecutsStore } from '../../../../../stores/diecuts-store/diecuts-store'

export const useData = () => {
  const rootStore = useRootStore()
  const { dielinesStore } = rootStore
  const diecutsStore = useRef(new DiecutsStore(rootStore)).current

  const { template, dielineUuid } = useParams<{
    template?: string
    dielineUuid?: string
  }>()

  const [dieline, setDieline] = useState<DielineStore | undefined>()
  const [diecut, setDiecut] = useState<DiecutStore | undefined>()
  const [redirect, setRedirect] = useState<boolean>(false)

  useEffect(() => {
    if (template) {
      setDieline(dielinesStore.newDieline(template))
    }
  }, [setDieline, template])

  useEffect(() => {
    if (
      dielinesStore.isTemplatesInitialized &&
      dielinesStore.isDielinesInitialized
    ) {
      try {
        let dielineCopy: DielineStore
        if (dielineUuid) {
          dielinesStore.getDieline(dielineUuid).then((dielineSource) => {
            if (dielineSource.templateSlug !== template) {
              throw new ProductTemplateNotFound()
            }
            setDieline(dielineSource)
            setDiecut(diecutsStore.newDiecut(dielineSource))
          })
        } else if (template && !dielineUuid) {
          dielineCopy = dielinesStore.newDieline(template)
          setDieline(dielineCopy)
          setDiecut(diecutsStore.newDiecut(dielineCopy))
        }
      } catch (error) {
        handleError(error)
        if (
          error instanceof ProductTemplateNotFound ||
          error instanceof DielineNotFound
        )
          setRedirect(true)
      }
    }
  }, [
    template,
    dielinesStore.isDielinesInitialized,
    dielinesStore.isTemplatesInitialized,
    dielineUuid
  ])

  const isLoading = useMemo(() => template && (!dieline || !diecut), [
    template,
    dieline,
    diecut
  ])

  return {
    isLoading,
    redirect,
    dieline,
    diecut,
    diecutsStore,
    template
  }
}
