import React, { useContext, useMemo } from 'react'
import {
  Button,
  ButtonMode,
  LoadingSize,
  Loading,
  ButtonSize,
  TType,
  Typography
} from '@packhelp/platform-dsl/atoms'
import { InstantPriceContext } from '../../../../InstantPrice.context'
import styles from './Estimations.module.scss'
import { Warnings } from './components/warnings'
import { useQuantityPrices } from './hooks/quantityPrices'
import { QuantityPrices } from './components/quantityPrices'

const Estimations: React.FC = () => {
  const {
    estimate,
    boxesflow: { loading, warnings, estimations }
  } = useContext(InstantPriceContext)
  const { sort } = useQuantityPrices()
  const sortedEstimations = useMemo(() => sort(estimations), [estimations])

  return (
    <div>
      <Typography type={TType.Medium14_20} className={styles.label}>
        Instant estimations
      </Typography>

      <Typography type={TType.Regular14_16} className={styles.paragraph}>
        Click to check for production costs instant estimations. Please note
        that these costs <b>don't include</b> Packhelp's margins.
      </Typography>

      <Button
        size={ButtonSize.large}
        mode={ButtonMode.secondary}
        onClick={estimate}
        disabled={loading}
      >
        Check for estimations
        {loading && (
          <span className={styles.buttonLoader}>
            <Loading size={LoadingSize.s} />
          </span>
        )}
      </Button>

      <Warnings warnings={warnings} />

      {sortedEstimations.map(({ quantity, estimations: sortedEstimations }) => (
        <QuantityPrices
          quantity={quantity}
          estimations={sortedEstimations}
          key={quantity}
        />
      ))}
    </div>
  )
}

export default Estimations
