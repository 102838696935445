import React, { useState } from 'react'
import { VmaApi } from '../../../../../../../services/vma-api'
import {
  ErrorsState,
  ValuesState
} from '../../../../../../../hooks/form-validation'
import { ChangePasswordFields } from '../../types'
import { useRootStore } from '../../../../../../../hooks'
import { handleError } from '../../../../../../../utils/handle-error/handle-error'
import { snackbar } from '../../../../../../../utils/snackbar/snackbar'
import { useTranslations } from '../../../../../../../libs/localisation/LocaleIntlProvider'
import translations from '../../translations'

export const useActions = () => {
  const [isChangingPassword, setChangingPassword] = useState(false)
  const { userStore } = useRootStore()
  const messages = useTranslations(translations)
  const vmaApi = new VmaApi()

  const changePassword = (
    values: ValuesState<ChangePasswordFields>,
    errors: ErrorsState<ChangePasswordFields>,
    onSuccess: () => void
  ) => async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    if (Object.keys(errors).length !== 0) return
    try {
      setChangingPassword(true)
      await vmaApi.changePassword({
        uuid: userStore.uuid as string,
        oldPassword: values.password as string,
        password: values.password as string,
        confirmPassword: values.password as string
      })
      snackbar.success(messages.passwordChanged)
      onSuccess()
    } catch (error) {
      handleError(error)
    } finally {
      setChangingPassword(false)
    }
  }
  return {
    changePassword,
    isChangingPassword
  }
}
