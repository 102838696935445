import React from 'react'
import cxBinder from 'classnames/bind'
import { useFormatMessage } from '../../../../libs/localisation/LocaleIntlProvider'
import { NavigationMenuHeaderProps } from './types'
import styles from './NavigationMenuHeader.module.scss'

const cx = cxBinder.bind(styles)

export const NavigationMenuHeader = ({
  label,
  e2eTargetName
}: NavigationMenuHeaderProps) => {
  return (
    <h3
      e2e-target="navigation-menu-header"
      e2e-target-name={e2eTargetName}
      className={cx('navigation-menu-header')}
    >
      {useFormatMessage(label)}
    </h3>
  )
}
