import { Estimation } from '../../../../../../../../../../../../../../../../services'

type Item = {
  name: string
  value: string
}

export const dieCutsHelper = (estimation: Estimation): Item[] => {
  const items: Item[] = []
  if (!estimation.info?.die_cuts || !Array.isArray(estimation.info.die_cuts)) {
    return items
  }

  estimation.info.die_cuts.forEach((dieCut) => {
    const elements = `${dieCut.elements} ${
      dieCut.elements > 1 ? 'elements' : 'element'
    }`
    const name = dieCut.name
      ? dieCut.name
      : `${dieCut.height?.value} x ${dieCut.width?.value} ${dieCut.width?.unit}`

    items.push({
      name: 'Die cut',
      value: `${name} (${elements})`
    })
  })

  return items
}
