import React from 'react'
import { Icon, TIcon, TType, Typography } from '@packhelp/platform-dsl/atoms'
import styles from './Warnings.module.scss'

type Props = {
  warnings: string[]
}

const Warnings: React.FC<Props> = ({ warnings }) => {
  return (
    <div className={styles.warnings}>
      {warnings.map((warning) => (
        <div className={styles.box} key={warning}>
          <Icon icon={TIcon.Warning} />

          <Typography type={TType.Regular14_20}>{warning}</Typography>
        </div>
      ))}
    </div>
  )
}

export default Warnings
