import React from 'react'
import { Card, CardPadding } from '@packhelp/platform-dsl/atoms'
import { observer } from 'mobx-react'
import { Wrapper } from '../../layout'
import { StringToSvg } from '../../string-to-svg/StringToSvg'
import { UrlToSvg } from '../../url-to-svg/UrlToSvg'
import { Header } from './components/header/Header'
import styles from './DielineView.module.scss'
import { Details } from './components/details/Details'
import { useData } from '../generate-diecut-view/hooks/data/data'
import { LoadingContent } from '../../loading-content/LoadingContent'

export const DielineView = observer(() => {
  const { dieline } = useData()

  if (!dieline) {
    return <LoadingContent />
  }

  return (
    <div
      className={styles['dieline']}
      e2e-target="dieline"
      e2e-target-name={`dieline-${dieline.uuid}`}
    >
      <Wrapper>
        <Header dieline={dieline} />
        <Card border={true} padding={CardPadding.medium}>
          <div className={styles['dieline__content']}>
            <div className={styles['dieline__svg']}>
              {dieline.svg &&
                (dieline.createdAt ? (
                  <UrlToSvg
                    url={dieline.svg}
                    e2eTargetName={`dieline-${dieline.uuid}`}
                  />
                ) : (
                  <StringToSvg
                    svgString={dieline.svg}
                    e2eTargetName={`dieline-${dieline.uuid}`}
                  />
                ))}
            </div>
            <Details dieline={dieline} />
          </div>
        </Card>
      </Wrapper>
    </div>
  )
})
