import React from 'react'
import cxBinder from 'classnames/bind'
import styles from './Param.module.scss'
import { ParamProps } from './types'

const cx = cxBinder.bind(styles)

export const Param = ({ label, children }: ParamProps) => {
  return (
    <div className={cx('param')}>
      <label>{label}</label>
      {children}
    </div>
  )
}
