import React from 'react'
import { observer } from 'mobx-react'
import { Card, CardPadding, CardHeight } from '@packhelp/platform-dsl/atoms'
import { Heading, HeadingSize } from '../../../../layout'
import { FormatMessage } from '../../../../../libs/localisation/LocaleIntlProvider'
import { SheetSizeForm, KnfieParametersForm } from './SecondaryParametersForms'
import { SecondaryParametersProps } from './types'
import translations from './translations'

export const SecondaryParameters = observer(
  ({ diecut }: SecondaryParametersProps) => {
    return (
      <Card border={true} padding={CardPadding.medium} height={CardHeight.auto}>
        <Heading size={HeadingSize.h2}>
          <FormatMessage id={translations.header} />
        </Heading>
        <SheetSizeForm diecut={diecut} />
        <Heading size={HeadingSize.h2}>
          <FormatMessage id={translations.headerSecond} />
        </Heading>
        <KnfieParametersForm diecut={diecut} />
      </Card>
    )
  }
)
