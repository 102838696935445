import axios, { AxiosError } from 'axios'
import {
  UserInfo,
  LoginResult,
  UserCredentials,
  UserRegisterRequest,
  AuthenticationError,
  UserRegistrationError,
  UserConfirmationError,
  VmaFile,
  GetProductsResponse,
  CreateProductConfigRequest,
  CreateProductConfigResponse,
  GetProductResponse
} from './types'

export const VmaApiBaseUrl = `//${process.env.REACT_APP_VMA_HOST}/api`
export const VmaApiEndpoints = {
  authenticate: 'me',
  login: 'auth/login',
  register: 'auth/register',
  confirm: 'auth/confirm',
  setPassword: 'auth/set-password',
  resetPassword: 'auth/reset_password',
  changePassword: 'users/{uuid}/change-password',
  updateProfile: 'users/{uuid}/update-profile',
  productTemplate: 'product-template',
  dielines: 'product-templates/dielines',
  dieline: 'product-templates/dieline',
  diecuts: 'product-templates/diecuts',
  diecut: 'product-templates/diecut',
  files: 'files'
}
export const VmaApiRequester = 'PACKAGING_TOOLBOX'

export class VmaApi {
  private static _api = axios.create({
    baseURL: VmaApiBaseUrl
  })
  private handleApiError = (error: AxiosError): never => {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message)
    }
    throw error
  }

  public authenticate = async (token: string): Promise<UserInfo> => {
    try {
      const { data } = await VmaApi._api.get(VmaApiEndpoints.authenticate, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (!data) throw new AuthenticationError()
      VmaApi._api.defaults.headers.common['Authorization'] = `Bearer ${token}`
      return data
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public login = async (credentials: UserCredentials): Promise<LoginResult> => {
    try {
      const response = await VmaApi._api.post(
        VmaApiEndpoints.login,
        credentials
      )
      if (!response || !response.data || !response.data.data) {
        throw new AuthenticationError()
      }
      return response.data.data
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public register = async (request: UserRegisterRequest): Promise<void> => {
    try {
      const response = await VmaApi._api.post(VmaApiEndpoints.register, {
        ...request,
        requester: VmaApiRequester
      })
      if (response.status !== 201) throw new UserRegistrationError()
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public confirm = async ({
    uuid,
    token
  }: {
    uuid: string
    token: string
  }): Promise<void> => {
    try {
      const response = await VmaApi._api.post(
        `${VmaApiEndpoints.confirm}/${uuid}`,
        {
          token
        }
      )
      if (response.status !== 201) throw new UserConfirmationError()
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public resetPassword = async ({
    email
  }: {
    email: string
  }): Promise<void> => {
    try {
      const response = await VmaApi._api.post(VmaApiEndpoints.resetPassword, {
        email
      })
      if (!response || response.status !== 200) {
        throw new Error('Reset password failed')
      }
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public setPassword = async ({
    code,
    email,
    password,
    confirmPassword
  }: {
    code: string
    email: string
    password: string
    confirmPassword: string
  }): Promise<void> => {
    try {
      const response = await VmaApi._api.post(
        `${VmaApiEndpoints.resetPassword}/${code}`,
        {
          email,
          password,
          password_confirmation: confirmPassword
        }
      )
      if (!response || response.status !== 200) {
        throw new Error('Reset password failed')
      }
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public changePassword = async ({
    uuid,
    oldPassword,
    password,
    confirmPassword
  }: {
    uuid: string
    oldPassword: string
    password: string
    confirmPassword: string
  }): Promise<void> => {
    try {
      const response = await VmaApi._api.put(
        VmaApiEndpoints.changePassword.replace('{uuid}', uuid),
        {
          password,
          old_password: oldPassword,
          password_confirmation: confirmPassword
        }
      )
      if (!response || response.status !== 200) {
        throw new Error('Change password failed')
      }
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public updateProfile = async ({
    uuid,
    firstName,
    lastName,
    email,
    phoneNumber,
    companyRole
  }: {
    uuid: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    companyRole: string
  }): Promise<void> => {
    try {
      const response = await VmaApi._api.put(
        VmaApiEndpoints.updateProfile.replace('{uuid}', uuid),
        {
          firstname: firstName || 'USER_FIRST_NAME',
          lastname: lastName || 'USER_LAST_NAME',
          email,
          phone_number: phoneNumber,
          company_role: companyRole
        }
      )
      if (!response || response.status !== 200) {
        throw new Error('Profile update failed')
      }
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public createProductTemplate = async (
    productTemplate: CreateProductConfigRequest
  ): Promise<CreateProductConfigResponse> => {
    try {
      const response = await VmaApi._api.post(
        VmaApiEndpoints.productTemplate,
        productTemplate
      )
      if (response.status !== 201) throw new UserConfirmationError()
      return response.data.data
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public createFile = async (
    file: string,
    mimeType: string
  ): Promise<VmaFile> => {
    const data = new FormData()
    data.append('action', 'ADD')
    data.append('file', new Blob([file], { type: mimeType }))

    try {
      const response = await VmaApi._api.post(VmaApiEndpoints.files, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (response.status !== 201) throw new UserConfirmationError()
      return response.data.data
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public getDielines = async (
    page?: number,
    per_page = 30
  ): Promise<GetProductsResponse> => {
    try {
      const response = await VmaApi._api.get(VmaApiEndpoints.dielines, {
        params: { page, per_page }
      })
      return response.data
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public getDieline = async (uuid: string): Promise<GetProductResponse> => {
    try {
      const response = await VmaApi._api.get(
        `${VmaApiEndpoints.dieline}/${uuid}`
      )
      return response.data
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public getDiecuts = async (
    page?: number,
    per_page = 30
  ): Promise<GetProductsResponse> => {
    try {
      const response = await VmaApi._api.get(VmaApiEndpoints.diecuts, {
        params: { page, per_page }
      })
      return response.data
    } catch (e) {
      return this.handleApiError(e)
    }
  }

  public getDiecut = async (uuid: string): Promise<GetProductResponse> => {
    try {
      const response = await VmaApi._api.get(
        `${VmaApiEndpoints.diecut}/${uuid}`
      )
      return response.data
    } catch (e) {
      return this.handleApiError(e)
    }
  }
}
