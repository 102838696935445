import translations from './translations'

export const maximumNumberOfElements = 48

export const preparePdfFileName = (label: string) => {
  const formattedLabel = label.replace(/ /g, '_')

  return `layout_${formattedLabel}.pdf`
}

export const validateKnifeLength = (knifeLength: number | null, calculatedKnifeLength: number | null) => {
  if (!knifeLength || !calculatedKnifeLength) return null
  
  if (calculatedKnifeLength > knifeLength) {
    return translations['knifeValidationError']
  } else {
    return null
  }
}

export const calculateTotalKnifePrice = (price: number | null, length: number | null) => {
  if (!price || !length) return null

  return Math.round((price * length))
}

export const calculateKnifeLength = (cut_length: number | undefined, crease_length: number | undefined) => {
  if (!cut_length || !crease_length) return null

  return Math.round((cut_length + crease_length) / 1000)
}

export const validateNumberOfElements = (noOfUnits: number) => {
  if (noOfUnits >= maximumNumberOfElements) {
    return translations['numberOfElementsError']
  } else {
    return null
  }
}
