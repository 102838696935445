import React, { createContext, useContext, ReactNode } from 'react'

const initialQuery = new URLSearchParams(window.location.search)

const SearchParamsContext = createContext(initialQuery)

export const useSearchParams = () => {
  const initialQuery = useContext(SearchParamsContext)
  const query = new URLSearchParams(window.location.search)

  return {
    initialQuery,
    query
  }
}

export const SearchParamsProvider = ({ children }: { children: ReactNode }) => (
  <SearchParamsContext.Provider value={initialQuery}>
    {children}
  </SearchParamsContext.Provider>
)
