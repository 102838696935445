import React from 'react'
import { TType, Typography } from '@packhelp/platform-dsl/atoms'
import { PackingPallets } from '../../../../../../../../../../../../../../../../../../../services'
import { PalletsCost } from '../palletsCost'

type Props = {
  name: string
  pallets: PackingPallets[]
  transitTimes: string | null
  deliveries?: {
    carrier?: string
    number_of_pallets?: number[]
  }
}

const PalletsService = ({ name, pallets, transitTimes, deliveries }: Props) => {
  return (
    <div>
      <Typography type={TType.Regular12_16}>
        {transitTimes ? `${name} (${transitTimes} days)` : name}
      </Typography>

      {pallets.map((palletsCost, index) => (
        <PalletsCost
          {...palletsCost}
          deliveries={deliveries}
          key={`${index}__${palletsCost.type}__${palletsCost.quantity}`}
        />
      ))}
    </div>
  )
}

export default PalletsService
