import React from 'react'
import { RotatedImage } from './components/rotated-image/RotatedImage'
import { RotatableImageProps } from './types'

export const RotatableImage = ({
  shouldRotate = false,
  src,
  alt = '',
  ...other
}: RotatableImageProps) => {
  if (shouldRotate) {
    return <RotatedImage src={src} alt={alt} {...other} />
  }

  return <img src={src} alt={alt} {...other} />
}
