import { FormEvent } from 'react'
import { useScrollTop } from '../../../../../hooks/scroll-top/scroll-top'
import { DiecutStore } from '../../../../../stores/diecut-store/diecut-store'
import { handleError } from '../../../../../utils/handle-error/handle-error'
import { snackbar } from '../../../../../utils/snackbar/snackbar'
import { ActionsHookReturnType } from './types'

export const useActions = (diecut?: DiecutStore): ActionsHookReturnType => {
  const scrollTop = useScrollTop()

  const generateDiecut = async (e?: FormEvent<HTMLFormElement>) => {
    e && e.preventDefault()

    if (!diecut) return

    try {
      await diecut.generate()
      snackbar.success('New diecut generated')
      scrollTop()
    } catch (error) {
      handleError(error)
    }
  }

  return {
    generateDiecut
  }
}
