// AUTOGENERATED FILE
// source: scripts/generate-product-types-templates.ts

export enum TProductTemplate {
  AutoBottom = 'AutoBottom',
  autoBottom = 'AutoBottom',
  CakeBoxAutoBottom = 'CakeBoxAutoBottom',
  cakeBoxAutoBottom = 'CakeBoxAutoBottom',
  CakeBox = 'CakeBox',
  cakeBox = 'CakeBox',
  DoubleSidewallDoubleBottom = 'DoubleSidewallDoubleBottom',
  doubleSidewallDoubleBottom = 'DoubleSidewallDoubleBottom',
  DoubleSidewall = 'DoubleSidewall',
  doubleSidewall = 'DoubleSidewall',
  Doypack = 'Doypack',
  doypack = 'Doypack',
  DrawerB = 'DrawerB',
  drawerB = 'DrawerB',
  Drawer = 'Drawer',
  drawer = 'Drawer',
  Envelope = 'Envelope',
  envelope = 'Envelope',
  Fefco201 = 'Fefco201',
  fefco201 = 'Fefco201',
  Fefco215 = 'Fefco215',
  fefco215 = 'Fefco215',
  Fefco217 = 'Fefco217',
  fefco217 = 'Fefco217',
  Fefco426 = 'Fefco426',
  fefco426 = 'Fefco426',
  Fefco427 = 'Fefco427',
  fefco427 = 'Fefco427',
  Fefco701 = 'Fefco701',
  fefco701 = 'Fefco701',
  Fefco703 = 'Fefco703',
  fefco703 = 'Fefco703',
  Fefco711 = 'Fefco711',
  fefco711 = 'Fefco711',
  Fefco712 = 'Fefco712',
  fefco712 = 'Fefco712',
  Fefco713 = 'Fefco713',
  fefco713 = 'Fefco713',
  FoodBoxFlexo = 'FoodBoxFlexo',
  foodBoxFlexo = 'FoodBoxFlexo',
  Magnetic = 'Magnetic',
  magnetic = 'Magnetic',
  PillowBox = 'PillowBox',
  pillowBox = 'PillowBox',
  ReverseTuckEnd = 'ReverseTuckEnd',
  reverseTuckEnd = 'ReverseTuckEnd',
  RigidBox = 'RigidBox',
  rigidBox = 'RigidBox',
  SeparatorSingleTop = 'SeparatorSingleTop',
  separatorSingleTop = 'SeparatorSingleTop',
  Sleeve = 'Sleeve',
  sleeve = 'Sleeve',
  StraightTuckEnd = 'StraightTuckEnd',
  straightTuckEnd = 'StraightTuckEnd',
  TuckTopSnapLockBottom = 'TuckTopSnapLockBottom',
  tuckTopSnapLockBottom = 'TuckTopSnapLockBottom',
  TwoPiecesTrayBox = 'TwoPiecesTrayBox',
  twoPiecesTrayBox = 'TwoPiecesTrayBox',
  Yope = 'Yope',
  yope = 'Yope'
}
