import { PaginationMeta } from '../services/vma-api'

export enum StoreDataState {
  pristine = 'pristine',
  ok = 'ok',
  loading = 'loading',
  error = 'error'
}

export type StoreDataCore<T> = { state: StoreDataState; data: T }

export interface StoreDataPristine<T> extends StoreDataCore<T> {
  state: StoreDataState.pristine
}
export interface StoreDataOk<T> extends StoreDataCore<T> {
  state: StoreDataState.ok
}
export interface StoreDataLoading<T> extends StoreDataCore<T> {
  state: StoreDataState.loading
}

export interface StoreDataError<T> extends StoreDataCore<T> {
  state: StoreDataState.error
  error: string
}
export type StoreData<T = null> =
  | StoreDataPristine<T>
  | StoreDataOk<T>
  | StoreDataError<T>
  | StoreDataLoading<T>

export interface PaginatedStoreDataOk<T> extends StoreDataOk<T> {
  meta: PaginationMeta
}
export interface PaginatedStoreDataError<T> extends StoreDataError<T> {
  meta?: PaginationMeta
}

export type PaginatedStoreData<T = null> =
  | StoreDataPristine<T>
  | PaginatedStoreDataOk<T>
  | PaginatedStoreDataError<T>
  | StoreDataLoading<T>
