import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { NumberField } from '../../../../form'
import { NumberOptionStore } from '../../../../../stores'
import { DiecutStore } from '../../../../../stores/diecut-store/diecut-store'
import { MultiSelectField } from '../../../../form/multiselect-field/MultiSelectField'
import { ContainersSizes } from '../../../../../services/diecuts-service/data/containers-sizes'
import { DiecutContainerConfig } from '../../../../../services'
import { ListProps } from '../../../../form/multiselect-field/types'
import translations from './translations'
import { useTranslations } from './../../../../../libs/localisation/LocaleIntlProvider'

interface SharedFormProps {
  diecut: DiecutStore
}

export const SheetSizeForm = observer(({ diecut }: SharedFormProps) => {
  const i18n = useTranslations(translations)
  const [checkedData, setCheckedData] = useState<string[]>([])
  const [useCustomSheetSize, setUseCustomSheetSize] = useState<boolean>(false)

  React.useEffect(() => {
    const selectedSheetSizes = checkedData.map(
      (option): DiecutContainerConfig => {
        const dimension = [...option.matchAll(/\s+(\d+)mm/g)].map(
          (match) => match[1]
        )
        return {
          width: parseInt(dimension[0]),
          height: parseInt(dimension[1])
        }
      }
    )
    diecut.setSelectedSheetSizes(selectedSheetSizes)
  }, [checkedData])

  React.useEffect(() => {
    if (checkedData.some((item) => item.includes('custom'))) {
      setUseCustomSheetSize(true)
    } else {
      setUseCustomSheetSize(false)

      // clear custom sheet sizes
      diecut.options.forEach(
        (option: NumberOptionStore) => option.setValue(null)
      )
    }
  }, [checkedData])

  if (!diecut) return null

  const containersSizesOptions: ListProps[] = Object.entries(
    ContainersSizes
  ).map((option) => {
    return {
      label: option[1],
      value: option[0]
    }
  })
  containersSizesOptions.push({
    label: i18n.predefinedSheetSizeCustomOption,
    value: 'custom'
  })

  const onPreferredSheetSizeChange = (value: string) => {
    if (useCustomSheetSize && !value.includes('custom')) {
      return
    } else if (
      value.includes('custom') &&
      !checkedData.some((item) => item.includes('custom')) &&
      checkedData.length > 0
    ) {
      // clear other choices if user select custom
      setCheckedData([])
    }

    if (checkedData.includes(value)) {
      const index = checkedData.indexOf(value)
      if (index > -1) {
        const tmpCheckedData = [...checkedData]
        tmpCheckedData.splice(index, 1)
        setCheckedData(tmpCheckedData)
      }
    } else {
      setCheckedData((oldCheckedData) => [...oldCheckedData, value])
    }
  }

  return (
    <>
      <MultiSelectField
        label={i18n.predefinedSheetSizeTitle}
        name="preferred_sheet_sizes"
        listData={containersSizesOptions}
        checkedData={checkedData}
        onChange={onPreferredSheetSizeChange}
        placeholder={i18n.predefinedSheetSizePlaceholder}
        useStartCase={false}
      />

      {useCustomSheetSize &&
        diecut.options.map((option: NumberOptionStore) => (
          <NumberField
            key={option.name}
            name={option.name}
            value={option.value}
            label={option.label}
            error={option.error}
            onChange={option.setValue}
            placeholder={'custom'}
            units="mm"
          />
        ))}
    </>
  )
})

export const KnfieParametersForm = ({ diecut }: SharedFormProps) => {
  const i18n = useTranslations(translations)

  if (!diecut) return null

  return (
    <>
      {diecut.knifeParams.map((option: NumberOptionStore) => {
        const traslationsKey = i18n[option.label as keyof typeof i18n]
        return (
          <NumberField
            key={option.name}
            name={option.name}
            value={option.value}
            label={traslationsKey}
            error={option.error}
            onChange={option.setValue}
            placeholder={''}
            units={option.name === 'knifePrice' ? 'pln' : 'm'}
          />
        )
      })}
    </>
  )
}
