import React, { useState } from 'react'
import { Icon, TIcon, TType, Typography } from '@packhelp/platform-dsl/atoms'
import { Estimation } from '../../../../../../../../../../../../../../../services'
import styles from './Technology.module.scss'
import { printSheetHelper } from './helpers/printSheetHelper'
import { dieCutsHelper } from './helpers/dieCutsHelper'

type Props = {
  estimation: Estimation
}

const Table = ({
  columns,
  rows
}: {
  columns: string[]
  rows: Array<string[]>
}) => {
  return (
    <div className={styles.table}>
      <div className={styles.table__row}>
        {columns.map((column) => (
          <div className={styles['table__row--header']} key={column}>
            <Typography type={TType.Regular12_16}>{column}</Typography>
          </div>
        ))}
      </div>

      {rows.map((rowColumns, index) => (
        <div
          className={styles.table__row}
          key={`${index}__${rowColumns.length}`}
        >
          {rowColumns.map((cellValue) => (
            <div className={styles['table__row--cell']} key={cellValue}>
              <Typography type={TType.Regular12_16}>{cellValue}</Typography>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const Technology: React.FC<Props> = ({ estimation }) => {
  const [showMaterials, setShowMaterials] = useState(false)
  const [showOperations, setShowOperations] = useState(false)

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Typography type={TType.Medium12_18}>Technology</Typography>
      </div>

      <div className={styles.content}>
        <ul className={styles.content__table}>
          <li>
            <Typography type={TType.Regular12_16}>Print sheet</Typography>
            <Typography type={TType.Regular12_16}>
              {printSheetHelper(estimation)}
            </Typography>
          </li>

          {dieCutsHelper(estimation).map(({ name, value }) => (
            <li key={`${name}__${value}`}>
              <Typography type={TType.Regular12_16}>{name}</Typography>
              <Typography type={TType.Regular12_16}>{value}</Typography>
            </li>
          ))}
        </ul>

        <div className={styles.collapsingTable}>
          <Typography
            type={TType.Regular12_16}
            className={styles.collapsingTable__trigger}
            onClick={() => setShowMaterials((s) => !s)}
          >
            Material
            <Icon
              icon={showMaterials ? TIcon.ArrowsType1Up : TIcon.ArrowsType1Down}
            />
          </Typography>

          {showMaterials && (
            <Table
              columns={['Name', 'Total m²', 'Price / m²', 'Total cost']}
              rows={(estimation.technology?.materials || []).map((material) => [
                material.name || 'n/a',
                material.total_area ? String(material.total_area) : 'n/a',
                material.total_area
                  ? `${(
                      parseFloat(material.cost.amount) / material.total_area
                    ).toFixed(2)} ${material.cost.currency}`
                  : 'n/a',
                `${material.cost.amount} ${material.cost.currency}`
              ])}
            />
          )}
        </div>

        <div className={styles.collapsingTable}>
          <Typography
            type={TType.Regular12_16}
            className={styles.collapsingTable__trigger}
            onClick={() => setShowOperations((s) => !s)}
          >
            Operations
            <Icon
              icon={
                showOperations ? TIcon.ArrowsType1Up : TIcon.ArrowsType1Down
              }
            />
          </Typography>

          {showOperations && (
            <Table
              columns={['Name', 'Quantity', 'Unit price', 'Total cost']}
              rows={(estimation.technology?.operations || []).map(
                (operation) => [
                  operation.name || 'n/a',
                  operation.quantity ? String(operation.quantity) : 'n/a',
                  operation.quantity
                    ? `${(
                        parseFloat(operation.cost.amount) / operation.quantity
                      ).toFixed(2)} ${operation.cost.currency}`
                    : 'n/a',
                  `${operation.cost.amount} ${operation.cost.currency}`
                ]
              )}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Technology
