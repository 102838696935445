import React from 'react'
import { Loading, LoadingSize } from '@packhelp/platform-dsl/atoms'
import { Content } from '../../layout/components'
import styles from './LoadingView.module.scss'

export const LoadingView = React.memo(() => {
  return (
    <Content>
      <div className={styles.wrapper}>
        <Loading size={LoadingSize.xl} />
      </div>
    </Content>
  )
})
