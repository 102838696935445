import React, { useState } from 'react'
import { TType, Typography, ToggleSelect } from '@packhelp/platform-dsl/atoms'
import { Estimation } from '../../../../../../../../../../../../../../../services'
import styles from './Shipping.module.scss'
import { usePacking } from './hooks/packing'
import { ShippingType } from './types'
import { NoShippingCosts } from './components/noShippingCosts'
import { Pallets } from './components/pallets'
import { Packages } from './components/packages'

type Props = {
  estimation: Estimation
}

const Shipping: React.FC<Props> = ({ estimation }) => {
  const { hasPallets, hasPackages, initialType } = usePacking(estimation)
  const [shippingType, setShippingType] = useState(initialType)

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Typography type={TType.Medium12_18}>Shipping</Typography>
        {shippingType && hasPallets && hasPackages && (
          <div className={styles.header__switch}>
            <ToggleSelect<ShippingType>
              value={shippingType}
              data={[
                { label: 'Pallets', value: ShippingType.Pallets },
                { label: 'Packages', value: ShippingType.Packages }
              ]}
              onChange={(v) => setShippingType(v)}
            />
          </div>
        )}
      </div>

      <div className={styles.content}>
        {shippingType === null && (
          <NoShippingCosts
            totalWeight={estimation.item.weight?.amount}
            weightUnit={estimation.item.weight?.unit}
          />
        )}

        {shippingType === ShippingType.Pallets && (
          <Pallets pallets={estimation.packing.pallets} />
        )}

        {shippingType === ShippingType.Packages && (
          <Packages boxes={estimation.packing.boxes} />
        )}
      </div>
    </div>
  )
}

export default Shipping
