export default {
  downloadButton:
    'components.views.generate-diecut-view.diecut-tile.download-button',
  knifeValidationError:
    'components.views.generate-diecut-view.diecut-tile.knife-validation-error',
  numberOfElementsError: "components.views.generate-diecut-view.diecut-tile.no-of-elements-error",
  sheetSize: 'components.views.generate-diecut-view.diecut-tile.sheet-size',
  noOfElements:
    'components.views.generate-diecut-view.diecut-tile.no-of-elements',
  knifeLength: 'components.views.generate-diecut-view.diecut-tile.knife-length',
  dieline: 'components.views.generate-diecut-view.diecut-tile.dieline',
  dimensions: 'components.views.generate-diecut-view.diecut-tile.dimensions',
  diecutCost: 'components.views.generate-diecut-view.diecut-tile.diecut-cost'
}
