import React from 'react'
import cxBinder from 'classnames/bind'
import { observer } from 'mobx-react'
import { Button, ButtonSize } from '@packhelp/platform-dsl/atoms'
import { Separator } from '../../../../layout'
import styles from './Actions.module.scss'
import { ActionsProps } from './types'
import { FormatMessage } from './../../../../../libs/localisation/LocaleIntlProvider'
import translations from './translations'

const cx = cxBinder.bind(styles)

export const Actions = observer(({ dieline }: ActionsProps) => {
  return (
    <div className={cx('actions')}>
      <Separator />
      <Button
        type="submit"
        size={ButtonSize.large}
        disabled={!dieline.isFilled}
        isLoading={dieline.isLoading}
      >
        <FormatMessage id={translations.buttonGenerate} />
      </Button>
    </div>
  )
})
