import {
  parsePhoneNumberFromString,
  PhoneNumber,
  getCountryCallingCode,
  CountryCode,
  CountryCallingCode
} from 'libphonenumber-js/max'

import metadata from 'libphonenumber-js/metadata.min.json'
import { map, compact } from 'lodash'

export class PhoneNumberHelper {
  static isValidPhoneNumber(phoneNumber: string): boolean {
    const parsedPhoneNumber:
      | PhoneNumber
      | undefined = PhoneNumberHelper.phoneNumberDescription(phoneNumber)
    return (parsedPhoneNumber && parsedPhoneNumber.isValid()) || false
  }
  static phoneNumberDescription(phoneNumber: string): PhoneNumber | undefined {
    return parsePhoneNumberFromString(phoneNumber)
  }

  static getInternationalPhoneNumberPrefixes(): number[] {
    return PhoneNumberHelper.getPhoneNumberPrefixesWithFlags().map(
      (code: { id: string; name: string; flag: string }) => parseInt(code.name)
    )
  }

  static getPhoneNumberPrefixesWithCountryCodes(): {
    prefix: number
    countryCode: string
  }[] {
    return compact(
      map(metadata.country_calling_codes, (codes: string[], prefix: string) => {
        const countryCode = codes[0].toLowerCase()
        if (countryCode.length < 3) {
          return {
            prefix: parseInt(prefix, 10),
            countryCode
          }
        }
      })
    )
  }

  static transformToPhoneNumber(
    phoneNumber: string,
    noTransformBreak = false
  ): string | undefined {
    if (noTransformBreak && phoneNumber.match(/^\+?(\d)+$/)) return

    const phoneTransformed = phoneNumber.trim().replace(/[^+0-9]/g, '')
    return `${phoneTransformed.substr(0, 1)}${phoneTransformed
      .substr(1)
      .replace(/\+/g, '')}`
  }

  static getPhonePrefixByLocale(
    locale: CountryCode | string
  ): CountryCallingCode | string {
    try {
      return getCountryCallingCode(<CountryCode>locale.toUpperCase())
    } catch (err) {
      return '48'
    }
  }

  static getPhoneNumberPrefixesWithFlags = (): {
    id: string
    name: string
    flag: string
  }[] => {
    const prefixes = PhoneNumberHelper.getPhoneNumberPrefixesWithCountryCodes().map(
      (codeInfo) => {
        const flag = `/img/images/all_countries/${codeInfo.countryCode.toLowerCase()}.svg`

        return { id: codeInfo.countryCode, name: codeInfo.prefix, flag }
      }
    )

    prefixes.sort((a, b) => <any>a.name - <any>b.name)

    return prefixes.map((prefixObj) => {
      return { ...prefixObj, name: `+${prefixObj.name}` }
    })
  }
}
