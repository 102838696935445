import { ReactNode } from 'react'

export enum ContentPadding {
  none = 'none',
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export interface ContentProps {
  children?: ReactNode
  e2eTargetName?: string
  padding?: ContentPadding
}
