import React from 'react'
import { TProductTemplate } from './components'

export enum TProductDimension {
  depth = 'depth',
  width = 'width',
  length = 'length',
  height = 'height'
}

export interface ProductTemplateProps extends React.SVGProps<SVGSVGElement> {
  product: TProductTemplate
  showDimensions?: boolean
  highlightDimension?: TProductDimension
  e2eTargetName?: string
}
