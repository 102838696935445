import React from 'react'
import { ErrorsState, ValuesState } from '../../../hooks/form-validation'
import { UserCredentials } from '../../../services/vma-api'

export const login = (
  values: ValuesState<'email' | 'password'>,
  errors: ErrorsState<'email' | 'password'>,
  loginAction: (credentials: UserCredentials) => void
) => async (event: React.FormEvent): Promise<void> => {
  event.preventDefault()
  if (values.email && values.password && Object.keys(errors).length === 0) {
    await loginAction({
      email: values.email as string,
      password: values.password as string
    })
  }
}
