import React, { useEffect, useMemo } from 'react'
import {
  ColumnAlign,
  PaddingType,
  TableConfig
} from '@packhelp/platform-dsl/components/Table'
import { observer } from 'mobx-react'
import { Icon, LinkElement, TIcon } from '@packhelp/platform-dsl/atoms'
import mapValues from 'lodash/mapValues'
import { useRootStore } from '../../../hooks'
import { NumberOptionStore } from '../../../stores'
import { LoadingView } from '../loading-view/LoadingView'
import { ListView } from '..'
import { Pagination } from '../list-view/types'
import { useFormatMessage } from '../../../libs/localisation/LocaleIntlProvider'
import { UrlToSvg } from '../../url-to-svg/UrlToSvg'
import { RotatableImage } from '../../rotatable-image/RotatableImage'
import styles from './MyDiecutsView.module.scss'
import translations from './translations'

export const MyDiecutsView = observer(() => {
  const { diecutsStore } = useRootStore()
  useEffect(() => {
    !diecutsStore.isInitialized &&
      !diecutsStore.isLoading &&
      diecutsStore.getDiecuts()
  }, [])

  const messages = mapValues(translations, useFormatMessage)

  const config: TableConfig = {
    idAccessor: 'my-diecuts',
    columns: [
      {
        uuid: 'product_type',
        label: messages.diecutId,
        cellRenderer: (data) => {
          return (
            <>
              <div className={styles['diecut-preview']}>
                {data.thumbnail && data.thumbnail.endsWith('svg') ? (
                  <UrlToSvg
                    shouldRotate={data.isVertical}
                    url={data.thumbnail}
                    e2eTargetName="diecut-thumbnail"
                  />
                ) : (
                  <RotatableImage
                    shouldRotate={data.isVertical}
                    src={data.thumbnail}
                    alt={data.format}
                  />
                )}
              </div>
              <div className={styles['diecut-label']}>
                <LinkElement to={`/tools/diecuts/my-diecuts/${data.uuid}`}>
                  {data.dieline?.label}
                </LinkElement>
              </div>
            </>
          )
        },
        align: ColumnAlign.left,
        maxWidth: 300
      },
      {
        uuid: 'format',
        label: messages.format,
        side: 'right'
      },
      {
        uuid: 'noOfUnits',
        label: messages.noOfUnits,
        side: 'right'
      },
      {
        uuid: 'date_created',
        label: messages.dateCreated,
        side: 'right'
      },
      {
        uuid: 'file',
        cellRenderer: (data) => (
          <a
            {...data}
            rel="enclosure"
            className={styles['download-file']}
            target="_blank"
          >
            <Icon icon={TIcon.Download} />
          </a>
        ),
        paddingType: PaddingType.l,
        side: 'right'
      }
    ]
  }

  const data = useMemo(
    () =>
      diecutsStore.diecuts.map((diecut) => ({
        product_type: diecut,
        size: diecut.options
          .map((dimension: NumberOptionStore) => dimension.value)
          .join('x')
          .concat(' mm'),
        date_created: diecut.createdAt,
        noOfUnits: diecut.noOfUnits,
        format: diecut.format,
        file: { href: diecut.pdf, download: `${diecut.dieline.label}.pdf` },
        name: diecut.dieline?.label
      })),
    [diecutsStore.diecuts]
  )

  if (!diecutsStore.isInitialized || diecutsStore.isLoading)
    return <LoadingView />

  return (
    <ListView
      title={messages.header}
      e2eTargetName="my-diecuts"
      config={config}
      data={data}
      pagination={diecutsStore.pagination as Pagination}
    />
  )
})
