import cogoToast from 'cogo-toast'
import { Snackbar, TToastOptions } from './types'
import './snackbar.module.scss'

const toastOptions: TToastOptions = {
  position: 'bottom-right',
  hideAfter: 5,
  bar: { size: '6px' }
}

export const snackbar: Snackbar = {
  error: (message: string) => {
    cogoToast.error(message, toastOptions)
  },
  success: (message: string) => {
    cogoToast.success(message, toastOptions)
  },
  info: (message: string) => {
    cogoToast.info(message, toastOptions)
  },
  warn: (message: string) => {
    cogoToast.warn(message, toastOptions)
  }
}
