import React, { useState } from 'react'
import { Icon, TIcon, TType, Typography } from '@packhelp/platform-dsl/atoms'
import { PackingBoxes } from '../../../../../../../../../../../../../../../../../../../services'
import styles from './PackagesCost.module.scss'

const PackagesCost: React.FC<PackingBoxes> = ({
  quantity,
  cost,
  size,
  products_per_box,
  product_weight
}) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <div>
      <ul className={styles.table}>
        <li>
          <Typography
            type={TType.Regular12_16}
            className={styles.table__link}
            onClick={() => setCollapsed((s) => !s)}
          >
            Packages: {quantity}
            <Icon
              icon={collapsed ? TIcon.ArrowsType1Up : TIcon.ArrowsType1Down}
            />
          </Typography>
          <Typography type={TType.Regular12_16}>
            {cost ? `${cost.amount} ${cost.currency}` : null}
          </Typography>
        </li>
      </ul>

      {collapsed && (
        <ul className={styles.table}>
          <li>
            <Typography type={TType.Regular12_16}>Package size</Typography>
            <Typography type={TType.Regular12_16}>
              {size.height.value} x {size.width.value} x {size.depth.value}{' '}
              {size.depth.unit}
            </Typography>
          </li>

          <li>
            <Typography type={TType.Regular12_16}>Total weight</Typography>
            <Typography type={TType.Regular12_16}>
              {product_weight
                ? `${product_weight.amount} ${product_weight.unit}`
                : 'n/a'}
            </Typography>
          </li>

          <li>
            <Typography type={TType.Regular12_16}>Products per box</Typography>
            <Typography type={TType.Regular12_16}>
              {products_per_box}
            </Typography>
          </li>
        </ul>
      )}
    </div>
  )
}

export default PackagesCost
