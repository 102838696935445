import React, { useState } from 'react'
import { Icon, TIcon, TType, Typography } from '@packhelp/platform-dsl/atoms'
import { PackingPallets } from '../../../../../../../../../../../../../../../../../../../services'
import styles from './PalletsCost.module.scss'

type Props = PackingPallets & {
  deliveries?: {
    carrier?: string
    number_of_pallets?: number[]
  }
}

const PalletsCost = ({
  quantity,
  cost,
  type,
  height_brutto,
  product_weight,
  products_per_pallet,
  deliveries
}: Props) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <div>
      <ul className={styles.table}>
        <li>
          <Typography
            type={TType.Regular12_16}
            className={styles.table__link}
            onClick={() => setCollapsed((s) => !s)}
          >
            Pallets: {quantity}
            <Icon
              icon={collapsed ? TIcon.ArrowsType1Up : TIcon.ArrowsType1Down}
            />
          </Typography>
          <Typography type={TType.Regular12_16}>
            {cost ? `${cost.amount} ${cost.currency}` : null}
          </Typography>
        </li>
      </ul>

      {collapsed && (
        <ul className={styles.table}>
          <li>
            <Typography type={TType.Regular12_16}>Pallet type/size</Typography>
            <Typography type={TType.Regular12_16}>{type}</Typography>
          </li>

          <li>
            <Typography type={TType.Regular12_16}>Stack height</Typography>
            <Typography type={TType.Regular12_16}>
              {height_brutto
                ? `${height_brutto.value} ${height_brutto.unit}`
                : 'n/a'}
            </Typography>
          </li>

          <li>
            <Typography type={TType.Regular12_16}>Total weight</Typography>
            <Typography type={TType.Regular12_16}>
              {product_weight
                ? `${product_weight.amount} ${product_weight.unit}`
                : 'n/a'}
            </Typography>
          </li>

          <li>
            <Typography type={TType.Regular12_16}>
              Products per pallet
            </Typography>
            <Typography type={TType.Regular12_16}>
              {products_per_pallet}
            </Typography>
          </li>

          {deliveries?.carrier && (
            <li>
              <Typography type={TType.Regular12_16}>Carrier</Typography>
              <Typography type={TType.Regular12_16}>
                {deliveries.carrier}
              </Typography>
            </li>
          )}

          {deliveries?.number_of_pallets && (
            <li>
              <Typography type={TType.Regular12_16}>Delivery</Typography>

              <Typography type={TType.Regular12_16}>
                {`${deliveries.number_of_pallets.length} ${
                  deliveries.number_of_pallets.length === 1
                    ? 'delivery'
                    : 'deliveries'
                }, ${deliveries.number_of_pallets.join('+')} pallets`}
              </Typography>
            </li>
          )}
        </ul>
      )}
    </div>
  )
}

export default PalletsCost
