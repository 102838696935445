export class UnableToGenerateLayoutsError extends Error {
  constructor(message = 'Unable to generate layouts') {
    super(message)
  }
}

export class UnableToGeneratePdfFile extends Error {
  constructor(message = 'Unable to generate PDF file') {
    super(message)
  }
}

export class UnableToGenerateLayoutContainersError extends Error {
  constructor(
    message = 'Unable to generate layouts with current settings, try different dimensions!'
  ) {
    super(message)
  }
}
