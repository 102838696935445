import React from 'react'
import { observer } from 'mobx-react'
import cxBinder from 'classnames/bind'
import { Loading, LoadingSize } from '@packhelp/platform-dsl/atoms'
import { useRootStore } from '../../../../../hooks'
import { ProductTile } from '../product-tile/ProductTile'
import { DielineStore } from '../../../../../stores/dieline-store/dieline-store'
import styles from './ProductTemplatesList.module.scss'

const cx = cxBinder.bind(styles)

export const ProductTemplatesList = observer(() => {
  const { dielinesStore } = useRootStore()

  if (!dielinesStore.isTemplatesInitialized) {
    return (
      <div className={cx('product-templates-list--loading')}>
        <Loading size={LoadingSize.l} />
      </div>
    )
  }

  return (
    <div
      className={cx('product-templates-list')}
      e2e-target-name="product-templates-list"
    >
      {dielinesStore.templates.map((store: DielineStore) => (
        <ProductTile key={store.type} store={store} />
      ))}
    </div>
  )
})
