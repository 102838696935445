import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import styles from './StringToSvg.module.scss'
import { StringToSvgProps } from './types'

export const StringToSvg = observer(
  ({ svgString, e2eTargetName, shouldRotate }: StringToSvgProps) => {
    const svgRef = useRef<SVGGElement>(null)
    const [{ viewBox, transform }, setSvgProps] = useState({
      viewBox: '',
      transform: ''
    })

    useEffect(() => {
      if (!shouldRotate || !svgRef.current) return
      const { width, height } = svgRef.current.getBoundingClientRect()
      setSvgProps({
        viewBox: `0 0 ${height} ${width}`,
        transform: `rotate(-90 ${width / 2} ${width / 2})`
      })
    }, [svgRef, shouldRotate])

    return shouldRotate ? (
      <div
        className={styles['string-to-svg']}
        e2e-target="string-to-svg"
        e2e-target-name={e2eTargetName}
      >
        <svg viewBox={viewBox}>
          <g
            ref={svgRef}
            dangerouslySetInnerHTML={{ __html: svgString }}
            transform={transform}
          />
        </svg>
      </div>
    ) : (
      <div
        className={styles['string-to-svg']}
        e2e-target="string-to-svg"
        e2e-target-name={e2eTargetName}
        dangerouslySetInnerHTML={{ __html: svgString }}
      />
    )
  }
)
