import { autorun } from 'mobx'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useRootStore } from '../../../../../../hooks'
import { DielineStore } from '../../../../../../stores'
import {
  FormTemplate,
  FormTemplatesHookReturnType,
  UseFormTemplatesTypes
} from './types'

export const useFormTemplates = ({
  route,
  forPacking = false
}: UseFormTemplatesTypes): FormTemplatesHookReturnType => {
  const { dielinesStore } = useRootStore()
  const history = useHistory()

  const [templates, setTemplates] = useState<FormTemplate[]>([])

  const onTemplateChange = (template: string) => {
    history.push(`${route}/${template}`)
  }

  useEffect(
    () =>
      autorun(() => {
        if (dielinesStore.isTemplatesInitialized) {
          const data = forPacking
            ? dielinesStore.packingAvailableTemplates
            : dielinesStore.templates
          const templates = data.map((dielineStore: DielineStore) => ({
            label: dielineStore.templateLabel,
            value: dielineStore.templateSlug
          }))
          setTemplates(templates)
        }
      }),
    []
  )

  return {
    templates,
    onTemplateChange
  }
}
