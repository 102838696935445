import { action, computed, observable } from 'mobx'
import { camelCase, capitalize, upperFirst, kebabCase, omit } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import {
  BoxesFlowApiError,
  DielineConfig,
  DielineConfigOptions,
  DielinesService,
  DimensionOptions
} from '../../services'

import { DielineOption } from '../option-store/types'
import { OptionStoreFactory } from '../../utils/option-store-factory/option-store-factory'
import { DielinesStore } from '../dielines-store/dielines-store'
import { StoreData, StoreDataState } from '../types'
import {
  BooleanOptionStore,
  NumberOptionStore,
  StringOptionStore
} from '../option-store/option-store'
import { ErrorGeneratingSVG, ErrorGeneratingPDF } from './types'

export class DielineStore {
  @observable public type = ''

  @observable private _svg: StoreData<string | undefined> = {
    state: StoreDataState.pristine,
    data: ''
  }
  @observable private _pdf: StoreData<string | undefined> = {
    state: StoreDataState.pristine,
    data: ''
  }

  @observable private _isPdfBlob = false

  @observable.ref private _options: DielineOption[] = []

  @observable private _isSaving = false

  private _uuid: string = uuidv4()
  private _createdAt: string | undefined
  private _options_custom_order: string[] = []
  private _is_packing_available: boolean | undefined = false

  constructor(
    private _dielinesStore: DielinesStore,
    productConfig: DielineConfig,
    private _dielinesService = new DielinesService()
  ) {
    this._mapProductConfig(productConfig)
  }

  public toJSON = (): {
    product_type: string
    pdf: string | undefined
    options_custom_order: string[] | undefined
    svg: string | undefined
    options: DielineConfigOptions
    name: string
    created_at: string | undefined
    uuid: string
    is_packing_available: boolean | undefined
  } => {
    return {
      options: this._options.reduce((options, option) => {
        return {
          ...options,
          ...option.toJSON()
        }
      }, {}) as DielineConfigOptions,
      name: this.label,
      product_type: this.type,
      options_custom_order: this.optionsCustomOrder,
      pdf: this.pdf,
      svg: this.svg,
      uuid: this.uuid,
      created_at: this.createdAt,
      is_packing_available: this.isPackingAvailable
    }
  }

  public getCopy = () => {
    return new DielineStore(this._dielinesStore, omit(this.toJSON(), ['uuid']))
  }

  @action.bound public generate = async (): Promise<DielineStore> => {
    await Promise.all([this.generateSVG(), this.generatePDF()])
    await this.save()
    return this
  }

  @action.bound public save = async () => {
    this._isSaving = true
    await this._dielinesStore.save(this)
    this._isSaving = false
  }

  get uuid() {
    return this._uuid
  }

  set uuid(uuid: string) {
    this._uuid = uuid
  }

  get createdAt() {
    return this._createdAt
  }

  get isPackingAvailable() {
    return this._is_packing_available
  }

  get optionsCustomOrder() {
    return this._options_custom_order
  }

  @computed get isGeneratedDieline() {
    return Boolean(
      this.createdAt ||
        this._dielinesStore.dielines.some(
          (dieline) => dieline.uuid === this.uuid
        )
    )
  }

  @computed get isFilled() {
    return this.isNumericFilled && this.isTextFilled
  }

  @computed get isNumericFilled() {
    return this.numericOptions.reduce(
      (result: boolean, option: DielineOption) => {
        return result && option.isFilled
      },
      true
    )
  }

  @computed get isTextFilled() {
    return this.textOptions.reduce((result: boolean, option: DielineOption) => {
      return result && option.isFilled
    }, true)
  }

  @computed get isLoading() {
    return this.isPdfLoading || this.isSvgLoading || this.isSaving
  }

  @computed get isSaving() {
    return this._isSaving
  }

  @computed get isPdfLoading() {
    return this._pdf.state === StoreDataState.loading
  }

  @computed get isSvgLoading() {
    return this._svg.state === StoreDataState.loading
  }

  @computed get svg() {
    return this._svg.data
  }

  @computed get pdf() {
    return this._pdf.data
  }

  @computed get pdfObjectUrl() {
    if (!this.pdf) return ''
    if (!this._isPdfBlob) return this.pdf
    const blob = new Blob([this.pdf], {
      type: 'application/pdf'
    })
    return URL.createObjectURL(blob)
  }

  @computed get templateKey() {
    return upperFirst(camelCase(this.type))
  }

  @computed get templateSlug() {
    return kebabCase(this.type)
  }

  @computed get templateLabel() {
    return capitalize(this.type)
  }

  @computed get label() {
    const dimensions = this.dimensionOptions
      .map((option: DielineOption) => option.value)
      .join('x')

    return `${this.templateLabel} ${dimensions}mm`
  }

  @computed get slug() {
    return kebabCase(this.label)
  }

  @computed get options() {
    const dimensionOptions = Object.values(DimensionOptions) as string[]
    if (this.optionsCustomOrder.length > 0) {
      return this._options.sort(
        (optionA: DielineOption, optionB: DielineOption) => {
          return (
            this.optionsCustomOrder.indexOf(optionA.name) -
            this.optionsCustomOrder.indexOf(optionB.name)
          )
        }
      )
    }

    return this._options.sort(
      (optionA: DielineOption, optionB: DielineOption) => {
        if (optionA.isDimension && !optionB.isDimension) {
          return -1
        } else if (optionB.isDimension && !optionA.isDimension) {
          return 1
        } else if (optionA.isDimension && optionB.isDimension) {
          return dimensionOptions.indexOf(optionA.name) <
            dimensionOptions.indexOf(optionB.name)
            ? -1
            : 1
        }
        return optionA.name.localeCompare(optionB.name)
      }
    )
  }

  @computed get dimensionsLabel() {
    return this.dimensionOptions.reduce((label, option, index: number) => {
      return `${label}${index > 0 ? ', ' : ''}${option.label}: ${option.text}`
    }, '')
  }

  @computed get dimensionOptions() {
    return this.numericOptions.filter(
      (option: NumberOptionStore) => option.isDimension
    )
  }

  @computed get nonDimensionNumericOptions() {
    return this.numericOptions.filter(
      (option: NumberOptionStore) => !option.isDimension
    )
  }

  @computed get numericOptions(): NumberOptionStore[] {
    return this.options.filter(
      (option: DielineOption) => option instanceof NumberOptionStore
    ) as NumberOptionStore[]
  }

  @computed get textOptions(): StringOptionStore[] {
    return this.options.filter(
      (option: DielineOption) => option instanceof StringOptionStore
    ) as StringOptionStore[]
  }

  @computed get booleanOptions(): BooleanOptionStore[] {
    return this.options.filter(
      (option: DielineOption) => option instanceof BooleanOptionStore
    ) as BooleanOptionStore[]
  }

  @action.bound private generateSVG = async () => {
    this._svg.state = StoreDataState.loading

    try {
      this._svg = {
        data: await this._dielinesService.generateSVGDieline(this.toJSON()),
        state: StoreDataState.ok
      }
    } catch (error) {
      if (error instanceof BoxesFlowApiError) {
        this.handleErrorOptions(error)
      }
      this._svg = {
        data: '',
        state: StoreDataState.error,
        error
      }
      throw new ErrorGeneratingSVG(error)
    }
  }

  @action.bound private generatePDF = async () => {
    this._pdf.state = StoreDataState.loading

    try {
      this._pdf = {
        data: await this._dielinesService.generatePDFDieline(this.toJSON()),
        state: StoreDataState.ok
      }
      this._isPdfBlob = true
    } catch (error) {
      if (error instanceof BoxesFlowApiError) {
        this.handleErrorOptions(error)
      }
      this._pdf = {
        data: '',
        state: StoreDataState.error,
        error
      }
      throw new ErrorGeneratingPDF(error)
    }
  }

  @action.bound private handleErrorOptions = async (
    error: BoxesFlowApiError
  ) => {
    if (!error.options) return

    error.options.forEach((errorOption) => {
      const option = this.findOption(errorOption.key)
      if (!option) return

      option.setError(errorOption.message)
    })
  }

  private findOption = (optionKey: string) => {
    return this.options.find((option) => option.name === optionKey)
  }

  @action.bound private _mapProductConfig = (productConfig: DielineConfig) => {
    if (productConfig['uuid']) {
      this._uuid = productConfig['uuid']
    }

    this.type = productConfig['product_type']
    this._pdf = {
      data: productConfig['pdf'],
      state: StoreDataState.ok
    }
    this._svg = {
      data: productConfig['svg'],
      state: StoreDataState.ok
    }
    this._options = Object.keys(productConfig['options']).map(
      (optionKey: string) => {
        return OptionStoreFactory.createOptionStore(
          optionKey,
          productConfig['options'][optionKey]
        )
      }
    )
    this._createdAt = productConfig.created_at
    this._options_custom_order = productConfig.options_custom_order
      ? productConfig.options_custom_order
      : []
    this._is_packing_available = productConfig.is_packing_available
  }
}
