import { pick } from 'lodash'
import {
  BoxesflowApi,
  DiecutContainerConfig,
  DiecutContainerResponse,
  DiecutContainersResponse,
  DiecutCreateRequest,
  DiecutCreateResponse
} from '../boxesflow-api'
import {
  CreateProductConfigRequest,
  PaginationMeta,
  ProductConfig,
  ProductType,
  VmaApi,
  VmaFile
} from '../vma-api'
import { DiecutStore } from '../../stores/diecut-store/diecut-store'
import {
  DiecutConfig,
  GeneratedDiecutConfig
} from '../../stores/diecut-store/types'
import {
  UnableToGenerateLayoutContainersError,
  UnableToGenerateLayoutsError,
  UnableToGeneratePdfFile
} from './types'

export class DiecutsService {
  constructor(
    private _boxesflow = new BoxesflowApi(),
    private _vma = new VmaApi()
  ) {}

  public loadDiecut = async (uuid: string): Promise<GeneratedDiecutConfig> => {
    const { data } = await this._vma.getDiecut(uuid)
    return this._mapProductConfigToGeneratedDiecutConfig(data)
  }

  public loadDiecuts = async (
    page?: number,
    perPage?: number
  ): Promise<{ diecuts: GeneratedDiecutConfig[]; meta: PaginationMeta }> => {
    const { data, meta } = await this._vma.getDiecuts(page, perPage)
    return {
      diecuts: data
        .map(this._mapProductConfigToGeneratedDiecutConfig)
        .filter((diecut) => !!diecut) as GeneratedDiecutConfig[],
      meta
    }
  }

  public saveDiecut = async (
    diecut: DiecutStore,
    ownerUuid: string
  ): Promise<string> => {
    const [pdf, thumbnail] = await Promise.all([
      this._vma.createFile(diecut.pdf as string, 'application/pdf'),
      this._vma.createFile(diecut.thumbnail as string, 'image/svg+xml')
    ])
    const savedDiecut = await this._vma.createProductTemplate(
      this._mapDiecutToProductConfigRequest(
        diecut,
        { thumbnail, pdf },
        ownerUuid
      )
    )
    return savedDiecut.uuid
  }

  public generateDiecut = async (
    config: DiecutCreateRequest
  ): Promise<DiecutContainerResponse[]> => {
    const BATCH_SIZE = 10

    const response: DiecutCreateResponse = await this._boxesflow.generateDiecut(
      config
    )

    let diecutsContainers

    if (Array.isArray(response.data.response_data)) {
      const containers = response.data.response_data.map(
        (data: DiecutContainerConfig) => {
          return pick(data, ['width', 'height'])
        }
      )
      const containersArrays = []

      if (response.data.response_data.length === 0) {
        throw new UnableToGenerateLayoutContainersError()
      }

      do {
        containersArrays.push(
          this.generateDiecut({
            ...config,
            containers: containers.splice(0, BATCH_SIZE)
          })
        )
      } while (containers.length > 0)

      diecutsContainers = (await Promise.all(containersArrays)).flat(1)
    } else {
      diecutsContainers = (response.data
        .response_data as DiecutContainersResponse).containers
    }

    if (!diecutsContainers || diecutsContainers.length === 0) {
      throw new UnableToGenerateLayoutsError()
    }

    return diecutsContainers
  }

  public generateDiecutPdf = async (
    config: DiecutCreateRequest
  ): Promise<string> => {
    let pdfFileBlob

    const response: DiecutCreateResponse = await this._boxesflow.generateDiecut(
      config
    )

    if (typeof response.data.response_data === 'string') {
      pdfFileBlob = response.data.response_data
    }

    if (!pdfFileBlob) {
      throw new UnableToGeneratePdfFile()
    }

    return pdfFileBlob
  }

  private _mapProductConfigToGeneratedDiecutConfig = (
    productConfig: ProductConfig
  ): GeneratedDiecutConfig => {
    const { id, attachments, specification, created_at } = productConfig
    const config = specification.reduce(
      (items, { name, value }) =>
        Object.assign(items, {
          [name]:
            name === 'thumbnail' || name === 'pdf'
              ? (attachments.find(
                  (attachment) => attachment.uuid === value
                ) as VmaFile).url
              : value
        }),
      {}
    ) as DiecutConfig
    return {
      ...config,
      uuid: id,
      createdAt: created_at
    } as GeneratedDiecutConfig
  }

  private _mapDiecutToProductConfigRequest = (
    diecut: DiecutStore,
    files: { thumbnail: VmaFile; pdf: VmaFile },
    ownerUuid: string
  ): CreateProductConfigRequest => {
    const diecutConfig = diecut.toJSON(false, true)
    return {
      name: `${diecut.dieline.label} at ${diecut.format}`,
      owner: ownerUuid,
      type: ProductType.diecut,
      specification: Object.keys(diecutConfig).map((key) => {
        if (Object.keys(files).includes(key))
          return { name: key, value: files[key as keyof typeof files].uuid }
        return { name: key, value: diecutConfig[key as keyof DiecutConfig] }
      }, []),
      attachments: [files.thumbnail, files.pdf]
    }
  }
}
