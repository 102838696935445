export enum ContainersSizes {
  A0_841mm_x_1189mm = 'A0 841mm x 1189mm',
  A1_594mm_x_841mm = 'A1 594mm x 841mm',
  A2_420mm_x_594mm = 'A2 420mm x 594mm',
  A3_297mm_x_420mm = 'A3 297mm x 420mm',
  A4_210mm_x_297mm = 'A4 210mm x 297mm',
  A5_148mm_x_210mm = 'A5 148mm x 210mm',
  B0_1000mm_x_1414mm = 'B0 1000mm x 1414mm',
  B1_707mm_x_1000mm = 'B1 707mm x 1000mm',
  B2_500mm_x_707mm = 'B2 500mm x 707mm',
  B3_353mm_x_500mm = 'B3 353mm x 500mm',
  B4_250mm_x_353mm = 'B4 250mm x 353mm',
  B5_176mm_x_250mm = 'B5 176mm x 250mm'
}
