import React from 'react'
import { observer } from 'mobx-react'
import { NumberField } from '../number-field/NumberField'
import { NumberOptionStore } from '../../../stores'
import { SelectField } from '../select-field/SelectField'
import { thicknessSelectData } from './config/thickness-select-data'
import { DielineNumericFieldsProps } from './types'

export const DielineNumericFields = observer(
  ({ options, onFocus, onBlur }: DielineNumericFieldsProps) => {
    return (
      <>
        {options.map((option: NumberOptionStore) => {
          if (['paper_thickness', 'material_thickness'].includes(option.name)) {
            return (
              <SelectField
                key={option.uuid}
                name={option.name}
                label={option.label}
                value={option.value}
                onChange={option.setValue}
                error={option.error}
                selectOptions={thicknessSelectData}
              />
            )
          }
          return (
            <NumberField
              key={option.uuid}
              required={true}
              onFocus={() => onFocus && onFocus(option.name)}
              onBlur={onBlur}
              name={option.name}
              label={option.label}
              value={option.value}
              error={option.error}
              onChange={option.setValue}
              units={
                [
                  'preferred_maximum_number_of_elements',
                  'preferred_space_between_elements'
                ].includes(option.name)
                  ? undefined
                  : 'mm'
              }
            />
          )
        })}
      </>
    )
  }
)
