import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './MenuItem.module.scss'

export const MenuItem = ({
  children,
  url
}: {
  url: string
  children: React.ReactNode
}) => {
  return (
    <NavLink
      e2e-target-name="navigation-link"
      key={url}
      className={styles.link}
      activeClassName={styles['link--active']}
      to={url}
    >
      {children}
    </NavLink>
  )
}
