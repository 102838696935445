import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import {
  DielineConfig,
  DielineCreateRequest,
  DielineCreateResponse,
  DielinesTemplatesFetchingError,
  DielineCreateError,
  DiecutCreateError,
  DiecutCreateRequest,
  DiecutCreateResponse,
  InstantEstimationRequest,
  InstantEstimationResponse
} from './types'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BOXESFLOW_API_URL}/api/`
})

const generateCancelToken = (): CancelTokenSource => axios.CancelToken.source()
let cancelToken: CancelTokenSource | null = null

export class BoxesflowApi {
  public async generateDieline(
    request: DielineCreateRequest
  ): Promise<DielineCreateResponse> {
    try {
      return await api.post('/public/v2/services/dielines/', request)
    } catch (error) {
      throw new DielineCreateError(error)
    }
  }
  public async getDielinesTemplates(): Promise<DielineConfig[]> {
    try {
      return await api.get('/public/v2/services/dielines/templates/')
    } catch (error) {
      throw new DielinesTemplatesFetchingError(error)
    }
  }
  public async generateDiecut(
    request: DiecutCreateRequest
  ): Promise<DiecutCreateResponse> {
    try {
      return await api.post('/public/v2/services/packing/', request)
    } catch (error) {
      throw new DiecutCreateError(error)
    }
  }
  public async getInstantEstimations(
    request: InstantEstimationRequest,
    finishAfterFirstOffer = false
  ): Promise<InstantEstimationResponse> {
    if (cancelToken) {
      cancelToken.cancel()
    }
    cancelToken = generateCancelToken()

    try {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': process.env.REACT_APP_BOXESFLOW_API_KEY
        },
        cancelToken: cancelToken.token
      }

      const { data } = await api.post(
        '/v3/services/instant-quote/',
        {
          name: 'automate-estimation',
          items: [
            {
              quantities: request.quantities,
              attributes: request.specification.attributes,
              product_type:
                request.specification.attributes['structural-design__code'],
              postal_code: request.shippingDestination.postalCode,
              country: request.shippingDestination.country
            }
          ],
          options: {
            package_box_info: true,
            finish_after_first_offer: finishAfterFirstOffer
          }
        },
        config
      )
      const { offers, ...rest } = data

      return { estimations: offers, ...rest, requestCancelled: false }
    } catch (e) {
      return {
        warnings: [],
        estimations: [],
        requestCancelled: axios.isCancel(e)
      }
    }
  }
}
