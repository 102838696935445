import React, { ReactNode } from 'react'
import { RootStore } from '../../root-store'
import { RootStoreContext } from '../../root-store-context'
import { useSearchParams } from '../../../../utils/search-params/search-params'

export const RootStoreProvider = ({ children }: { children?: ReactNode }) => {
  const { initialQuery, query } = useSearchParams()
  const token = query.get('token') || initialQuery.get('token') || undefined
  const rootStore = new RootStore(token)

  return (
    <RootStoreContext.Provider value={rootStore}>
      {children}
    </RootStoreContext.Provider>
  )
}
