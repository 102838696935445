import { useState, useEffect, useCallback } from 'react'
import { useTranslations } from '../../libs/localisation/LocaleIntlProvider'
import { validationRules } from './validation-rules'
import {
  ErrorsState,
  FieldName,
  FieldTypes,
  TouchedState,
  ValuesState
} from './types'
import translations from './translations'

export const useFormValidation = <FormFieldName extends FieldName>(
  initialValues: Pick<FieldTypes, FormFieldName>
) => {
  const messages = useTranslations(translations)
  const [values, setValues] = useState<ValuesState<FormFieldName>>(
    initialValues
  )
  const [errors, setErrors] = useState<ErrorsState<FormFieldName>>({})
  const [touched, setTouched] = useState<TouchedState<FormFieldName>>({})
  const [validated, setValidated] = useState<TouchedState<FormFieldName>>({})

  useEffect(() => {
    setErrors(
      (Object.keys(touched) as Array<keyof typeof touched>).reduce(
        (result: ErrorsState<FormFieldName>, field) => {
          const error = validationRules[field](values)
          if (error) result[field] = messages[error]
          return result
        },
        {}
      )
    )
  }, [touched, values])

  useEffect(() => {
    setValidated(Object.keys(touched).length === Object.keys(values).length)
  }, [touched, errors])

  const onChange = (field: FormFieldName, value: FieldTypes[FormFieldName]) =>
    value !== values[field]
      ? setValues((values) => ({ ...values, [field]: value }))
      : undefined

  const onTouch = useCallback(
    (field: FormFieldName) =>
      setTouched((touched) => ({ ...touched, [field]: true })),
    [touched]
  )

  const createChangeHandler = (field: FormFieldName) => (
    value: FieldTypes[FormFieldName]
  ) => onChange(field, value)

  const createTouchHandler = (field: FormFieldName) => () => onTouch(field)

  const validate = () => {
    setTouched(
      Object.keys(values).reduce(
        (fields, field) => ({ ...fields, [field]: true }),
        {}
      )
    )
  }

  return {
    values,
    errors,
    touched,
    validated,
    onChange,
    onTouch,
    createChangeHandler,
    createTouchHandler,
    validate
  }
}
