import React from 'react'
import cxBinder from 'classnames/bind'
import { Logo } from '..'
import styles from './Header.module.scss'
import { TopRightMenu } from './components/top-right-menu/TopRightMenu'
import { TopLeftMenu } from './components/top-left-menu/TopLeftMenu'

const cx = cxBinder.bind(styles)

export const Header = ({ e2eTargetName }: { e2eTargetName?: string }) => {
  return (
    <div
      className={cx('header')}
      e2e-target="layout-header"
      e2e-target-name={e2eTargetName}
    >
      <div className={styles.menu}>
        <Logo className={styles.logo} />
        <TopLeftMenu />
      </div>
      <div className={styles.menu}>
        <TopRightMenu />
      </div>
    </div>
  )
}
