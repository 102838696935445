import React from 'react'
import { NumberOptionStore } from '../../../../stores'
import { NumberField } from '../../../form'
import { DiecutStore } from '../../../../stores/diecut-store/diecut-store'
import { useTranslations } from '../../../../libs/localisation/LocaleIntlProvider'
import translations from './translations'

interface AdditionalParametersFormProps {
  diecut: DiecutStore
}

export const AdditionalParametersForm = ({
  diecut
}: AdditionalParametersFormProps) => {
  const i18n = useTranslations(translations)

  if (!diecut) return null

  return (
    <>
      {diecut.additionalParams.map((option: NumberOptionStore) => {
        const labelName =
          option.label.charAt(0).toLocaleLowerCase() + option.label.slice(1)
        const traslationsKey = i18n[labelName as keyof typeof i18n]
        return (
          <NumberField
            key={option.name}
            name={option.name}
            value={option.value}
            label={traslationsKey}
            error={option.error}
            onChange={option.setValue}
            placeholder={''}
            units={
              option.name === 'preferredSpaceBetweenElements' ? 'mm' : undefined
            }
          />
        )
      })}
    </>
  )
}
