import React, { useState, useEffect } from 'react'
import { Input } from '@packhelp/platform-dsl/atoms'
import { FormError } from '../../views/generate-dieline-view/components/form-error/FormError'
import { Field } from '../field/Field'
import { NumberFieldProps } from './types'
import { useNumberValue } from './hooks/number-value/number-value'
import styles from './NumberField.module.scss'

export const NumberField = ({
  value: passedValue,
  label,
  name,
  placeholder,
  units,
  error,
  onChange,
  onFocus,
  onBlur,
  required,
  disabled = false
}: NumberFieldProps) => {
  const [isBlurred, setIsBlurred] = useState(false)
  const { value, setValue } = useNumberValue({
    baseValue: passedValue,
    name,
    onChange
  })

  const onBlurEvent = () => {
    setIsBlurred(true)
  }

  useEffect(() => {
    if (isBlurred && onBlur) onBlur()
  }, [isBlurred])

  return (
    <Field>
      <label htmlFor={name}>{label}</label>
      <div className={styles['input-wrapper']}>
        <Input
          name={name}
          error={isBlurred && !!error}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value)
            setIsBlurred(false)
          }}
          required={required}
          controlled={true}
          onFocus={onFocus}
          onBlur={onBlurEvent}
          autoComplete="off"
          disabled={disabled}
        />
        {units && <span>{units}</span>}
      </div>
      {isBlurred && <FormError error={error} />}
    </Field>
  )
}
