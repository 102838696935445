import { NavigationMenuConfig } from '../../../navigation-menu/types'

//NOTE: commented out TEMPORARILY(!) disabled menu parts
export const toolsNavigationMenu: NavigationMenuConfig[] = [
  {
    label: 'components.views.tools-view.menu.tools'
  },
  {
    label: 'components.views.tools-view.menu.dielines-catalogue',
    url: '/tools/dielines',
    submenu: [
      {
        label: 'components.views.tools-view.menu.product-templates',
        url: '/tools/dielines/templates'
      },
      // {
      //   label: 'My templates',
      //   url: '/tools/dielines/my-templates'
      // },
      {
        label: 'components.views.tools-view.menu.my-dielines',
        url: '/tools/dielines/my-dielines'
      }
    ]
  },
  {
    label: 'components.views.tools-view.menu.diecut-templates',
    url: '/tools/diecuts',
    submenu: [
      // {
      //   label: 'Sheet sizes',
      //   url: '/tools/diecut/sheet-sizes'
      // },
      {
        label: 'components.views.tools-view.menu.my-diecuts',
        url: '/tools/diecuts/my-diecuts'
      },
      {
        label: 'components.views.tools-view.menu.generate-layout',
        url: '/tools/diecuts/generate/'
      }
    ]
  }
]
