import React from 'react'
import cxBinder from 'classnames/bind'
import styles from './Separator.module.scss'
import { SeparatorProps } from './types'

const cx = cxBinder.bind(styles)

export const Separator = ({ withLine = true }: SeparatorProps) => {
  return (
    <div
      className={cx('separator', {
        'separator--with-line': withLine
      })}
    />
  )
}
