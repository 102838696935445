import React, { useState } from 'react'
import classNames from 'classnames'
import { Estimation } from '../../../../../../../../../../../services'
import styles from './PriceItem.module.scss'
import { ItemHeader } from './components/itemHeader'
import { ItemContent } from './components/itemContent'

type Props = {
  estimation: Estimation
}

const PriceItem: React.FC<Props> = ({ estimation }) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div className={classNames(styles.item, { [styles.collapsed]: collapsed })}>
      <ItemHeader
        estimation={estimation}
        collapsed={collapsed}
        onToggle={() => setCollapsed((state) => !state)}
      />

      {collapsed && <ItemContent estimation={estimation} />}
    </div>
  )
}

export default PriceItem
