import { action, computed, observable } from 'mobx'
import { upperFirst } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { DimensionOptions } from '../../services'
import { StoreData, StoreDataState } from '../types'
import { OptionValidators } from './types'

export abstract class OptionStore<T> {
  protected _option: string
  protected _validators: OptionValidators = {}

  @observable protected _value: StoreData<T>
  @observable protected _uuid = uuidv4()

  constructor(option: string, value: T) {
    this._option = option
    this._value = {
      state: StoreDataState.ok,
      data: value
    }
  }
  @computed get value() {
    return this._value.data
  }

  @computed get isFilled() {
    return (
      this._value.data !== null && this._value.state !== StoreDataState.error
    )
  }

  get uuid() {
    return this._uuid
  }

  get label() {
    return upperFirst(this._option.replace(/_/g, ' '))
  }

  get name() {
    return this._option
  }

  @computed get error() {
    if (this._value.state !== StoreDataState.error) return undefined

    return this._value.error
  }

  @computed get isDimension() {
    const dimensionOptions = Object.values(DimensionOptions) as string[]

    return dimensionOptions.includes(this._option)
  }

  public toJSON = (): { [option: string]: T } => {
    return {
      [this._option]: this.value
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public validate(): void {}

  @action.bound setValue(value: T) {
    this._value = {
      state: StoreDataState.ok,
      data: value
    }

    this.validate()
  }

  @action.bound setError(message: string) {
    this._value = {
      state: StoreDataState.error,
      error: message,
      data: this._value.data
    }
  }
}

export class StringOptionStore extends OptionStore<string | null> {
  @computed get text() {
    return this.value || ''
  }
}

export class NumberOptionStore extends OptionStore<number | null> {
  constructor(
    option: string,
    value: number | null,
    validators: OptionValidators = {}
  ) {
    super(option, value)
    this._prepareValidation(validators)
  }

  public validate() {
    if (this.value === null) return

    if (this._validators.min && this.value < this._validators.min) {
      this.setError(`Value must be bigger or equal ${this._validators.min}`)
      return
    }
    if (this._validators.max && this.value > this._validators.max) {
      this.setError(`Value must be lower or equal ${this._validators.max}`)
      return
    }
  }

  @computed get text() {
    return this.value ? this.value.toString() : ''
  }

  private _prepareValidation(validators: OptionValidators) {
    this._validators = validators
    if (this.isDimension) {
      if (!this._validators.min) {
        this._validators.min = 25
      }
      if (!this._validators.max) {
        this._validators.max = 500
      }
    }
  }
}

export class BooleanOptionStore extends OptionStore<boolean> {
  @computed get text() {
    return this.value === true ? 'yes' : 'no'
  }
}
