export class ProductTemplateNotFound extends Error {
  constructor(message = 'Product template not found') {
    super(message)
  }
}

export class DielineNotFound extends Error {
  constructor(message = 'Dieline not found') {
    super(message)
  }
}
