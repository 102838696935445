import { action, computed, observable } from 'mobx'
import store2, { StoreAPI } from 'store2'
import { VmaApi, UserCredentials } from '../../services/vma-api'
import { StoreData, StoreDataState } from '../types'
import { handleError } from '../../utils/handle-error/handle-error'
import { InactiveUserError, MissingTokenError, UserStoreData } from './types'

const initialUserData: UserStoreData = {
  authenticated: false
}

export class UserStore {
  private _localStore: StoreAPI = store2.namespace('ph-user-store')
  @observable private _user: StoreData<UserStoreData> = {
    state: StoreDataState.pristine,
    data: initialUserData
  }
  private _vmaApi: VmaApi = new VmaApi()

  constructor(token?: string) {
    this._initialize(token)
  }

  private _initialize(token?: string) {
    this.authenticate(token)
  }

  @action.bound private setLoading() {
    this._user = {
      state: StoreDataState.loading,
      data: this._user.data
    }
  }

  @action.bound private setError(message: string) {
    handleError(message)
    this._user = {
      state: StoreDataState.error,
      error: message,
      data: this._user.data
    }
  }

  @action.bound private setOk(data: UserStoreData) {
    this._user = {
      state: StoreDataState.ok,
      data
    }
  }

  @action.bound public async authenticate(
    token: string = this._localStore.get('token')
  ) {
    try {
      if (token) {
        this.setLoading()
        const user = await this._vmaApi.authenticate(token)
        if (!user.active) throw new InactiveUserError()

        this.setOk({ authenticated: true, ...user })
      } else {
        this.setOk({ authenticated: false })
      }
    } catch (e) {
      if (token) this._localStore.clear()
      this.setError(e.message)
    }
  }

  @action.bound public async login(credentials: UserCredentials) {
    this.setLoading()
    try {
      const { token } = await this._vmaApi.login(credentials)
      if (!token) throw new MissingTokenError()

      this._localStore.set('token', token)
      await this.authenticate()
    } catch (e) {
      this.setError(e.message)
    }
  }

  @action.bound public logout() {
    this._localStore.clear()
    this.setOk(initialUserData)
  }

  get isAuthenticated() {
    return this._user.data.authenticated
  }

  @computed get isInitialized() {
    return ![StoreDataState.pristine, StoreDataState.loading].includes(
      this._user.state
    )
  }

  @computed get isLoading() {
    return this._user.state === StoreDataState.loading
  }

  @computed get error() {
    return this._user.state === StoreDataState.error
      ? this._user.error
      : undefined
  }

  @computed get uuid() {
    if (!this._user.data.authenticated) return undefined
    return this._user.data.uuid
  }

  @computed get firstname() {
    if (
      !this._user.data.authenticated ||
      this._user.data.firstname === 'USER_FIRST_NAME'
    )
      return undefined
    return this._user.data.firstname
  }

  @computed get lastname() {
    if (
      !this._user.data.authenticated ||
      this._user.data.lastname === 'USER_LAST_NAME'
    )
      return undefined
    return this._user.data.lastname
  }

  @computed get email() {
    if (!this._user.data.authenticated) return undefined
    return this._user.data.email
  }

  @computed get avatar() {
    if (!this._user.data.authenticated) return undefined
    return this._user.data.avatar_image
  }

  @computed get companyRole() {
    if (!this._user.data.authenticated) return undefined
    return this._user.data.company_role
  }

  @computed get phoneNumber() {
    if (!this._user.data.authenticated) return undefined
    return this._user.data.phone_number
  }
}
