import React, { lazy, Suspense } from 'react'
import { kebabCase } from 'lodash'
import cxBinder from 'classnames/bind'
import { handleError } from '../../utils/handle-error/handle-error'
import { ProductTemplateProps } from './types'
import styles from './ProductTemplate.module.scss'

const cx = cxBinder.bind(styles)

const loadedTemplates: {
  [index: string]: React.ComponentType<React.SVGProps<SVGSVGElement>>
} = {}

export const ProductTemplate = ({
  product,
  showDimensions = false,
  highlightDimension,
  e2eTargetName,
  ...other
}: ProductTemplateProps) => {
  if (!loadedTemplates[product]) {
    const templateComponentName = `${product}Template`
    const templateComponentFolder = kebabCase(templateComponentName)

    loadedTemplates[product] = lazy(async () => {
      try {
        const module = await import(
          `./components/templates/${templateComponentFolder}/${templateComponentName}.tsx`
        )
        return { default: module[templateComponentName] }
      } catch (error) {
        handleError(error)
        return {
          default: () => {
            return null
          }
        }
      }
    })
  }
  const TemplateComponent = loadedTemplates[product]

  return (
    <Suspense fallback={null}>
      <div className={styles['svg-wrapper']}>
        <TemplateComponent
          {...other}
          className={cx('product-template', {
            'product-template--hide-dimensions':
              !showDimensions && !highlightDimension,
            [`product-template--highlight-${highlightDimension}`]: highlightDimension
          })}
          e2e-target="product-template"
          e2e-target-name={e2eTargetName || product}
        />
      </div>
    </Suspense>
  )
}
