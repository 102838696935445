import React from 'react'
import cxBinder from 'classnames/bind'
import styles from './Content.module.scss'
import { ContentProps, ContentPadding } from './types'

const cx = cxBinder.bind(styles)

export const Content = ({
  children,
  e2eTargetName,
  padding = ContentPadding.small
}: ContentProps) => {
  return (
    <div
      e2e-target="layout-content"
      e2e-target-name={e2eTargetName}
      className={cx('content', {
        [`content--padding-${padding}`]: padding
      })}
    >
      {children}
    </div>
  )
}
