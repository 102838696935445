import { useEffect, useState } from 'react'
import { ProductSpecification } from '@packhelp/platform-pss-form'
import { InstantPriceContextContract } from '../contracts/InstantPriceContextContract'
import { BoxesflowApi, Estimation } from '../../../../services'

type Hook = {
  context: InstantPriceContextContract
}

type State = {
  specification?: ProductSpecification
  country: string | null
  postalCode: string | null
  quantities: number[]
  boxesflow: {
    estimations: Estimation[]
    estimationsAvailable: boolean | null
    loading: boolean
    warnings: string[]
  }
}

export const useInstantPrice = (): Hook => {
  const initialBoxesflow = {
    estimations: [],
    estimationsAvailable: null,
    loading: false,
    warnings: []
  }

  const [state, setState] = useState<State>({
    specification: undefined,
    country: null,
    postalCode: null,
    quantities: [],
    boxesflow: initialBoxesflow
  })

  useEffect(() => {
    setState((state) => ({
      ...state,
      boxesflow: {
        ...state.boxesflow,
        loading: false,
        estimations: [],
        warnings: []
      }
    }))
  }, [
    JSON.stringify(state.specification?.attributes),
    state.country,
    state.postalCode,
    state.quantities
  ])

  return {
    context: {
      updateSpecification(specification?: ProductSpecification) {
        setState((prevState) => ({
          ...prevState,
          specification
        }))
      },
      updateDeliveryAddress(country: string | null, postalCode: string | null) {
        setState((prevState) => ({
          ...prevState,
          country,
          postalCode
        }))
      },
      updateQuantities(quantities: number[]) {
        setState((prevState) => ({
          ...prevState,
          quantities
        }))
      },
      async estimate() {
        if (!state.specification) {
          return
        }

        try {
          setState((prevState) => ({
            ...prevState,
            boxesflow: {
              ...prevState.boxesflow,
              loading: true,
              estimations: [],
              warnings: []
            }
          }))

          const api = new BoxesflowApi()
          const { warnings, estimations } = await api.getInstantEstimations({
            specification: state.specification,
            quantities: state.quantities,
            shippingDestination: {
              country: state.country,
              postalCode: state.postalCode
            }
          })

          setState((prevState) => ({
            ...prevState,
            boxesflow: { ...prevState.boxesflow, estimations, warnings }
          }))
        } catch {
          // todo: add error box
        } finally {
          setState((prevState) => ({
            ...prevState,
            boxesflow: { ...prevState.boxesflow, loading: false }
          }))
        }
      },
      quantities: state.quantities,
      specification: state.specification,
      country: state.country,
      postalCode: state.postalCode,
      boxesflow: state.boxesflow
    }
  }
}
