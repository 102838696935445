import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { configure } from 'mobx'
import { App } from './components'
import { LocaleIntlProvider } from './libs/localisation/LocaleIntlProvider'
import { SegmentAnalytics } from './libs/segment-analytics'

configure({ isolateGlobalState: true })

Sentry.init({
  dsn:
    'https://29e18c2c24264ed5b25514b5e7ee44a8@o29500.ingest.sentry.io/5461718'
})
SegmentAnalytics.init()

ReactDOM.render(
  <LocaleIntlProvider>
    <App />
  </LocaleIntlProvider>,
  document.getElementById('root')
)
