import React from 'react'
import cxBinder from 'classnames/bind'
import { HeadingProps, HeadingSize } from './types'
import styles from './Heading.module.scss'

const cx = cxBinder.bind(styles)

export const Heading = ({
  size = HeadingSize.h1,
  children,
  marginBottom
}: HeadingProps) => {
  const HeadingTag = size
  return (
    <HeadingTag
      className={cx('heading', {
        [`heading--${size}`]: true,
        [`heading--mb-${marginBottom}`]: true
      })}
    >
      {children}
    </HeadingTag>
  )
}
