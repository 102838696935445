import React from 'react'
import { observer } from 'mobx-react'
import { Select, SelectSize } from '@packhelp/platform-dsl/molecules'
import { PopupSizeProp } from '@packhelp/platform-dsl/atoms/Popup'
import { Field } from '../field/Field'
import { SelectItem } from '../../form/select-item/SelectItem'

export const MeasurementSelect = observer(() => {
  const measurementData = [{ value: 'mm', label: 'Millimeters' }]

  return (
    <Field>
      <label htmlFor="measurement">Measurement</label>
      <Select
        disabled={true}
        name="measurement"
        value={measurementData[0].value}
        data={measurementData}
        size={SelectSize.large}
        popupSize={PopupSizeProp.auto}
        listItemRenderer={(label) => <SelectItem label={label} />}
      />
    </Field>
  )
})
