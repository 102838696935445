export default {
  header: 'components.views.generate-diecut-view.sheet-size.header',
  headerSecond:
    'components.views.generate-diecut-view.sheet-size.second-header',
  KnifePrice: 'components.views.generate-diecut-view.sheet-size.knife-price',
  KnifeLength: 'components.views.generate-diecut-view.sheet-size.knife-length',
  predefinedSheetSizePlaceholder:
    'components.views.generate-diecut-view.predefined-sheet-size.placeholder',
  predefinedSheetSizeTitle:
    'components.views.generate-diecut-view.predefined-sheet-size.title',
  predefinedSheetSizeCustomOption:
    'components.views.generate-diecut-view.predefined-sheet-size.custom-option'
}
