import { VmaApi } from '../../../../../services/vma-api'
import { SegmentAnalytics } from '../../../../../libs/segment-analytics'

export const confirmAccount = async (
  uuid: string,
  token: string,
  setIsLoading: (isSubmitting: boolean) => void,
  setIsConfirmed: (isSubmitting: boolean) => void
): Promise<void> => {
  try {
    const vmaApi = new VmaApi()
    await vmaApi.confirm({
      uuid,
      token
    })
    SegmentAnalytics.accountConfirmed(uuid)
    setIsConfirmed(true)
  } catch (e) {
    setIsConfirmed(false)
  } finally {
    setIsLoading(false)
  }
}
