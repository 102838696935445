import React from 'react'
import { ParsedAttribute, textareaFields } from '@packhelp/platform-pss-form'
import { attributeValueToString } from '../../../../hooks/productSpecPreview'
import styles from '../../Summary.module.scss'

type Props = {
  attribute: ParsedAttribute
}

const PreviewValue: React.FC<Props> = ({ attribute }) => {
  if (textareaFields.includes(`${attribute.group}__${attribute.name}`)) {
    return (
      <span className={styles['preview__group_value--text']}>
        {attribute.value}
      </span>
    )
  }

  if (attribute.value_type === 'file' && Array.isArray(attribute.value)) {
    return (
      <>
        {attribute?.value?.map((value) => {
          if (value && typeof value === 'object') {
            return (
              <a
                key={value.url}
                href={value.url}
                rel="noopener noreferrer"
                className={styles['preview__group_value--url']}
              >
                {value.name || value.url}
              </a>
            )
          }
        })}
      </>
    )
  }

  return <span>{attributeValueToString(attribute)}</span>
}

export default PreviewValue
