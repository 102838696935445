import React from 'react'
import cxBinder from 'classnames/bind'
import styles from './Wrapper.module.scss'
import { WrapperProps, WrapperSize } from './types'

const cx = cxBinder.bind(styles)

export const Wrapper = ({
  children,
  e2eTargetName,
  size = WrapperSize.big
}: WrapperProps) => {
  return (
    <div
      e2e-target="layout-wrapper"
      e2e-target-name={e2eTargetName}
      className={cx('wrapper', {
        [`wrapper--${size}`]: size
      })}
    >
      {children}
    </div>
  )
}
