import { useMemo } from 'react'
import { PackingPallets } from '../../../../../../../../../../../../../../../../../../services'

export type PalletsProps = {
  services: string[]
  palletsByService: { [key: string]: PackingPallets[] }
  palletsWithNoService: PackingPallets[]
  transitTimes: Record<string, string>
  serviceDeliveries: Record<
    string,
    {
      carrier?: string
      number_of_pallets?: number[]
    }
  >
}

export const usePallets = (
  pallets: PackingPallets[]
): { pallets: PalletsProps } => {
  return {
    pallets: useMemo(() => {
      const empty: PalletsProps = {
        services: [],
        palletsByService: {},
        palletsWithNoService: [],
        transitTimes: {},
        serviceDeliveries: {}
      }

      return pallets.length === 0
        ? empty
        : pallets.reduce<PalletsProps>((result, packingPallets) => {
            packingPallets.services?.forEach((service) => {
              if (!result.services.includes(service.name)) {
                result.services.push(service.name)
                result.transitTimes[service.name] = service.transit_times
                result.palletsByService[service.name] = []

                if (service.carrier && service.number_of_pallets) {
                  result.serviceDeliveries[service.name] = {
                    carrier: service.carrier,
                    number_of_pallets: service.number_of_pallets
                  }
                }
              }

              result.palletsByService[service.name].push({
                ...packingPallets,
                cost: service.cost
              })
            })

            if (!packingPallets.services?.length)
              result.palletsWithNoService.push(packingPallets)

            return result
          }, empty)
    }, [pallets])
  }
}
